import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { FinanceService } from '../../../finance.service';

@Component({
    selector: 'app-finance-transactions-charge',
    templateUrl: './charge.component.html',
    styleUrls: ['./charge.component.css']
})
export class TransactionAddChargeComponent implements OnInit, AfterViewInit {

    @Input() mode: string = "add";
    @ViewChild('f', {static:true}) form?: NgForm;

    date?: NgbDateStruct;
    faCalendar = faCalendarAlt;
    currentForm?: NgForm;

    viewInit: boolean = false;

    errorMessage: string = "";
    successMessage: string = "";

    currencies_all: {[key:string]: string}[] = [
        { "currency": "USD" },
        { "currency": "MXN" }
    ];

    currencies: {[key:string]: string}[] = [];

    status = "";

    constructor( public financeService: FinanceService, public alertService: AlertService) { }

    ngOnInit(): void {
        console.log("CHARGE MODE", this.mode);
        this.currencies = this.currencies_all;
    }

    ngAfterViewInit(){
        if( this.mode == "edit" ){
            this.loadTransaction();
        }
        this.viewInit = true;
    }

    loadTransaction(){
        if( this.viewInit ){
            this.setEditingTransaction();
        } else {
            setTimeout( () => {
                this.loadTransaction();
            }, 100);
        }
    }

    getStatusBgColor(){
        if( this.status=="2" ){
            return "#fbdddd";
        } else if( this.status=="1" ){
            return "#d0f1d2";
        }
        return "#fffff";
    }

    onAddCharge( form: NgForm){

        this.errorMessage = "";
        this.successMessage = "";
        this.currentForm = form;

        let data = form.form.value;

        if( data.account==1 && data.currency=="MXN" ){
            this.errorMessage = "La cuenta HOA solo admite transacciones en USD";
            return;
        } else if( data.account==2 && data.currency=="USD" ){
            this.errorMessage = "La cuenta Billpay solo admite transacciones en MXN";
            return;
        }


        let date = data.dp.year+"-"+(data.dp.month < 10 ? "0"+data.dp.month : data.dp.month )+"-"+(data.dp.day < 10 ? "0" + data.dp.day : data.dp.day );

        var resume = "<ul>";
        resume += "<li><b>Propiedad: </b> "+this.financeService.meta["properties_assoc"][data.property].name+"</li>";
        resume += "<li><b>Tipo de cobro: </b> "+this.financeService.meta["billtypes_assoc"][data.billtype].name+"</li>";
        resume += "<li><b>Cuenta: </b> "+this.financeService.meta["accounts_assoc"][data.account].name+"</li>";
        resume += "<li><b>Fecha: </b> "+date+"</li>";
        resume += "<li><b>Descripción: </b> "+data.description+"</li>";
        resume += "<li><b>Cantidad: </b> $"+data.amount+"</li>";
        resume += "<li><b>Moneda: </b> "+data.currency+"</li>";

        if( this.mode=='edit' ){
            resume += "<li><b>Tipo de Cambio: </b> $"+parseFloat(data.tc).toFixed(2)+"</li>";
            resume += "<li><b>Estatus: </b> "+this.getStatusString(data.status)+"</li>";
        }

        resume += "</ul>";
        var transaction = {
            property: data.property,
            date: date,
            description: data.description,
            account: data.account,
            amount: data.amount,
            currency: data.currency,
            billtype: data.billtype,
            status: "",
            tc: "",
            transaction_id: ""
        }
        if( this.mode=='edit' ){
            this.alertService.setConfirmationAlert("Confirmación de cambios", "<b>Resumen:</b><br>"+resume, "Guardar Cambios", () => {
                this.alertService.syncing = true;
                transaction.tc = data.tc;
                transaction.status = data.status;
                transaction.transaction_id = this.financeService.editingTransaction["id"];
                this.onEditChargeConfirmed( transaction );
            });
        } else {
            this.alertService.setConfirmationAlert("Confirmación de cargo", "<b>Resumen:</b><br>"+resume, "Agregar", () => {
                this.alertService.syncing = true;
                this.onAddChargeConfirmed( transaction );
            });
        }
    }

    onAddChargeConfirmed( data: { [key: string] : string}){
        this.financeService.addCharge( data ).subscribe(
            resData => {
                this.alertService.syncing = false;
                if( resData.auth ){
                    //Show success
                    this.alertService.setSuccessAlert( "Éxito", "Transacción agregada correctamente. ID: "+resData.transaction_id );
                    //reset form
                    this.currentForm?.reset();
                } else {
                    this.alertService.closeAlert();
                    this.onSessionExpired();
                }
            }, errorMessage => {
                this.alertService.syncing = false;
                this.alertService.closeAlert();
                this.errorMessage = errorMessage;
            }
        );
    }

    onEditChargeConfirmed(data: { [key: string] : string} ){
        this.financeService.editCharge( data ).subscribe(
            resData => {
                this.alertService.syncing = false;
                if( resData.auth ){
                    //Show success
                    this.alertService.setSuccessAlert( "Éxito", "Cambios guardados correctamente. ID: "+data.transaction_id, () => {
                        this.financeService.hostViewContainerRef?.clear();
                    } );
                } else {
                    this.alertService.closeAlert();
                    this.onSessionExpired();
                }
            }, errorMessage => {
                this.alertService.syncing = false;
                this.alertService.closeAlert();
                this.errorMessage = errorMessage;
            }
        );
    }

    onSessionExpired(){
        this.errorMessage = "Session expired.";
    }

    setEditingTransaction(){
        /*
            amount: ""
            billtype: ""
            currency: ""
            description: ""
            dp: undefined
            property: ""
        */
        console.log(this.form, this.financeService.editingTransaction);
        let date_a = this.financeService.editingTransaction.date.split("-");
        console.log(date_a);
        this.form?.reset({
            property: this.financeService.editingTransaction.property_id,
            billtype: this.financeService.editingTransaction.billtype_id,
            account: this.financeService.editingTransaction.account_id,
            currency: this.financeService.editingTransaction.currency,
            description: this.financeService.editingTransaction.description,
            amount: this.financeService.editingTransaction.charge,
            tc: this.financeService.editingTransaction.tc,
            status: this.financeService.editingTransaction.status,
            dp: {
                year: parseInt(date_a[0]),
                month: parseInt(date_a[1]), 
                day: parseInt(date_a[2])
            }
        });
        this.updateCurrencies( this.financeService.editingTransaction.account_id );
    }

    getStatusString( status: string): string{
        if( status=="1" ){
            return "Activa";
        } else if( status=="2" ){
            return "Cancelada";
        }
        return "";
    }

    onAccountChanged(event: Event){
        let account_id = (<HTMLSelectElement>event.target).value;
        if( account_id ){
            this.updateCurrencies( account_id );
        }
    }

    updateCurrencies( account_id: string ){
        let scurrency = (account_id=='1') ? "USD" : "MXN";
        this.currencies = this.currencies_all.filter( (currency) => {
            return currency.currency==scurrency
        });
    }

    isEditingMode(){
        return this.mode=="edit";
    }
}
