<div class='modal' id='modal_wrapper'>
    <div id='modal_block' class='popup_bg_modal'></div>
    <div id='modal_block_text' class='hidden'>Please wait <i class='fa fa-spinner fa-spin'></i></div>
    <div id='modal_wrap' class='modal-dialog {{size}}'>
        <div class='modal-content modal-content-v2'>

            <!-- Modal Header -->
            <div class='modal-header'>
                <i id='back-model' class='fa fa-arrow-left margin-right-md hidden pointer'
                    onclick='javascript: ".$this->js_instance.".modal_back();'></i>
                <label id='modal_title' class='modal-title font-18'>Copiar valor</label>
                <button id='modal_close' type='button' class='close' (click)="onClose()">&times;</button>
            </div>

            <!-- Modal body -->
            <div id='modal_body' class='modal-body'>

                <div class="page-container no-margin">

                    <div class="form-group" *ngIf="selector=='date'">
                        <label>Fecha</label>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <a href="javascript:;" (click)="d.toggle()">
                                    <fa-icon [icon]="faCalendar"></fa-icon>
                                </a>
                            </span>
                            <input class="form-control datepicker" placeholder="yyyy-mm-dd" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()">
                        </div>
                    </div>

                    <div class="form-group" *ngIf="selector=='billtype'">
                        <label>Tipo de cobro</label>
                        <select class="form-control" [(ngModel)]="billtype" autocomplete="off">
                            <option value=""></option>
                            <option *ngFor="let billtype of financeService.meta.billtypes" 
                                [value]="billtype.id">{{billtype.name}}</option>
                        </select>
                    </div>

                    <div class="form-group" *ngIf="selector=='description'">
                        <label>Descripción</label>
                        <input type="text" class="form-control" [(ngModel)]="description" autocomplete="off">
                    </div>

                    <div class="form-group" *ngIf="selector=='amount'">
                        <label>Amount</label>
                        <div class="input-group">
                            <span class="input-group-addon">$</span>
                            <input type="text" class="form-control" [(ngModel)]="amount" autocomplete="off" [OnlyNumber]="true" [AllowDot]="true" [AllowNegative]="false" noCopyPaste>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="selector=='notification'">
                        <label>Enviar notificación</label>
                        <select class="form-control" [(ngModel)]="notification">
                            <option value="1">Enviar</option>
                            <option value="0">No enviar</option>
                        </select>
                    </div>

                    <input type="checkbox" [(ngModel)]="onlyEmpty"> Aplicar solo a campos vacíos
                    <hr>
                    <div class="text-right">
                        <button class="btn btn-primary" (click)="onApply()">Aplicar a todo</button>
                    </div>
                </div>
            </div>

            <!-- Modal Footer -->
            <!-- <div id='modal_footer' class='modal-footer'>
              
        </div> -->

            <div id='modal_msg' class='modal_msg_wrapper'></div>

        </div>
    </div>
</div>