import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { FinanceService } from '../../../finance.service';

@Component({
  selector: 'app-finance-transactions-import-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class TransactionsImportAddComponent implements OnInit {

  @Output() close = new EventEmitter<void>();

  size: string = "modal-md";
  title: string = "Importar Transacciones";
  faSpinner = faSpinner;
  errorMessage: string = "";
  viewInit: boolean = false;
  faTimesCircle = faTimesCircle;

  date?: NgbDateStruct;
  faCalendar = faCalendarAlt;
  currentForm?: NgForm;

  balanceInicial: string = "";

  constructor(public financeService: FinanceService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit(){
    this.viewInit = true;
  }

  initComponent(){
    if( !this.viewInit ){
        setTimeout( () => { 
          this.onDocumentReady();
        }, 100);
    } else {
        this.initComponent();
    }
  }

  onDocumentReady() {
    
    //Saldo Inicial
    var tr = document.getElementById("initial_balance")?.getElementsByTagName("tr");
    if( tr ){
      for( var i = 0; i< tr.length; i++ ){
        let amounts = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-amount");
        for( var d = 0; d< amounts.length; d++ ){
          (<HTMLInputElement>amounts[d]).value = this.getAmount(this.financeService.imported.inicial);
        }
      }
    }

    //Abonos
    var tr = document.getElementById("abonos")?.getElementsByTagName("tr");
    if( tr ){
      for( var i = 0; i< tr.length; i++ ){
          let amounts = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-amount");
          for( var d = 0; d< amounts.length; d++ ){
            (<HTMLInputElement>amounts[d]).value = this.getAmount(this.financeService.imported.abonos[i]["total"]);
          }

          let descriptions = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-description");
          for( var d = 0; d< descriptions.length; d++ ){
            (<HTMLInputElement>descriptions[d]).value = this.getDescription(this.financeService.imported.abonos[i]);
          }

          let dates = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-date");
          for( var d = 0; d< dates.length; d++ ){
            (<HTMLInputElement>dates[d]).value = this.financeService.imported.abonos[i]["date"];
          }
      }
    }

    //Cargos
    var tr = document.getElementById("cargos")?.getElementsByTagName("tr");
    if( tr ){
      for( var i = 0; i< tr.length; i++ ){
        let amounts = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-amount");
        for( var d = 0; d< amounts.length; d++ ){
          (<HTMLInputElement>amounts[d]).value = this.getAmount(this.financeService.imported.cargos[i]["total"]);
        }

        let descriptions = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-description");
        for( var d = 0; d< descriptions.length; d++ ){
          (<HTMLInputElement>descriptions[d]).value = this.getDescription(this.financeService.imported.cargos[i]);
        }

        let dates = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-date");
        for( var d = 0; d< dates.length; d++ ){
          (<HTMLInputElement>dates[d]).value = this.financeService.imported.cargos[i]["date"];
        }
      }
    }

  }

  getTransactions(){
    var transactions: any[] = [];
    var ids = ["initial_balance", "abonos", "cargos"];

    for( let id of ids ){
      var tr = document.getElementById(id)?.getElementsByTagName("tr");
      if( tr ){
        for( var i = 0; i< tr.length; i++ ){
          var trans = {
            date: "",
            type: "",
            description: "",
            total: "",
            idd: id
          };

          //Total
          let amounts = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-amount");
          for( var d = 0; d< amounts.length; d++ ){
            trans.total = (<HTMLInputElement>amounts[d]).value;
          }
          //Description
          let descriptions = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-description");
          for( var d = 0; d< descriptions.length; d++ ){
            trans.description = (<HTMLInputElement>descriptions[d]).value;
          }

          //Date
          let dates = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-date");
          for( var d = 0; d< dates.length; d++ ){
            trans.date = (<HTMLInputElement>dates[d]).value;
          }

          //Type
          if( id=="cargos" || (id=="initial_balance" && parseFloat(this.balanceInicial)<0) ){
            let types = (<HTMLTableRowElement>tr[i]).getElementsByClassName("imp-billtype");
            for( var d = 0; d< types.length; d++ ){
              trans.type = (<HTMLInputElement>types[d]).value;
            }
          } else {
            trans.type = "abono";
          }

          if( this.isTransactionValid(trans) ){
            transactions.push(trans);
          } else {
            //Invalid transaction
            return null;
          }
        }
      }
    }

    return transactions;
  }

  isInitialBalancePositive(){
    return (this.balanceInicial=="" || parseFloat(this.balanceInicial)>0 );
  }

  isTransactionValid(trans: any): boolean{
    if( trans.date && trans.total && trans.type && trans.description ){
      return true;
    }
    return false;
  }

  getDescription(transaction: any){
    if( transaction.hasOwnProperty("charge") ){
      return transaction["charge"] + " - " + transaction["concept"] + " - " + transaction["ref"];
    }
    return transaction["concept"] + " - " + transaction["ref"];
  }

  getAmount(total: string){
    return total.replace(",", "");
  }

  getTransactionsTotal(){
    var i = (this.financeService.imported.inicial=="" ) ? 0 : 1;
    i += this.financeService.imported.cargos.length + this.financeService.imported.abonos.length;
    return i;
  }

  getSaldoInicial(): Number{
    let inicial = parseFloat(this.financeService.imported.inicial);
    console.log("inicial", inicial);
    return inicial || 0;
  }

  onTransactionRowDelete( event: Event ){
    var el = (<HTMLElement>event.target).parentNode;
    while( el && el?.nodeName!="TR" ){
      el = el?.parentNode || null;
    }
    el?.parentNode?.removeChild(el);
  }

  onAddTransacctions(form: NgForm){
    //Get data, validate
    this.errorMessage = "";
    if( form.valid ){
      let transactions = this.getTransactions();
      if( transactions===null ){
        this.alertService.setErrorAlert( "Transacción inválida", "Una o más transacciones tienen campos vacios, todos los campos son obligatorios en cada transacción" );
      } else if(transactions.length==0) {
        this.alertService.setErrorAlert( "Sin resultados", "No se encontraron transacciones" );
      } else {
        this.alertService.setConfirmationAlert( "Confirmación", "¿Estás seguro de agregar las transacciones?", "Agregar Transacciones", () => {
          var data = form.form.value;
          data.transactions = transactions;
          this.saveTransactions(data);
        });
      }
    } else {
      this.alertService.setErrorAlert( "Campos requeridos", "Debe proporcionar la propiedad, cuenta y moneda" );
    }
  }

  saveTransactions( data: any ){
    this.financeService.saveImportedTransactions( data ).subscribe(
      resData => {
        if( resData.auth ){
            //Show success
            this.alertService.setSuccessAlert( "Éxito", "Transacciones agregadas correctamente", () => {
              this.onClose();
            } );
        } else {
            this.alertService.closeAlert();
            this.onSessionExpired();
        }
      }, error => {
        this.errorMessage = error;
        this.alertService.closeAlert();
      }
    );
  }

  onSessionExpired(){
    this.errorMessage = "Session expired.";
  }

  onClose(){
    this.close.emit();
  }

}
