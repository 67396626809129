import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faListAlt, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SubmenuService } from 'src/app/panel/submenu.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { FinanceService } from '../../finance.service';

@Component({
    selector: 'app-finance-add-recurrent',
    templateUrl: './add.recurrent.component.html',
    styleUrls: ['./add.recurrent.component.css']
})
export class FinanceAddRecurrentComponent implements OnInit {

    @Input() mode: string = "add";
    options: string[] = ["step1", "step2", "step3"];
    optionsName: { [key: string]: string } = {
        "step1": "1.- Datos Generales",
        "step2": "2.- Propiedades y Cantidades",
        "step3": "3.- Frecuencia"
    };
    selectedOption = this.options[0];
    enabledOptions : { [key: string]: boolean } = {
        "step1": true,
        "step2": false,
        "step3": false
    };

    faCalendar = faCalendarAlt;
    viewInit: boolean = false;
    errorMessage: string = "";
    loaded: boolean = false;
    savingRecurrent: boolean = false;

    generalData: {[key: string]: any} = {
        "name": "",
        "description": "",
        "billtype": "",
        "account": "",
        "currency": "",
        "not_owners": true,
        "not_owners_balance": true
    };
    propertiesData: {[key: string]: any}[] = [];
    propertiesFilters = {
        bedrooms: "",
        property_type: "",
        amount: ""
    };
    today: Date = new Date();
    tomorrow: Date = new Date();
    frequencyDate: {[key: string]: number} = {};
    frequencyData = {
        date: null,
        minDate: {
            year: 0,
            month: 0,
            day: 0
        },
        logic: "",
        days: [
            { day: 1, selected : false, name: "Lunes" },
            { day: 2, selected : false, name: "Martes" },
            { day: 3, selected : false, name: "Miércoles" },
            { day: 4, selected : false, name: "Jueves" },
            { day: 5, selected : false, name: "Viernes" },
            { day: 6, selected : false, name: "Sábado" },
            { day: 7, selected : false, name: "Domingo" }
        ],
        frequency: {
            each: "",
            type: ""
        }
    };

    faSpinner = faSpinner;

    constructor(private submenuService: SubmenuService, private route: ActivatedRoute, public financeService: FinanceService, public alertService: AlertService) { }

    ngOnInit(): void {
        //Set min date
        this.tomorrow.setDate( this.tomorrow?.getDate() + 1);
        this.frequencyData.minDate = {
            year: parseInt(formatDate(this.tomorrow, 'yyyy', 'en')),
            month: parseInt(formatDate(this.tomorrow, 'MM', 'en')),
            day: parseInt(formatDate(this.tomorrow, 'dd', 'en'))
        };

        this.updateRootMenu();
        this.updateNavBar();
        //fake
        //this.loaded = true;

        this.getMeta();
    }

    ngAfterViewInit() {
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    getMeta(){
        this.financeService.getAddTransactionMeta().subscribe(
            resData => {
                if( this.financeService.meta["properties"].length>0 ){
                    this.loaded = true;
                }
                if( this.mode=="edit" ){
                    this.loadEditingRecurrent();
                }
                //Test
                // this.generalData = {
                //     "name": "test",
                //     "description": "testd",
                //     "billtype": "3",
                //     "account": "1",
                //     "currency": "MXN"
                // };
                // this.setupPropertiesData();
                // for( var i=0; i<this.propertiesData.length; i++ ){
                //     this.propertiesData[i].selected = true;
                //     this.propertiesData[i].amount = "120";
                // }
                // this.frequencyDate = {year: 2021, month: 7, day: 25 };
                // this.frequencyData.logic = "by_days";
                
            }, error => {
                this.errorMessage = error;
            }
        )
    }

    loadEditingRecurrent(){
        let recurrent = this.financeService.editingRecurrent;
        if( recurrent ){
            //console.log(recurrent);
            let not_owners = (recurrent.notifications.hasOwnProperty("not_owners")) ? recurrent.notifications["not_owners"] : false;
            let not_owners_balance = (recurrent.notifications.hasOwnProperty("not_owners_balance")) ? recurrent.notifications["not_owners_balance"] : false;
            this.generalData = {
                "name": recurrent.name,
                "description": recurrent.description,
                "billtype": recurrent.billtype_id,
                "account": recurrent.account_id,
                "currency": recurrent.currency,
                "not_owners": not_owners,
                "not_owners_balance": not_owners_balance
            };
            let properties = recurrent.properties;
            for(let property of this.financeService.meta.properties ){
                let checked = properties.hasOwnProperty(property.id);
                let amount = checked ? properties[property.id] : "";
                this.propertiesData.push({
                    id: property.id,
                    selected: checked,
                    name: property.name,
                    amount: amount
                });
            }
            this.frequencyData.date = recurrent.next_date_a;
            this.frequencyDate = recurrent.next_date_a;
            this.frequencyData.logic = recurrent.freq_logic;
            if( this.frequencyData.logic=="by_days" ){
                var days = recurrent.freq_meta;
                if( days.length>0 ){
                    for(var i=0; i<this.frequencyData.days.length; i++ ){
                        let day = this.frequencyData.days[i];
                        if( days.includes(day.day) ){
                            this.frequencyData.days[i].selected = true;
                        }
                    }
                }
            } else if( this.frequencyData.logic=="by_frequency" ){
                this.frequencyData.frequency = recurrent.freq_meta;
            }
            this.enabledOptions  = {
                "step1": true,
                "step2": true,
                "step3": true
            };
        }
    }

    saveGeneralData(form: NgForm){
        console.log(form.form.value);
        //prepare next step view
        this.setupPropertiesData();
        //save step data
        this.generalData = form.form.value;
        //load next step
        this.toStep("step2");
    }

    setupPropertiesData(){
        if( this.propertiesData.length==0 ){
            for(let property of this.financeService.meta.properties ){
                this.propertiesData.push({
                    id: property.id,
                    selected: false,
                    name: property.name,
                    amount: ""
                });
            }
        }
    }

    updateFilters(){

        for( let i=0; i<this.propertiesData.length; i++ ){
            let propData = this.financeService.meta.properties_assoc[this.propertiesData[i].id];
            var pass = true;
            if( this.propertiesFilters.bedrooms!="" ){
                if( propData.bedrooms != this.propertiesFilters.bedrooms ){
                    pass = false;
                }
            }

            if( this.propertiesFilters.property_type!="" ){
                if( propData.type_id != this.propertiesFilters.property_type ){
                    pass = false;
                }
            }

            this.propertiesData[i].selected = pass;
        }
        
    }

    applyAmountByFilters(){
        if( this.propertiesFilters.amount!="" ){
            for( let i=0; i<this.propertiesData.length; i++ ){
                let propData = this.financeService.meta.properties_assoc[this.propertiesData[i].id];
                var pass = true;
                if( this.propertiesFilters.bedrooms!="" ){
                    if( propData.bedrooms != this.propertiesFilters.bedrooms ){
                        pass = false;
                    }
                }

                if( this.propertiesFilters.property_type!="" ){
                    if( propData.type_id != this.propertiesFilters.property_type ){
                        pass = false;
                    }
                }

                if( pass ){
                    this.propertiesData[i].amount = this.propertiesFilters.amount;
                }
            }
        }
    }

    toStep(step: string){
        this.selectedOption = step;
        this.enabledOptions[step] = true;
    }

    toFrequency(){
        var pass = 0;
        for( let property of this.propertiesData ){
            if( property.selected && property.amount ){
                pass += 1;
                break;
            }
        }
        if( pass==0 ){
            this.alertService.setErrorAlert( "Sin propiedades", "Al menos una propiedad y cantidad deben ser proporcionados");
        } else {
            this.toStep("step3");
        }
    }

    onSaveRecurrent(){
        if( this.frequencyDate && this.frequencyDate.year && this.frequencyData.logic ){
            if( this.frequencyData.logic == "by_days" ){
                if( this.areSelectedDays() ){
                    (this.mode=='add') ? this.saveRecurrent() : this.editRecurrent();
                } else {
                    this.alertService.setErrorAlert("Días requeridos", "Debes seleccionar al menos un día");
                }
            } else if( this.frequencyData.logic == "by_frequency" ){
                if( this.frequencyData.frequency.each && this.frequencyData.frequency.type ){
                    (this.mode=='add') ? this.saveRecurrent() : this.editRecurrent();
                } else {
                    this.alertService.setErrorAlert("Campos requeridos", "Debes indicar la frecuencia con la que se agregará el cobro");
                }
            }
        } else {
            this.alertService.setErrorAlert("Campos requeridos", "La fecha del primer cargo y la lógica son requeridos");
        }
    }

    saveRecurrent(){
        let data = this.getDataFormated();
        this.savingRecurrent = true;
        this.financeService.saveRecurrentCharge( data ).subscribe(
            resData => {
                if( resData.auth ){
                    this.alertService.setSuccessAlert("Éxito", "Cargo recurrente guardado correctamente", () => {
                        this.resetAll();
                    });
                } else {
                    this.onSessionExpired();
                }
                this.savingRecurrent = false;
            }, error => {
                this.savingRecurrent = false;
                this.alertService.setErrorAlert("Error", error);
            }
        )
    }

    editRecurrent(){
        //updateRecurrentCharge
        let data = this.getDataFormated();
        data.recurrent_id = this.financeService.editingRecurrent.id;
        this.savingRecurrent = true;
        this.financeService.updateRecurrentCharge( data ).subscribe(
            resData => {
                if( resData.auth ){
                    this.alertService.setSuccessAlert("Éxito", "Cargo recurrente actualizado correctamente", () => {
                        //Close modal
                        this.financeService.hostViewContainerRef.clear();
                    });
                } else {
                    this.onSessionExpired();
                }
                this.savingRecurrent = false;
            }, error => {
                this.savingRecurrent = false;
                this.alertService.setErrorAlert("Error", error);
            }
        )
    }

    getDataFormated(){
        var properties: {[key:string]: any} = {};
        for( let property of this.propertiesData ){
            if( property.selected ){
                properties[property.id] = property.amount;
            }
        }

        var date_charge: string = this.frequencyDate.year + "/" +(this.frequencyDate.month<10 ? "0" + this.frequencyDate.month : this.frequencyDate.month)+"/"+(this.frequencyDate.day<10 ? "0"+this.frequencyDate.day : this.frequencyDate.day);
        var days: number[] = [];
        var freq: {[key: string]: string} = {};
        if( this.frequencyData.logic=="by_days" ){
            for(let day of this.frequencyData.days ){
                if( day.selected ){
                    days.push( day.day );
                }
            }
        } else if( this.frequencyData.logic=="by_frequency" ){
            freq.each = this.frequencyData.frequency.each;
            freq.type = this.frequencyData.frequency.type;
        }

        var frequency: {[key:string]: any} = {
            date: date_charge,
            logic: this.frequencyData.logic,
            days: days,
            freq: freq
        };

        var data = {
            general: this.generalData,
            properties: properties,
            frequency: frequency,
            recurrent_id: null
        };
        return data;
    }

    resetAll(){
        this.generalData = {
            "name": "",
            "description": "",
            "billtype": "",
            "account": "",
            "currency": ""
        };
        this.propertiesData = [];
        this.propertiesFilters = {
            bedrooms: "",
            property_type: "",
            amount: ""
        };
        this.frequencyData = {
            date: null,
            minDate: {
                year: 0,
                month: 0,
                day: 0
            },
            logic: "",
            days: [
                { day: 1, selected : false, name: "Lunes" },
                { day: 2, selected : false, name: "Martes" },
                { day: 3, selected : false, name: "Miércoles" },
                { day: 4, selected : false, name: "Jueves" },
                { day: 5, selected : false, name: "Viernes" },
                { day: 6, selected : false, name: "Sábado" },
                { day: 7, selected : false, name: "Domingo" }
            ],
            frequency: {
                each: "",
                type: ""
            }
        };
        this.toStep("step1");
        this.enabledOptions = {
            "step1": true,
            "step2": false,
            "step3": false
        };
    }

    areSelectedDays(){
        for( let day of this.frequencyData.days ){
            if( day.selected ){
                return true;
            }
        }
        return false;
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([
            {
              name: "Agregar",
              icon: faPlusCircle,
              link: "finanzas/recurrentes/agregar"
            },
            {
              name: "Lista de Cargos Recurrentes",
              icon: faListAlt,
              link: "finanzas/recurrentes"
            }
          ]
        );
    }

    isEnabled(step: string){
        return this.enabledOptions[step];
    }

    onStepClicked(option: any){
        if( !this.savingRecurrent && this.enabledOptions[option] ){
            this.selectedOption=option;
        }
    }

    onSessionExpired(){
        this.alertService.setErrorAlert("Session expired", "Your session is expired");
    }

}
