import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faListAlt, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SubmenuService } from 'src/app/panel/submenu.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { FinanceService } from '../../finance.service';

@Component({
    selector: 'app-finance-transactions-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.css']
})
export class TransactionAddComponent implements OnInit, AfterViewInit {

    options: string[] = [ "charge", "deposit", "multipe-charge", "multiple-deposit", "import" ];
    optionsName: { [key: string] : string } = { 
        "charge" : "Agregar cargo",
        "deposit" : "Agregar depósito",
        "multipe-charge" : "Agregar múltiples cargos",
        "multiple-deposit": "Agregar múltiples depósitos",
        "import" : "Importar transacciones"
    };
    selectedOption = this.options[0];

    viewInit: boolean = false;
    errorMessage: string = "";
    loaded: boolean = false;

    faSpinner = faSpinner;

    constructor( private submenuService: SubmenuService, private route: ActivatedRoute, private financeService: FinanceService, public alertService: AlertService) { }

    ngOnInit(): void {
        console.log("finance on init");
        
        this.updateRootMenu();
        //this.submenuService.rightOptions.next([]);
        this.updateNavBar();

        //GET DATA
        this.getMeta();
    }

    ngAfterViewInit(){
        console.log("ADD TRANSACTION NG AFTER VIEW INIT");
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
                this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([
            {
              name: "Agregar Transacción",
              icon: faPlusCircle,
              link: "finanzas/transacciones/agregar"
            },
            {
              name: "Lista de Transacciones",
              icon: faListAlt,
              link: "finanzas/transacciones"
            }
          ]
        );
    }

    getMeta(){
        this.financeService.getAddTransactionMeta().subscribe(
            resData => {
                if( resData.auth ){
                   this.loaded = true;
                }
            }, errorMessage => {
                this.alertService.setErrorAlert("Error", errorMessage);
                //this.errorMessage = errorMessage;
            }
        );
    }

    onSessionExpired(){
        this.errorMessage = "Session expired.";
    }
}
