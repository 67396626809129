export class User {
    constructor( 
        public email: string, 
        public name: string,
        public id: string, 
        private _token: string,
        private _tokenExpirationDate: Date,
        public modules?: {
            [key: string]: string;
        }
    ){
        //console.log("MODULES", this.modules);
    }

    get token(): string | null{
        if( !this._tokenExpirationDate || new Date() > this._tokenExpirationDate ){
            return null;
        }
        return this._token;
    }
}