import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faSignOutAlt, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { RightOption, SubmenuService } from './submenu.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent implements OnInit, OnDestroy, AfterViewInit {

  holdOptons: RightOption[] = [];
  //optionsSub?: Subscription
  loaded = false
  //faUsers = faUsers;
  //faHome = faHome;
  //faDollarSign = faDollarSign;
  faUserCog = faUserCog;
  faSignOutAlt = faSignOutAlt;
  //faChartBar = faChartBar;

  currentRoot: string = "";

  constructor( public submenuService: SubmenuService, public authService: AuthService) {
  }

  ngOnInit(): void {
    // this.submenuService.currentRoot.subscribe( (root) => {
    //   console.log("PANEL on init, root changed:", root);
    //   this.currentRoot = root;
    //   this.submenuService.regenerateMenu(this.currentRoot);
    // });

    this.submenuService.generateMainMenu();
    // this.optionsSub = this.submenuService.rightOptions.subscribe( (options) => {
    //   console.log("on update right options");
    //   this.rightOptions = options;
    // });
    console.log("Panel oninit");
  }

  onOptionSelected(e: Event){
    let action = (<HTMLElement>e.target).innerText;
    this.submenuService.panelSwitch( action );
  }

  ngAfterViewInit(): void {
    //console.log("panelComponent ngAfterViewInit");
    this.submenuService.panelStatus = "ready";
    //this.submenuService.regenerateMenu(this.currentRoot);
  }

  onLogout(){
    this.authService.logout();
  }

  ngOnDestroy(){
    //this.optionsSub?.unsubscribe();
    //this.submenuService.currentRoot.unsubscribe();
  }

}