import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FinanceService } from '../../../finance.service';

@Component({
    selector: 'app-finance-multiple-selector',
    templateUrl: './multiple-selector.component.html',
    styleUrls: ['./multiple-selector.component.css']
})
export class MultipleSelectorComponent implements OnInit {

    @Output() close = new EventEmitter<void>();
    @Input() type: string = "";
    @Input() selector: string = "";
    @Input() size: string = "modal-md";

    faCalendar = faCalendarAlt;

    date: {[key: string]: string} = {};
    currentValue: any;
    onlyEmpty: boolean = false;
    billtype: string = "";
    description: string = "";
    amount: string = "";
    notification: string = "1";

    constructor( public financeService: FinanceService ) { }

    ngOnInit(): void {
        //console.log( this.type, this.selector );
    }

    onApply(){
        if( this.passValidation() ){
            this.financeService.multipleSelector.next({ 
                type: this.type, 
                selector: this.selector, 
                value: this.currentValue,
                onlyEmpty: this.onlyEmpty
            });
            this.onClose();
        }
    }

    passValidation(){
        if( this.selector=="date" ){
            //&& this.date.hasOwnProperty("year") && this.date["year"]!=""
            this.currentValue = this.date;
            return true;
        } else if( this.selector=="billtype" ){
            this.currentValue = this.billtype;
            return true;
        } else if( this.selector=="description" ){
            this.currentValue = this.description;
            return true;
        } else if( this.selector=="amount" ){
            this.currentValue = this.amount;
            return true;
        } else if( this.selector=="notification" ){
            this.currentValue = this.notification;
            return true;
        }
        return false;
    }

    onClose(){
        this.close.emit();
    }

}
