<app-alert *ngIf="alertService.showAlert"></app-alert>
<ng-template appPlaceholder></ng-template>
<div class="margin-top-lg">
    <div class="row">
        <div class="col-md-12 form-group">
            <label>Cuenta</label>
            <select class="form-control" [(ngModel)]="account">
                <option value=""></option>
                <option *ngFor="let account of financeService.meta.accounts" [value]="account.id">{{account.name}}</option>
            </select>
        </div>
    </div>
    <hr>
    <table class="table tablev2 table-bordered">
        <thead>
            <tr>
                <th>Propiedad</th>
                <th>Fecha <fa-icon [icon]="faClone" (click)="openSelector('charge', 'date')"></fa-icon></th>
                <th>Tipo de Cobro <fa-icon [icon]="faClone" (click)="openSelector('charge', 'billtype')"></fa-icon></th>
                <th>Descripción <fa-icon [icon]="faClone" (click)="openSelector('charge', 'description')"></fa-icon></th>
                <th>Cantidad <fa-icon [icon]="faClone" (click)="openSelector('charge', 'amount')"></fa-icon></th>
                <th>Notificación <fa-icon [icon]="faClone" (click)="openSelector('charge', 'notification')"></fa-icon></th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of multipleData">
                <td>
                    {{row.property_name}}
                </td>
                <td>
                    <div class="input-group">
                        <span class="input-group-addon">
                            <a href="javascript:;" (click)="d.toggle()">
                                <fa-icon [icon]="faCalendar"></fa-icon>
                            </a>
                        </span>
                        <input class="form-control datepicker" placeholder="yyyy-mm-dd" [(ngModel)]="row.date" ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()">
                    </div>
                </td>
                <td>
                    <select class="form-control" [(ngModel)]="row.billtype_id" autocomplete="off">
                        <option value=""></option>
                        <option *ngFor="let billtype of financeService.meta.billtypes" [value]="billtype.id">{{billtype.name}}</option>
                    </select>
                </td>
                <td>
                    <input type="text" class="form-control" [(ngModel)]="row.description" autocomplete="off">
                </td>
                <td>
                    <div class='input-group'>
                        <span class="input-group-addon">$</span>
                        <input type="text" class="form-control" [OnlyNumber]="true" [AllowDot]="true" [AllowNegative]="false" noCopyPaste autocomplete="off" [(ngModel)]="row.amount">
                    </div>
                </td>
                <td>
                    <select class="form-control" [(ngModel)]="row.notification">
                        <option value="1">Enviar</option>
                        <option value="0">No enviar</option>
                    </select>
                </td>
                <td class="text-center">
                    <fa-icon [icon]="faDelete" class="text-danger" (click)="onDeleteRow(row)"></fa-icon>
                </td>
            </tr>
        </tbody>
    </table>
    <hr>
    <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="text-right">
        <button class="btn btn-primary" (click)="onAddMultipleCharges()" *ngIf="!saving">Agregar</button>
        <span *ngIf="saving">Agregando transacciones <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></span>
    </div>
</div>