<div class="page-container">
    <div class="row">
        <div class="col-md-4">
            <h3>Gestión de tipos de cobro</h3>
            <ul>
                <li><a [routerLink]="['/panel/finanzas/tipos-cobros/']">Ver, editar y eliminar de tipos de cobro</a></li>
                <li><a [routerLink]="['/panel/finanzas/tipos-cobros/agregar']">Agregar tipo de cobro</a></li>
            </ul>
        </div>
        <div class="col-md-4">
            <h3>Transacciones</h3>
            <ul>
                <li><a [routerLink]="['/panel/finanzas/transacciones/']">Ver, editar y eliminar transacciones</a></li>
                <li><a [routerLink]="['/panel/finanzas/transacciones/agregar']">Agregar / Importar transacciones</a></li>
            </ul>
        </div>
        <div class="col-md-4">
            <h3>Cargos Recurrentes</h3>
            <ul>
                <li><a [routerLink]="['/panel/finanzas/recurrentes/']">Ver, editar y eliminar cargos recurrentes</a></li>
                <li><a [routerLink]="['/panel/finanzas/recurrentes/agregar']">Agregar cargo recurrente</a></li>
            </ul>
        </div>
        <div class="col-md-4">
            <h3>Configuración</h3>
            <ul>
                <li><a [routerLink]="['/panel/finanzas/configuracion/']">Ir a configuración</a></li>
            </ul>
        </div>
    </div>
</div>