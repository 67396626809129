<div id="center-wrapper">
    <div id="clock-wrapper">
        <fa-icon [icon]="faClock"></fa-icon>
    </div>
    <div class="text-center txt-color">
        <h1>Coming Soon</h1>
    </div>
    <div class="text-center txt-color">
        Get ready, something cool is coming!
    </div>
</div>