import { NgModule } from '@angular/core';
import { EditPropertyComponent } from './edit/edit.component';
import { AddPropertyComponent } from './add/add.component';
import { PropertiesComponent } from './properties.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';

export const propertyRoutes: Routes = [
    { path: "propiedades", canActivate: [AuthGuard], component: PropertiesComponent },
    { path: "propiedades/agregar", canActivate: [AuthGuard], component: AddPropertyComponent }
];

@NgModule({
    declarations: [
        PropertiesComponent,
        AddPropertyComponent,
        EditPropertyComponent
    ],
    imports: [ 
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule
    ],
    exports: [],
    providers: [],
    entryComponents: [
        EditPropertyComponent
    ]
})
export class PropertiesModule {}