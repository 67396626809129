import {  AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmenuService } from '../submenu.service';

import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PropertiesService } from './properties.service';
import { EditPropertyComponent } from './edit/edit.component';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

// interface UserRows {
//   id: string,
//   name: string,
//   email: string,
//   phone: string,
//   type: string,
//   status: string,
//   visible: boolean
// }

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit, AfterViewInit {

  //users: UserRow[] = [];
  faSearch = faSearch;
  syncing: boolean = true;
  faSpinner = faSpinner;
  errorMessage = "";
  viewInit: boolean = false;
  firstYear: number = 2021;
  years: {year: number, selected: boolean}[] = [];
  selectedYear: number = 2021;

  apiUrl: string = "";

  propertiesListBackup: any = null;

  private closeSub?: Subscription;

  @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 

  constructor( private submenuService: SubmenuService, private router: Router, private route: ActivatedRoute, public propertiesService: PropertiesService, private componentFactoryResolver: ComponentFactoryResolver, private authService: AuthService) {
    this.apiUrl = environment.endpoint;
  }

  ngOnInit(): void {
    console.log("Properties on init");
    this.updateRootMenu();

    //generate report years
    this.loadYears();

    //Get Properties
    this.getProperties();
  }

  ngAfterViewInit(){
    this.viewInit = true;
  }

  loadYears() {
    const current = new Date().getFullYear();
    for (let y = this.firstYear; y <= current; y++) {
        this.years.push({
            year: y,
            selected: y === current
        });
    }
    this.selectedYear = current;
  }

  updateRootMenu(){
    if( !this.viewInit ){
      setTimeout( () => { 
        this.submenuService.updateRoot( this.route.snapshot );
      }, 10);
    } else {
      this.updateRootMenu();
    }
  }

  onSessionExpired(){
    this.errorMessage = "Session expired.";
  }

  getProperties(){
    this.syncing = true;
    this.propertiesService.propertiesList = [];
    this.propertiesService.getProperties(this.propertiesService.filters).subscribe( 
      resData => {
        this.syncing = false;
        if( resData.auth ){
          if( resData.hasOwnProperty("properties") ){
            this.propertiesService.propertiesList = resData.properties;
            this.propertiesListBackup = resData.properties;
          }
          console.log(resData);
        } else {
          this.onSessionExpired();
        }
      },
      errorMessage => {
        this.syncing = false;
        this.errorMessage = errorMessage;
      }
    );
  }

  editProperty( property_id: string ){
    console.log( "Open in modal", property_id );

    const editCmpFactory = this.componentFactoryResolver.resolveComponentFactory(EditPropertyComponent);

    const hostViewContainerRef = this.editHost?.viewContainerRef;
    hostViewContainerRef?.clear();

    const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

    componentRef!.instance.propertyId = property_id;
    componentRef!.instance.size = "modal-xl";

    this.propertiesService.hostViewContainerRef = hostViewContainerRef;

    this.closeSub = componentRef?.instance.close.subscribe( () => {
      this.closeSub?.unsubscribe();
      hostViewContainerRef?.clear();
    });
  }
  
  onFilterStatusChange(event: Event){
    this.propertiesService.filters.status = +(<HTMLSelectElement>event.target).value;
    this.getProperties();
  }

  onSearch( event: Event ){
    let search = (<HTMLInputElement>event.target).value;
    //console.log(search);

    this.propertiesService.propertiesList = this.propertiesListBackup.filter((property: {[key: string]: any}) => {
      //console.log(property);
      return (property.name.toLowerCase().includes(search.toLowerCase()) || property.owner.toLowerCase().includes(search.toLowerCase()));
    });
    
  }

  getOwnerReportLink( property: {[key:string]: string}, report_type: string){
    var user_id = this.authService.currentUser?.id;
    var token = this.authService.currentUser?.token;
    var url = this.apiUrl + "/reports/admin/?oid="+property.owner_id+"&uid="+user_id+"&token="+token+"&report="+report_type+"&year="+this.selectedYear;
    return url;
  }
}
