import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChartBar, faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faDollarSign, faFileInvoiceDollar, faHistory, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { SubmenuService } from '../submenu.service';
import { ReportsFiltersComponent } from './filters/filters.component';
import { HistoryComponent } from './history/history.component';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

    faTransactions = faFileAlt;
    faResume = faChartBar;
    faBalances = faDollarSign;
    faHistory = faHistory;

    viewInit: boolean = false;

    @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective;

    private closeSub?: Subscription;

    constructor(public alertService: AlertService, public submenuService: SubmenuService, private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit(): void {
        this.updateRootMenu();
        this.updateNavBar();
    }

    ngAfterViewInit(){
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([]);
    }

    openHistory(){
        //console.log( "Open modal" );
        const editCmpFactory = this.componentFactoryResolver.resolveComponentFactory(HistoryComponent);

        const hostViewContainerRef = this.editHost?.viewContainerRef;
        hostViewContainerRef?.clear();

        const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

        //componentRef!.instance.propertyId = property_id;
        componentRef!.instance.size = "modal-xl";

        //this.propertiesService.hostViewContainerRef = hostViewContainerRef;

        this.closeSub = componentRef?.instance.close.subscribe( () => {
            this.closeSub?.unsubscribe();
            hostViewContainerRef?.clear();
        });
    }

    openReportFilters( reportId: number ){
        const editCmpFactory = this.componentFactoryResolver.resolveComponentFactory(ReportsFiltersComponent);

        const hostViewContainerRef = this.editHost?.viewContainerRef;
        hostViewContainerRef?.clear();

        const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

        componentRef!.instance.reportId = reportId;
        componentRef!.instance.size = "modal-md";

        //this.propertiesService.hostViewContainerRef = hostViewContainerRef;

        this.closeSub = componentRef?.instance.close.subscribe( () => {
            this.closeSub?.unsubscribe();
            hostViewContainerRef?.clear();
        });
    }

}
