<app-alert *ngIf="alertService.showAlert"></app-alert>
<ng-template appPlaceholder></ng-template>
<div class="margin-top-lg">
    <div class="text-center">
        <div *ngIf="uploading">
            <fa-icon [icon]="faSpinner" [spin]=true></fa-icon> Subiendo...
        </div>
        <form #f="ngForm" *ngIf="!uploading">
            <a href="javascript:;" class='import-wrap' (click)="onUploadFile()" ><fa-icon class='import-icon' [icon]="faExcel"></fa-icon><br>Subir archivo xls</a>
            <input type="file" id="import_file" name="file" (change)="onFileSelection($event)">
        </form>
    </div>
</div>