import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor( private authService: AuthService, private router: Router){}

    getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {

        return this.authService.isAuthenticated()
            .then(
                (authenticated) => {
                    const current_url = this.getResolvedUrl(route);
                    console.log("canActivate", authenticated, current_url);
                    if ( authenticated ){
                        return true;
                    } else {
                        this.router.navigate(['/']);
                    }
                    return false;
                }
            );
    }
}