import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faListAlt, faPlusCircle, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { SubmenuService } from '../../submenu.service';
import { FinanceService } from '../finance.service';
import { BilltypeEditComponent } from './edit/edit.component';

@Component({
    selector: 'app-finance-billtypes',
    templateUrl: './billtypes.component.html',
    styleUrls: ['./billtypes.component.css']
})
export class BillTypesComponent implements OnInit {
    viewInit: boolean = false;
    errorMessage: string = "";
    syncing: boolean = false;

    faPlusCircle = faPlusCircle;
    faSearch = faSearch;
    faSpinner = faSpinner;

    
    private closeSub?: Subscription;

    @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 

    constructor( private submenuService: SubmenuService, private route: ActivatedRoute, public financeService: FinanceService, private componentFactoryResolver: ComponentFactoryResolver ) { }

    ngOnInit(): void {
        console.log("finance on init");
        
        this.updateRootMenu();
        //this.submenuService.rightOptions.next([]);
        this.updateNavBar();

        this.getBilltypes();
    }

    ngAfterViewInit(){
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([
            {
              name: "Agregar Tipo de Cobro",
              icon: faPlusCircle,
              link: "finanzas/tipos-cobros/agregar"
            },
            {
              name: "Lista de Tipos de Cobros",
              icon: faListAlt,
              link: "finanzas/tipos-cobros"
            }
          ]
        );
    }

    onSessionExpired(){
        this.errorMessage = "Session expired.";
    }

    onSearch( event: Event ){
        let search = (<HTMLInputElement>event.target).value;
        //console.log(search);

        this.financeService.billtypesList = this.financeService.billtypesListBackup.filter((billtype: {[key: string]: any}) => {
        //console.log(user);
            return (billtype.name.toLowerCase().includes(search.toLowerCase()) );
        });
    }

    onFilterStatusChange( event: Event ){

    }

    getBilltypes(){
        this.syncing = true;
        this.financeService.billtypesList = [];
        this.financeService.getBillTypes(this.financeService.filters).subscribe( 
        resData => {
            this.syncing = false;
            if( resData.auth ){
            if( resData.hasOwnProperty("billtypes") ){
                this.financeService.billtypesList = resData.billtypes;
            }
            console.log(resData);
            } else {
                this.onSessionExpired();
            }
        },
        errorMessage => {
            this.syncing = false;
            this.errorMessage = errorMessage;
        }
        );
    }

    editBillType( billtype_id: string ){
        const editCmpFactory = this.componentFactoryResolver.resolveComponentFactory(BilltypeEditComponent);

        const hostViewContainerRef = this.editHost?.viewContainerRef;
        hostViewContainerRef?.clear();

        const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

        this.financeService.setEditingBilltype( billtype_id );
        componentRef!.instance.size = "modal-xl";

        this.financeService.hostViewContainerRef = hostViewContainerRef;

        this.closeSub = componentRef?.instance.close.subscribe( () => {
            this.closeSub?.unsubscribe();
            hostViewContainerRef?.clear();
        });
    }
}
