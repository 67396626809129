<app-alert *ngIf="alertService.showAlert"></app-alert>
<ng-template appPlaceholder></ng-template>
<div class="page-container">
    <div class="alert alert-info">
        <div class="row">
            <div class="col-md-3 form-group">
                <div class="input-group">
                    <span class="input-group-addon">
                        <a href="javascript:;" (click)="dfrom.toggle()"><fa-icon [icon]="faCalendar"></fa-icon> Fecha Inicial</a>
                    </span>
                    <input class="form-control datepicker"
                         name="dp" [(ngModel)]="dateFrom" ngbDatepicker #dfrom="ngbDatepicker" autocomplete="off" readonly (click)="dfrom.toggle()">
                </div>
            </div>
            <div class="col-md-3 form-group">
                <div class="input-group">
                    <span class="input-group-addon">
                        <a href="javascript:;" (click)="dto.toggle()"><fa-icon [icon]="faCalendar"></fa-icon> Fecha Final</a>
                    </span>
                    <input class="form-control datepicker"
                         name="dp" [(ngModel)]="dateTo" ngbDatepicker #dto="ngbDatepicker" autocomplete="off" readonly (click)="dto.toggle()">
                </div>
            </div>
            <div class="col-md-3 form-group">
                <select class='form-control' [(ngModel)]="financeService.transFilters.account_id">
                    <option value="">Todas las cuentas</option>
                    <option *ngFor="let account of financeService.meta.accounts" [value]="account.id">{{account.name}}</option>
                </select>
            </div>
            <div class="col-md-3 form-group">
                <select class='form-control' [(ngModel)]="financeService.transFilters.property_id">
                    <option value="">Todas las propiedades</option>
                    <option *ngFor="let property of financeService.meta.properties" [value]="property.id">{{property.name}}</option>
                </select>
            </div>
            <div class="col-md-3 form-group">
                <select class='form-control' [(ngModel)]="financeService.transFilters.currency">
                    <option value="">Todas las monedas</option>
                    <option value="MXN">MXN</option>
                    <option value="USD">USD</option>
                </select>
            </div>
            <div class="col-md-3 form-group">
                <select class='form-control' [(ngModel)]="financeService.transFilters.transtype">
                    <option value="">Todas las transacciones</option>
                    <option value="-1">Todos los cobros</option>
                    <option value="-2">Todos los depósitos</option>
                    <optgroup label="Tipos de cobro">
                        <option *ngFor="let billtype of financeService.meta.billtypes" [value]="billtype.id">{{billtype.name}}</option>
                    </optgroup>
                </select>
            </div>
            <div class="col-md-3 form-group">
                <select class='form-control' [(ngModel)]="financeService.transFilters.status">
                    <option value="">Todos los estados</option>
                    <option value="1">Solo transacciones activas</option>
                    <option value="2">Solo transacciones canceladas</option>
                </select>
            </div>
            <div class="col-md-12 text-right">
                <hr>
                <div *ngIf="applyingFilters">
                    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Obteniendo transacciones...
                </div>
                <button *ngIf="!applyingFilters" class="btn btn-info" (click)="applyFilters()">Aplicar Filtros</button>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table tablev2 table-bordered table-hover">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha (Año-Mes-Día)</th>
                    <th>Propiedad</th>
                    <th>Descripción</th>
                    <th class='text-right'>Total</th>
                </tr>
            </thead>
            <tbody *ngIf="!applyingFilters">
                <tr *ngFor="let transaction of financeService.transactionsList" [ngClass]="{canceled: transaction.status==2}">
                    <td><a href="javascript:;" (click)="onEditTransaction(transaction.id)">{{transaction.id}}</a></td>
                    <td>{{transaction.date}}</td>
                    <td>{{transaction.property_name}}<br><small>{{transaction.owner_name}}</small></td>
                    <td><span class='subdesc'>{{getTransactionBilltype(transaction)}}</span><br>{{transaction.description}}<br><small class='text-muted'>{{transaction.account}} - Por {{transaction.author}}</small></td>
                    <td class="text-right"><fa-icon [icon]="getTransactionIcon(transaction)" class="{{getTransactionIconColor(transaction)}}"></fa-icon> {{getTransactionTotal(transaction)}}<br>
                        <small class="text-muted">{{transaction.currency}}</small>
                    </td>
                </tr>
                <tr *ngIf="!applyingFilters && financeService.transactionsList.length==0">
                    <td colspan="5">No se encontraron transacciones con los filtros seleccionados</td>
                </tr>
            </tbody>
            <tfoot *ngIf="applyingFilters">
                <tr>
                    <td colspan="5"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Actualizando lista...</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>