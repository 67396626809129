<app-alert *ngIf="alertService.showAlert"></app-alert>
<div class='modal' id='modal_wrapper'>
    <div id='modal_block' class='popup_bg_modal'></div>
    <div id='modal_block_text' class='hidden'>Please wait <i class='fa fa-spinner fa-spin'></i></div>
    <div id='modal_wrap' class='modal-dialog {{size}}'>
      <div class='modal-content modal-content-v2'>

        <!-- Modal Header -->
        <div class='modal-header'>
          <i id='back-model' class='fa fa-arrow-left margin-right-md hidden pointer' onclick='javascript: ".$this->js_instance.".modal_back();'></i>
          <label id='modal_title' class='modal-title font-18'>{{title}}</label>
          <button id='modal_close' type='button' class='close' (click)="onClose()">&times;</button>
        </div>

        <!-- Modal body -->
        <div id='modal_body' class='modal-body'>
            <div class="page-container" *ngIf="loading">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
            </div>
            <div class="page-container" *ngIf="!loading">
                <div class="row">
                    <div class="col-md-6 form-group" *ngIf="reportId!=3">
                        <label>Fecha Inicial</label>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <a href="javascript:;" (click)="dfrom.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                            </span>
                            <input class="form-control datepicker"
                                 name="dp" [(ngModel)]="filters.from" ngbDatepicker #dfrom="ngbDatepicker" autocomplete="off" readonly (click)="dfrom.toggle()">
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label *ngIf="reportId!=3">Fecha Final</label>
                        <label *ngIf="reportId==3">Balance al</label>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <a href="javascript:;" (click)="dto.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                            </span>
                            <input class="form-control datepicker"
                                 name="dp" [(ngModel)]="filters.to" ngbDatepicker #dto="ngbDatepicker" autocomplete="off" readonly (click)="dto.toggle()">
                        </div>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="reportId!=3">
                        <label for="property">Propiedad</label>
                        <select name="property" id="property" [(ngModel)]="filters.property_id" class="form-control" autocomplete="off">
                            <option value="">Todas las propiedades</option>
                            <option [value]="property.id" *ngFor="let property of financeService.meta.properties">{{property.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-12 form-group">
                        <label for="account">Cuenta</label>
                        <select name="account" id="account" [(ngModel)]="filters.account_id" class="form-control" autocomplete="off">
                            <option value=""></option>
                            <option [value]="account.id" *ngFor="let account of financeService.meta.accounts">{{account.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="reportId!=3">
                        <label for="billtype">Tipo de Cobro</label>
                        <select name="billtype" id="billtype" [(ngModel)]="filters.billtype_id" class="form-control" autocomplete="off">
                            <option value="">Todos los tipos de cobro</option>
                            <option [value]="billtype.id" *ngFor="let billtype of financeService.meta.billtypes">{{billtype.name}}</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-12 form-group">
                        <label for="currency">Moneda</label>
                        <select name="currency" id="currency" [(ngModel)]="filters.currency" class="form-control" autocomplete="off">
                            <option value="">USD & MXN</option>
                            <option value="USD">USD</option>
                            <option value="MXN">MXN</option>
                        </select>
                    </div> -->
                </div>
                <hr>
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="text-right">
                    <button class="btn btn-danger" (click)="onPdfReportGenerate()"><fa-icon [icon]="faPdf"></fa-icon> Generate PDF</button>
                </div>
            </div>
        </div>

        <!-- Modal Footer -->
        <!-- <div id='modal_footer' class='modal-footer'>
              
        </div> -->

        <div id='modal_msg' class='modal_msg_wrapper'></div>

      </div>
    </div>
  </div>