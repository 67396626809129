import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {

  errorMessage = "";
  private userSub?: Subscription;
  isAuthenticated = false;
  faSpinner = faSpinner
  loggingIn = false;

  constructor( public authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe( user => {
      this.isAuthenticated = !!user;
    });
  }

  onSubmit( form: NgForm){
    this.errorMessage = "";
    
    if( !form.valid ){
      return;
    }

    this.loggingIn = true;

    this.authService.login(form.value["email"], form.value["password"] ).subscribe(
      resData => {
        if( resData.auth ){

          this.authService.isLoggedIn = true;
          this.router.navigate(['/panel']);

        } else {
          this.errorMessage = resData.error;
          this.authService.isLoggedIn = false;
          this.authService.user.next(null);
        }
        this.loggingIn = false;
        
        console.log( resData );
      }, errorMessage => {
        this.errorMessage = errorMessage;
        this.authService.isLoggedIn = false;
        this.authService.user.next(null);
        this.loggingIn = false;
        console.log( "errorMessage", errorMessage );
      }
    );

    form.reset( { email: form.value["email"], password: "" } );
  }

  ngOnDestroy(){
    this.userSub?.unsubscribe();
  }

}
