<div class='modal' id='modal_wrapper'>
    <div id='modal_block' class='popup_bg_modal'></div>
    <div id='modal_block_text' class='hidden'>Please wait <i class='fa fa-spinner fa-spin'></i></div>
    <div id='modal_wrap' class='modal-dialog {{size}}'>
      <div class='modal-content modal-content-v2'>

        <!-- Modal Header -->
        <div class='modal-header'>
          <i id='back-model' class='fa fa-arrow-left margin-right-md hidden pointer' onclick='javascript: ".$this->js_instance.".modal_back();'></i>
          <label id='modal_title' class='modal-title font-18'>{{title}}</label>
          <button id='modal_close' type='button' class='close' (click)="onClose()">&times;</button>
        </div>

        <!-- Modal body -->
        <div id='modal_body' class='modal-body'>
            <div *ngIf="getTransactionsTotal()==0">
                <div class="page-container no-margin">
                    <div class="alert alert-danger">No se encontraron transacciones en el archivo seleccionado, inténtelo nuevamente con otro archivo</div>
                </div>
            </div>
            <div *ngIf="getTransactionsTotal()>0">
              <div class="page-container no-margin">
                <div>
                    <form #f="ngForm">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="property">Propiedad</label>
                                <select name="property" id="property" required ngModel class="form-control" autocomplete="off">
                                    <option value=""></option>
                                    <option [value]="property.id" *ngFor="let property of financeService.meta.properties">{{property.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="account">Cuenta</label>
                                <select name="account" id="account" required ngModel class="form-control" autocomplete="off">
                                    <option value=""></option>
                                    <option [value]="account.id" *ngFor="let account of financeService.meta.accounts">{{account.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="currency">Moneda</label>
                                <select name="currency" class="form-control" ngModel required>
                                    <option value=""></option>
                                    <option value="MXN">MXN</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="financeService.imported.inicial!='' && financeService.imported.inicial!='0' && financeService.imported.inicial!='0.00'">
                    <hr>
                    <h2>Saldo Inicial</h2>
                    <div class="table-responsivee">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Tipo</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody id="initial_balance">
                                <tr>
                                    <td>
                                        <div class="input-group">
                                            <span class="input-group-addon">
                                                <a href="javascript:;" (click)="dinicial.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                                                <!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                                    
                                                </button> -->
                                            </span>
                                            <input class="form-control datepicker imp-date" placeholder="yyyy-mm-dd" ngModel
                                             ngbDatepicker #dinicial="ngbDatepicker" autocomplete="off" readonly (click)="dinicial.toggle()">
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngIf="isInitialBalancePositive()">Balance Inicial</span>
                                        <select *ngIf="!isInitialBalancePositive()" ngModel class="form-control imp-billtype" autocomplete="off">
                                            <option value=""></option>
                                            <option [value]="billtype.id" *ngFor="let billtype of financeService.meta.billtypes">{{billtype.name}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control imp-description" autocomplete="off" value="Balance Inicial">
                                    </td>
                                    <td>
                                        <div class='input-group'>
                                            <span class="input-group-addon">
                                                $
                                            </span>
                                            <input type="text" id="balance_inicial" class="form-control imp-amount" noCopyPaste [OnlyNumber]=true autocomplete="off" [(ngModel)]="balanceInicial">
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <a href='javascript:;' (click)="onTransactionRowDelete($event)">
                                            <fa-icon [icon]="faTimesCircle" class="text-danger"></fa-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="financeService.imported.abonos.length>0">
                    <hr>
                    <h2>Abonos</h2>
                    <div class="table-responsivee">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Tipo</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody id="abonos">
                                <tr *ngFor="let trans of financeService.imported.abonos">
                                    <td>
                                        <div class="input-group">
                                            <span class="input-group-addon">
                                                <a href="javascript:;" (click)="d.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                                                <!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                                    
                                                </button> -->
                                            </span>
                                            <input class="form-control datepicker imp-date" placeholder="yyyy-mm-dd" ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()">
                                        </div>
                                    </td>
                                    <td>
                                        Abono
                                        <!-- <select required ngModel class="form-control imp-billtype" autocomplete="off">
                                            <option value=""></option>
                                            <option [value]="billtype.id" *ngFor="let billtype of financeService.meta.billtypes">{{billtype.name}}</option>
                                        </select> -->
                                    </td>
                                    <td>
                                        <input type="text" class="form-control imp-description" autocomplete="off" >
                                    </td>
                                    <td>
                                        <div class='input-group'>
                                            <span class="input-group-addon">
                                                $
                                            </span>
                                            <input type="text" class="form-control imp-amount" noCopyPaste [OnlyNumber]=true autocomplete="off" ngModel>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <a href='javascript:;' (click)="onTransactionRowDelete($event)">
                                            <fa-icon [icon]="faTimesCircle" class="text-danger"></fa-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="financeService.imported.cargos.length>0">
                    <hr>
                    <h2>Cargos</h2>
                    <div class="table-responsivee">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Tipo</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody id="cargos">
                                <tr *ngFor="let trans of financeService.imported.cargos">
                                    <td>
                                        <div class="input-group">
                                            <span class="input-group-addon">
                                                <a href="javascript:;" (click)="d.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                                                <!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                                    
                                                </button> -->
                                            </span>
                                            <input class="form-control datepicker imp-date" placeholder="yyyy-mm-dd" ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()">
                                        </div>
                                    </td>
                                    <td>
                                        <select ngModel class="form-control imp-billtype" autocomplete="off">
                                            <option value=""></option>
                                            <option [value]="billtype.id" *ngFor="let billtype of financeService.meta.billtypes">{{billtype.name}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control imp-description" autocomplete="off" >
                                    </td>
                                    <td>
                                        <div class='input-group'>
                                            <span class="input-group-addon">
                                                $
                                            </span>
                                            <input type="text" class="form-control imp-amount" noCopyPaste [OnlyNumber]=true autocomplete="off" ngModel>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <a href='javascript:;' (click)="onTransactionRowDelete($event)">
                                            <fa-icon [icon]="faTimesCircle" class="text-danger"></fa-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr>
                <div id="validation-wrapper">
                    <div class="alert alert-danger" *ngIf="errorMessage">
                        {{errorMessage}}
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-primary" (click)="onAddTransacctions(f)">Agregar Transacciones</button>
                </div>
              </div>
            </div>
        </div>

        <!-- Modal Footer -->
        <!-- <div id='modal_footer' class='modal-footer'>
              
        </div> -->

        <div id='modal_msg' class='modal_msg_wrapper'></div>

      </div>
    </div>
  </div>