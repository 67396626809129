<div id="main-wrapper">
    <div id="login-bg" class="login-bg"></div>
    <div id="login-bg-color" class="login-bg"></div>

    <div id="main">
        <div id="left">
            <div id='left-bg'></div>
            <div id='left-content'>
                <div id="logo-wrapper">
                    <img src="https://api.puertaprivadabills.com/images/puertaprivada-logo2.png" alt="">
                </div>
                <div id='welcome' class='text-center twhite'>
                    <h2 class='tcursive'>Bienvenido</h2>
                    <h4 class='tcursive'>{{authService.currentUser?.name}}</h4>
                    <ul id='panel-options'>
                        <li *ngFor="let option of this.submenuService.leftOptions" class="{{option.style}}">
                            <a [routerLink]="[option.link]" routerLinkActive="active">
                                <fa-icon [icon]="option.icon" *ngIf="option.icon"></fa-icon> {{option.name}}
                            </a>
                        </li>
                        <!-- <li>
                            <a [routerLink]="['usuarios']" routerLinkActive="active">
                                <fa-icon [icon]="faUsers"></fa-icon> Usuarios
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['propiedades']" routerLinkActive="active">
                                <fa-icon [icon]="faHome"></fa-icon> Propiedades
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['finanzas']" routerLinkActive="active">
                                <fa-icon [icon]="faDollarSign"></fa-icon> Finanzas
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['reportes']" routerLinkActive="active">
                                <fa-icon [icon]="faChartBar"></fa-icon> Reportes
                            </a>
                        </li> -->
                        <!-- <li>
                            <a [routerLink]="['cuenta']" routerLinkActive="active">
                                <fa-icon [icon]="faUserCog"></fa-icon> Mi Cuenta
                            </a>
                        </li> -->
                        <li>
                            <a (click)="onLogout()">
                                <fa-icon [icon]="faSignOutAlt"></fa-icon> Cerrar Sesión
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="right">
            <app-navbar></app-navbar>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>