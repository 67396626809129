<ng-template appPlaceholder></ng-template>
<div class="page-container">
    <div class="row form-group">
        <div class="col-md-8">
            <div class="input-group">
                <span class='input-group-addon'>
                    <fa-icon [icon]="faSearch" ></fa-icon>
                </span>
                <input type="text" class='form-control' (keyup)="onSearch($event)">
            </div>
        </div>
        <div class="col-md-4">
            <select class='form-control' (change)="onFilterStatusChange($event)" [ngModel]="propertiesService.filters.status">
                <option value="10">Mostrar todas</option>
                <option value="0">Mostrar solo inactivas</option>
                <option value="1">Mostrar solo activas</option>
            </select>
        </div>
    </div>
    <table class="table table-bordered table-striped table-hover">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Dueños</th>
                <th>Tipo</th>
                <th>Estado</th>
                <th class='text-center'>
                    Reportes de Dueño<br>
                    <select class='form-control text-center' [(ngModel)]="selectedYear">
                        <option *ngFor="let year of years" value="{{year.year}}" [selected]="year.selected">Año: {{year.year}}</option>
                    </select>
                </th>
            </tr>
        </thead>
        <tbody id="list-results">
            <tr *ngIf="errorMessage">
                <td colspan="5">
                    <div class="alert alert-danger">{{errorMessage}}</div>
                </td>
            </tr>
            <tr *ngIf="syncing">
                <td colspan="5"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...</td>
            </tr>
            <tr *ngIf="!syncing && propertiesService.propertiesList.length==0">
                <td colspan="5">No se encontraron propiedades. <a [routerLink]="['/panel/propiedades/agregar/']">Agregar propiedad</a></td>
            </tr>
            <tr *ngFor="let property of propertiesService.propertiesList">
                <td><a href="javascript:;" (click)="editProperty(property.id)">{{property.name}}</a></td>
                <td>{{property.owner}}</td>
                <td>{{property.type}}</td>
                <td>{{property.status}}</td>
                <td class='text-right'>
                    <a *ngIf="property.owner_id" href="{{getOwnerReportLink(property, 'hoa')}}" class='btn btn-hoa' target="_blank">HOA</a>&nbsp;
                    <a *ngIf="property.owner_id && property.type_id != 2" href="{{getOwnerReportLink(property, 'billpay')}}" class='btn btn-billpay' target="_blank">Bill Pay</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>