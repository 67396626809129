<app-alert *ngIf="alertService.showAlert"></app-alert>
<div [ngClass]="{'margin-top-lg': !isEditingMode()}">
    <form (ngSubmit)="onAddCharge(f)" #f="ngForm">
        <div class="row">
            <div class="col-md-6 form-group">
                <label for="property">Propiedad</label>
                <select name="property" id="property" required ngModel class="form-control" autocomplete="off">
                    <option value=""></option>
                    <option [value]="property.id" *ngFor="let property of financeService.meta.properties">{{property.name}}</option>
                </select>
            </div>
            <div class="col-md-6 form-group">
                <label for="date">Fecha</label>
                <!-- <input name="date" id="date" class="form-control" [(ngModel)]="date" ngbDatepicker autocomplete="off"> -->
                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <a href="javascript:;" (click)="d.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                            <!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                
                            </button> -->
                        </span>
                        <input class="form-control datepicker" placeholder="yyyy-mm-dd"
                             name="dp" [(ngModel)]="date" required ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()">
                    </div>
                  </div>
            </div>
            <div class="col-md-6 form-group">
                <label for="account">Cuenta</label>
                <select name="account" id="account" required ngModel class="form-control" autocomplete="off" (change)="onAccountChanged($event)">
                    <option value=""></option>
                    <option [value]="account.id" *ngFor="let account of financeService.meta.accounts">{{account.name}}</option>
                </select>
            </div>
            <div class="col-md-6 form-group">
                <label for="billtype">Tipo de Cobro</label>
                <select name="billtype" id="billtype" required ngModel class="form-control" autocomplete="off">
                    <option value=""></option>
                    <option [value]="billtype.id" *ngFor="let billtype of financeService.meta.billtypes">{{billtype.name}}</option>
                </select>
            </div>
            <div class="col-md-6 form-group">
                <label for="description">Descripción</label>
                <input type="text" name="description" ngModel required id="description" class="form-control" autocomplete="off">
            </div>
            <div class="col-md-6 form-group">
                <label for="amount">Cantidad</label>
                <div class='input-group'>
                    <span class="input-group-addon">
                        $
                    </span>
                    <input type="text" name="amount" id="amount" ngModel required class="form-control" noCopyPaste [OnlyNumber]=true autocomplete="off">
                    <span class="input-group-addon">
                        <select ngModel required name="currency">
                            <option value=""></option>
                            <option *ngFor="let currency of currencies" [value]="currency.currency" >{{currency.currency}}</option>
                        </select>
                    </span>
                </div>
            </div>
            <div class="col-md-6 form-group" *ngIf="mode=='edit'">
                <label for="amount">Tipo de Cambio</label>
                <div class='input-group'>
                    <span class="input-group-addon">
                        $
                    </span>
                    <input type="text" name="tc" id="tc" ngModel required class="form-control" noCopyPaste [OnlyNumber]=true autocomplete="off">
                    <span class="input-group-addon">
                        pesos por dólar
                    </span>
                </div>
            </div>
            <div class="col-md-6 form-group" *ngIf="mode=='edit'">
                <label for="status">Estado</label>
                <select name="status" id="status" [ngStyle]="{backgroundColor: getStatusBgColor()}" required [(ngModel)]="status" class="form-control" autocomplete="off">
                    <option value="1">Activa</option>
                    <option value="2">Cancelada</option>
                </select>
            </div>
            <div class="col-md-12 form-group text-right">
                <div class="alert alert-danger text-left" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <hr>
                <button *ngIf="mode=='add'" type="submit" class="btn btn-primary" [disabled]="f.invalid">Agregar Cargo</button>
                <button *ngIf="mode=='edit'" type="submit" class="btn btn-primary" [disabled]="f.invalid">Guardar Cambios</button>
            </div>
        </div>
    </form>
</div>