import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PropertiesService } from '../properties.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditPropertyComponent implements OnInit {

  @Input() propertyId: string = "";
  @Output() close = new EventEmitter<void>();

  size: string = "modal-md";
  title: string = "Editar Propiedad";
  faSpinner = faSpinner;

  errorMessage: string = "";
  property: any = null;

  constructor( private propertiesService: PropertiesService) { }

  ngOnInit(): void {
    this.getProperty();
  }

  getProperty(){
    this.propertiesService.getProperty( this.propertyId ).subscribe(
      resData => {
        if( resData.auth ){
          if( resData.hasOwnProperty("property") ){
            this.property = resData.property;
          }
          console.log(resData);
        } else {
          this.onSessionExpired();
        }
      }, errorMessage => {
        this.errorMessage = errorMessage;
      }
    );
  }

  onSessionExpired(){
    this.errorMessage = "Session expired.";
  }

  onClose(){
    this.close.emit();
  }

}
