import {  AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmenuService } from '../submenu.service';

import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UsersService } from './users.service';
import { EditUserComponent } from './edit/edit.component';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { Subscription } from 'rxjs';

interface UserRow {
  id: string,
  name: string,
  email: string,
  phone: string,
  type: string,
  status: string,
  visible: boolean
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {

  //users: UserRow[] = [];
  faSearch = faSearch;
  syncing: boolean = true;
  faSpinner = faSpinner;
  errorMessage = "";
  viewInit: boolean = false;

  usersListBackup: any = null;

  private closeSub?: Subscription;

  @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 

  constructor( private submenuService: SubmenuService, private router: Router, private route: ActivatedRoute, public usersService: UsersService, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.updateRootMenu();

    //Get Users
    this.getUsers();
  }

  ngAfterViewInit(){
    this.viewInit = true;
  }

  updateRootMenu(){
    if( !this.viewInit ){
      setTimeout( () => { 
        this.submenuService.updateRoot( this.route.snapshot );
      }, 10);
    } else {
      this.updateRootMenu();
    }
  }

  onSessionExpired(){
    this.errorMessage = "Session expired.";
  }

  getUsers(){
    this.usersService.usersList = null;
    this.usersService.getUsers(this.usersService.filters).subscribe( 
      resData => {
        this.syncing = false;
        if( resData.auth ){
          if( resData.hasOwnProperty("users") ){
            this.usersService.usersList = resData.users;
            this.usersListBackup = resData.users;
          }
          console.log(resData);
        } else {
          this.onSessionExpired();
        }
      },
      errorMessage => {
        this.syncing = false;
        this.errorMessage = errorMessage;
      }
    );
  }

  editUser( user_id: string ){
    console.log( "Open in modal", user_id );

    const editCmpFactory = this.componentFactoryResolver.resolveComponentFactory(EditUserComponent);

    const hostViewContainerRef = this.editHost?.viewContainerRef;
    hostViewContainerRef?.clear();

    const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

    componentRef!.instance.userId = user_id;
    componentRef!.instance.size = "modal-xl";

    this.usersService.hostViewContainerRef = hostViewContainerRef;

    this.closeSub = componentRef?.instance.close.subscribe( () => {
      this.closeSub?.unsubscribe();
      hostViewContainerRef?.clear();
    });
  }
  
  onFilterStatusChange(event: Event){
    this.usersService.filters.status = +(<HTMLSelectElement>event.target).value;
    console.log( this.usersService.filters );
    this.getUsers();
  }

  onSearch( event: Event ){
    let search = (<HTMLInputElement>event.target).value;
    //console.log(search);

    this.usersService.usersList = this.usersListBackup.filter((user: {[key: string]: any}) => {
      //console.log(user);
      return (user.name.toLowerCase().includes(search.toLowerCase()) || 
        user.email.toLowerCase().includes(search.toLowerCase() ) ||
        user.properties_string.toLowerCase().includes(search.toLowerCase()) );
    });
    
  }
}
