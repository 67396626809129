<div class='modal' id='modal_wrapper'>
    <div id='modal_block' class='popup_bg_modal'></div>
    <div id='modal_block_text' class='hidden'>Please wait <i class='fa fa-spinner fa-spin'></i></div>
    <div id='modal_wrap' class='modal-dialog {{size}}'>
      <div class='modal-content modal-content-v2'>

        <!-- Modal Header -->
        <div class='modal-header'>
          <i id='back-model' class='fa fa-arrow-left margin-right-md hidden pointer' onclick='javascript: ".$this->js_instance.".modal_back();'></i>
          <label id='modal_title' class='modal-title font-18'>{{title}}</label>
          <button id='modal_close' type='button' class='close' (click)="onClose()">&times;</button>
        </div>

        <!-- Modal body -->
        <div id='modal_body' class='modal-body'>
            <div *ngIf="!financeService.editingTransaction">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
            </div>
            <div *ngIf="financeService.editingTransaction">
              <div class="page-container no-margin">
                <app-finance-transactions-charge mode="edit" *ngIf="getTransactionType()=='charge'"></app-finance-transactions-charge>
                <app-finance-transactions-deposit mode="edit" *ngIf="getTransactionType()=='deposit'"></app-finance-transactions-deposit>
              </div>
            </div>
        </div>

        <!-- Modal Footer -->
        <!-- <div id='modal_footer' class='modal-footer'>
              
        </div> -->

        <div id='modal_msg' class='modal_msg_wrapper'></div>

      </div>
    </div>
  </div>