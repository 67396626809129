<app-alert *ngIf="alertService.showAlert"></app-alert>
<div class="page-container">
    <div *ngIf="successMessage">
        <div class="alert alert-success">
            <h4 class='no-margin'><fa-icon [icon]="faCheck"></fa-icon> {{successMessage}}</h4>
        </div>
    </div>
    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
    <div >
        <form (ngSubmit)="onSubmit(f)" #f="ngForm" >
            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="name">Nombre</label>
                    <input type="text" class='form-control' name='name' ngModel required autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="type">Tipo</label>
                    <select class='form-control' name='type' id="type" ngModel required>
                        <option value=""></option>
                        <option *ngFor="let type of propertyTypes" [value]="type.id">{{type.name}}</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label for="owner">Dueño</label>
                    <select class='form-control' name='owner' id="owner" [(ngModel)]="ownerSelected" (change)="onOwnerSelected()">
                        <option value=""></option>
                        <option *ngFor="let owner of propertyOwners" [value]="owner.id">{{owner.name}}</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label for="bedrooms">Habitaciones</label>
                    <select class='form-control' name='bedrooms' id='bedrooms' ngModel required>
                        <option *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9]" [value]="number">{{number}}</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label for="currency">Moneda</label>
                    <select class='form-control' name='currency' id='currency' ngModel required>
                        <option value=""></option>
                        <option value="USD">USD</option>
                        <option value="MXN">MXN</option>
                    </select>
                </div>
                <!-- <div class="col-md-6 form-group">
                    <label for="userType">Tipo de Usuario</label>
                    <select name="userType" id="userType" class="form-control" [(ngModel)]="userType" required autocomplete="off">
                        <option value=""></option>
                        <option value="owner">Dueño</option>
                        <option value="admin">Administrativo</option>
                    </select>
                </div> -->
                <div class="col-md-6 form-group" *ngIf="mode=='edit'">
                    <label for="status">Estado</label>
                    <select name="status" id="status" ngModel class="form-control" autocomplete="off">
                        <option value="1">Activo</option>
                        <option value="0">Inactivo</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Additional Owners</label>
                    <!-- <div class="row">
                        <div *ngFor="let additional of additionalOwners; let i = index" class="col-md-6 form-group">
                            <select class='form-control' name="additionals[]" [(ngModel)]="additionalOwnersSelect[i]" (change)="onAdditionalOwnerSelected()">
                                <option value="">{{i}}</option>
                                <option *ngFor="let owner of propertyOwners" [value]="owner.id">{{owner.name}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class='input-group'>
                        <select class='form-control' name="additionals[]" [(ngModel)]="additionalOwnerSelected">
                            <option value=""></option>
                            <option *ngFor="let owner of propertyOwners" [value]="owner.id">{{owner.name}}</option>
                        </select>
                        <span class='input-group-btn'><button type="button" class='btn btn-info' (click)="addAdditionalOwner()"><fa-icon [icon]="faPlus"></fa-icon></button></span>
                    </div>
                    <div class="list-el-container">
                        <span class='list-el' *ngFor="let additional of additionalOwners;let i = index">
                            {{propertyOwnersAssoc[additional]}} <a href="javascript:;" (click)="deleteAdditional(i)"><fa-icon class='text-danger' [icon]="faDelete"></fa-icon></a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="mode=='edit' && !ready">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
            </div>
            <div class="text-right">
                <hr>
                <div class="alert alert-danger text-left" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="text-right text-white" *ngIf="saving">
                    <div *ngIf="mode=='add'">Agregando propiedad, espere por favor <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></div>
                    <div *ngIf="mode=='edit' && !deleteConfirm">Guardando cambios, espere por favor <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></div>
                    <div *ngIf="mode=='edit' && deleteConfirm">Eliminando propiedad, espere por favor <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></div>
                </div>
                <div *ngIf="!saving && mode=='add' && this.ready">
                    <button [disabled]="!f.valid" class="btn btn-primary ">Agregar</button>
                    <!-- <button [disabled]="!form.valid" class="btn btn-primary ">Agregar</button> -->
                </div>
                <div *ngIf="!saving && mode=='edit' && this.ready && !propertyDeleted">
                    <button type="button" class="btn btn-danger" (click)="onDelete()">Eliminar</button> &nbsp;
                    <button type="button" [disabled]="!f.valid" class="btn btn-primary" (click)="onSaveChanges()">Guardar Cambios</button>
                </div>
                <!-- <div class='alert alert-danger' *ngIf="mode=='edit' && deleteConfirm && !saving">
                    <b>Confirma para eliminar el usuario</b><br>
                    <small>*Esta acción no puede revertirse</small><br><br>
                    <button type="button" class='btn btn-default' (click)="deleteConfirm=false;">Cancelar</button>&nbsp; 
                    <button type="button" class="btn btn-danger" (click)="onDelete()">Eliminar</button>
                </div> -->
            </div>
        </form>
    </div>
</div>