<app-alert *ngIf="alertService.showAlert"></app-alert>
<ng-template appPlaceholder></ng-template>
<div class="page-container">
    <div *ngIf="!loaded">
        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
    </div>
    <div class="table-responsive" *ngIf="loaded">
        <table class="table tablev2 table-bordered">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Próximo Cargo</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let recurrent of financeService.recurrents">
                    <td>
                        <a href="javascript:;" (click)="onEditRecurrent(recurrent.id)">{{recurrent.name}}</a>
                    </td>
                    <td>{{recurrent.description}}</td>
                    <td>{{recurrent.next_date_formated}}</td>
                    <td class="text-center">
                        <a href="javascript:;" (click)="onEditRecurrent(recurrent.id)"><fa-icon [icon]="faEdit"></fa-icon></a>&nbsp;&nbsp;
                        <a href="javascript:;" (click)="onDeleteRecurrent(recurrent.id)"><fa-icon [icon]="faDelete" class='text-danger'></fa-icon></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>