import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCalendarAlt, faCheckCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCogs, faSpinner, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { SubmenuService } from '../../submenu.service';
import { FinanceService } from '../finance.service';

@Component({
    selector: 'app-finance-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class FinanceSettingsComponent implements OnInit {

    viewInit: boolean = false;

    faCogs = faCogs;
    faSpinner = faSpinner;
    faCheck = faCheckCircle;
    faEnvelope = faEnvelope;
    faCalendar = faCalendarAlt;
    tc: string = "";
    syncing: boolean = false;
    loaded: boolean = false;

    errorMessage: string = "";
    successMessage: string = "";
    statementsErrorMessage: string = "";
    statementsSuccessMessage: string = "";

    tabs: {[key: string]: boolean} = {
        settings: true,
        statements: false
    };

    statementsSend: {[key: string]: any} = {
        hoa: {
            status: 0,
            statusIcon: faToggleOff,
            each: "",
            frequency: "",
            next_date: {}
        },
        billpay: {
            status: 0,
            statusIcon: faToggleOff,
            each: "",
            frequency: "",
            next_date: {}
        }
    }

    //today: Date = new Date();
    tomorrow: Date = new Date();
    minDate = {
        year: 0,
        month: 0,
        day: 0
    };

    constructor( private submenuService: SubmenuService, private route: ActivatedRoute, private financeService: FinanceService) {
        this.tomorrow.setDate( this.tomorrow?.getDate() + 1);
        this.minDate = {
            year: parseInt(formatDate(this.tomorrow, 'yyyy', 'en')),
            month: parseInt(formatDate(this.tomorrow, 'MM', 'en')),
            day: parseInt(formatDate(this.tomorrow, 'dd', 'en'))
        };
    }

    ngOnInit(): void {
        this.updateRootMenu();
        this.updateNavBar();

        this.setSettings();
    }

    ngAfterViewInit(){
        this.viewInit = true;
    }

    setSettings(){
        this.financeService.getSettings().subscribe(
            resData => {
                if( resData.auth ){
                    if( resData.settings.hasOwnProperty("tc") ){
                        this.tc = resData.settings.tc;
                    }
                    if( resData.settings.hasOwnProperty("statement_hoa") ){
                        this.statementsSend.hoa.status = resData.settings.statement_hoa.status;
                        this.statementsSend.hoa.next_date = resData.settings.statement_hoa.next_date;
                        this.statementsSend.hoa.each = resData.settings.statement_hoa.each;
                        this.statementsSend.hoa.frequency = resData.settings.statement_hoa.frequency;
                        this.statementsSend.hoa.statusIcon = (this.statementsSend.hoa.status==0) ? faToggleOff: faToggleOn;
                    }
                    if( resData.settings.hasOwnProperty("statement_billpay") ){
                        this.statementsSend.billpay.status = resData.settings.statement_billpay.status;
                        this.statementsSend.billpay.next_date = resData.settings.statement_billpay.next_date;
                        this.statementsSend.billpay.each = resData.settings.statement_billpay.each;
                        this.statementsSend.billpay.frequency = resData.settings.statement_billpay.frequency;
                        this.statementsSend.billpay.statusIcon = (this.statementsSend.billpay.status==0) ? faToggleOff: faToggleOn;
                    }
                    
                } else {
                    this.onSessionExpired();
                }
                this.loaded = true;
            }, errorMessage => {
                this.loaded = true;
                this.errorMessage = errorMessage;
            }
        );
    }

    onSaveChanges(){
        this.errorMessage = "";
        this.successMessage = "";

        if( this.tc.trim()=="" ){
            this.errorMessage = "Debe proporcionar el tipo de cambio actual";
        } else {
            this.syncing = true;
            let settings = {
                tc: this.tc.trim()
            };
            this.financeService.updateSettings( settings ).subscribe(
                resData => {
                    if( resData.auth ){
                        this.successMessage = "Configuración guardada correctamente";
                    } else {
                        this.onSessionExpired();
                    }
                    this.syncing = false;
                }, errorMessage => {
                    this.syncing = false;
                    this.errorMessage = errorMessage;
                }
            );
        }
    }

    onTabChange( tab: string ){
        if( this.loaded ){
            this.statementsErrorMessage = "";
            this.statementsSuccessMessage = "";
            this.errorMessage = "";
            this.successMessage = "";
            this.tabs = {
                settings: tab=="settings",
                statements: tab=="statements"
            };
        }
    }

    onSwitch( type: string ){
        this.statementsSend[type].status = (this.statementsSend[type].status==0) ? 1 : 0;
        this.statementsSend[type].statusIcon = (this.statementsSend[type].status==0) ? faToggleOff: faToggleOn;
    }

    onSaveStatementsSettings(){
        this.statementsErrorMessage = "";
        this.statementsSuccessMessage = "";

        if( this.statementsSend.hoa.status==1 ){
            if( this.statementsSend.hoa.each=="" || this.statementsSend.hoa.frequency=="" || !this.statementsSend.hoa.next_date.hasOwnProperty("year") || this.statementsSend.hoa.next_date["year"]=="" ){
                this.statementsErrorMessage = "La configuración del envío del estado de cuenta HOA está incompleta";
                return;
            }
        }

        if( this.statementsSend.billpay.status==1 ){
            if( this.statementsSend.billpay.each=="" || this.statementsSend.billpay.frequency=="" || !this.statementsSend.billpay.next_date.hasOwnProperty("year") || this.statementsSend.billpay.next_date["year"]=="" ){
                this.statementsErrorMessage = "La configuración del envío del estado de cuenta Billpay está incompleta";
                return;
            }
        }

        //Save
        this.syncing = true;
        this.financeService.updateStatementsSettings( this.statementsSend ).subscribe(
            resData => {
                if( resData.auth ){
                    this.statementsSuccessMessage = "Cambios guardados correctamente";
                } else {
                    this.statementsErrorMessage = "La sesión ha expirado";
                }
                this.syncing = false;
            }, error => {
                this.syncing = false;
                this.statementsErrorMessage = error;
            }
        )
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([]);
    }

    onSessionExpired(){
        this.errorMessage = "La sesión ha expirado";
    }
}
