import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { faUsers, faHome, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth/auth.service';

export interface RightOption{
    "name": string,
    "icon": any,
    "link": string
}

export interface LeftOption{
    name: string,
    icon: any,
    link: string,
    style?: string
  }

@Injectable({
    providedIn: 'root'
})
export class SubmenuService {
    currentRoot: Subject<string> = new Subject<string>();
    rightOptions: Subject<RightOption[]> = new Subject<RightOption[]>();
    panelStatus: string = "waiting";

    leftOptions: LeftOption[] = [];

    mainMenu: {
        [key: string]: {
            [key: string]: any
        }
    };

    subMenus: {
        [key: string]: {
            [key: string]: any
        }
    }

    subMenuParents: {[key:string]: string}

    constructor( private authService: AuthService){
        this.mainMenu = {
            "User": {
              "name": "Usuarios",
              "icon": faUsers,
              "link": "usuarios",
              "subs": []
            },
            "Property": {
              "name": "Propiedades",
              "icon": faHome,
              "link": "propiedades"
            },
            "Finance": {
              "name": "Finanzas",
              "icon": faDollarSign,
              "link": "finanzas"
            },
            "Reports": {
              "name": "Reportes",
              "icon": faChartBar,
              "link": "reportes",
              "subs": []
            }
          }

        this.subMenus = {
            "finanzas": [
                {
                    name: "Gestión de tipos de cobro",
                    link: "finanzas/tipos-cobros/"
                },
                {
                    name: "Transacciones",
                    link: "finanzas/transacciones/"
                },
                {
                    name: "Cargos Recurrentes",
                    link: "finanzas/recurrentes/"
                },
                {
                    name: "Configuración",
                    link: "finanzas/configuracion/"
                }
            ],
            // "usuarios": [
            //     {
            //         name: "Gestión usuarios",
            //         link: "users/tipos-cobros/"
            //     },
            //     {
            //         name: "Gestión usuarios nuevos",
            //         link: "users/tipos-cobros/"
            //     }
            // ]
        };

        this.subMenuParents = {
            "propiedades": "Property",
            "finanzas": "Finance",
            "usuarios": "User",
        }
    }

    panelSwitch = function(action: string){}

    setOptions( rightOptions: RightOption[] ){
        this.rightOptions.next(rightOptions);
    }

    updateRoot( route: ActivatedRouteSnapshot ){
        let url = this.getResolvedUrl(route);
        let paths = url.split("/").filter( path => { return path!==""} );
        if( paths.length>1 ){
            this.currentRoot.next( paths[1] );
            this.regenerateMenu( paths[1] );
        }
    }

    getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    generateMainMenu( sub?: string ){
      this.leftOptions = [];
        var modules = this.authService.currentUser?.modules;
        if( modules ){
          for( let mod in modules ){
            if( modules[mod] ){
              this.leftOptions.push( {
                name: this.mainMenu[mod].name,
                icon: this.mainMenu[mod].icon,
                link: this.mainMenu[mod].link
              });
              if( sub && mod==this.getSubParent(sub) && this.subMenus.hasOwnProperty(sub) ){
                for( let sub_indx in this.subMenus[sub] ){
                  console.log(sub_indx, this.subMenus[sub][sub_indx]);
                  this.leftOptions.push( {
                    name: this.subMenus[sub][sub_indx].name,
                    icon: null,
                    link: this.subMenus[sub][sub_indx].link,
                    style: "sub"
                  });
                }
              }
              console.log("mod", mod, modules[mod]);
            }
          }
        }
    }
    
    regenerateMenu( sub: string ){
        if( this.panelStatus=="ready" ){
            this.leftOptions = [];
            this.generateMainMenu( sub );
        } else {
            setTimeout( ()=>{
                this.regenerateMenu( sub );
            }, 10);
        }
    }

    getSubParent( sub: string ){
        return this.subMenuParents[sub];
    }
}