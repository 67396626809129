import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubmenuService } from '../submenu.service';

@Component({
    selector: 'app-finance',
    templateUrl: './finance.component.html',
    styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit, AfterViewInit {
    viewInit: boolean = false;

    constructor( private submenuService: SubmenuService, private route: ActivatedRoute ) { }

    ngOnInit(): void {
        console.log("finance on init");
        
        this.updateRootMenu();
        this.submenuService.rightOptions.next([]);
    }

    ngAfterViewInit(){
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }
}