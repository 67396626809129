import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FinanceService } from '../../finance.service';

@Component({
    selector: 'app-finance-billtype-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.css']
})
export class BilltypeEditComponent implements OnInit {

    size: string = "modal-md";
    title: string = "Editar tipo de cobro";
    faSpinner = faSpinner;

    @Output() close = new EventEmitter<void>();

    constructor( public financeService: FinanceService ) { }

    ngOnInit(): void {
        this.title = this.title + " " + this.financeService.editingBilltype.name;
        console.log( "editing billtype", this.financeService.editingBilltype );
    }

    onClose(){
        this.close.emit();
    }
}
