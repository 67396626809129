import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditUserComponent implements OnInit {

  @Input() userId: string = "";
  @Output() close = new EventEmitter<void>();

  size: string = "modal-md";
  title: string = "Editar Usuario";
  faSpinner = faSpinner;

  errorMessage: string = "";
  user: any = null;

  constructor( private usersService: UsersService) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(){
    this.usersService.getUser( this.userId ).subscribe(
      resData => {
        if( resData.auth ){
          if( resData.hasOwnProperty("user") ){
            this.user = resData.user;
          }
          console.log(resData);
        } else {
          this.onSessionExpired();
        }
      }, errorMessage => {
        this.errorMessage = errorMessage;
      }
    );
  }

  onSessionExpired(){
    this.errorMessage = "Session expired.";
  }

  onClose(){
    this.close.emit();
  }

}
