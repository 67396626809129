<div class="page-container">

    <ul class="nav itabs nav-tabs">
        <li [ngClass]="{active:tabs.settings}">
            <a (click)="onTabChange('settings')" data-toggle="tab" href='javascript:;'>Configuración</a>
        </li>
        <li [ngClass]="{active:tabs.statements}">
            <a (click)="onTabChange('statements')" data-toggle="tab" href='javascript:;'>Estados de cuenta</a>
        </li>
    </ul>

    <div class="tab-content">
        <div id="settings_tab" class="tab-pane fade" [ngClass]="{'in active': tabs.settings}">
            <h3><fa-icon [icon]="faCogs"></fa-icon> Configuración</h3>
            <hr>
            <div class="alert alert-success" *ngIf="successMessage">
                <h4 class='no-margin'><fa-icon [icon]="faCheck"></fa-icon> {{successMessage}}</h4>
            </div>
            <div *ngIf="!loaded">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
            </div>
            <div class="row form-group" *ngIf="loaded">
                <div class="col-md-6 form-group">
                    <label for="">Tipo de Cambio</label>
                    <div class='input-group'>
                        <span class="input-group-addon">
                            $
                        </span>
                        <input type="text" [(ngModel)]="tc" class="form-control" [OnlyNumber]="true" noCopyPaste [AllowNegative]="false">
                        <span class="input-group-addon">
                            pesos por dólar
                        </span>
                    </div>
                </div>
                <div class="col-md-12 text-right">
                    <hr>
                    <div class="alert alert-danger text-left" *ngIf="errorMessage">
                        {{errorMessage}}
                    </div>
                    <div *ngIf="syncing"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Guardando...</div>
                    <button *ngIf="!syncing" class="btn btn-primary" (click)="onSaveChanges()">Guardar Cambios</button>
                </div>
            </div>
        </div>

        <div id="statements" class="tab-pane fade" [ngClass]="{'in active': tabs.statements}">
            <h3><fa-icon [icon]="faEnvelope"></fa-icon> Envío de Estados de Cuenta</h3>
            <hr>
            <div class="alert alert-success" *ngIf="statementsSuccessMessage">
                <h4 class='no-margin'><fa-icon [icon]="faCheck"></fa-icon> {{statementsSuccessMessage}}</h4>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-group">Estado de cuenta HOA <fa-icon class="switchIcon" [icon]="statementsSend.hoa.statusIcon" [ngClass]="{active: statementsSend.hoa.status==1}" (click)="onSwitch('hoa')"></fa-icon></label>
                    <div class="input-group form-group">
                        <span class="input-group-addon">Enviar cada</span>
                        <input type="text" [OnlyNumber]="true" class='form-control' [(ngModel)]="statementsSend.hoa.each" [AllowNegative]="false" noCopyPaste [AllowDot]="false" [disabled]="statementsSend.hoa.status==0">
                        <span class="input-group-addon">
                            <select [(ngModel)]="statementsSend.hoa.frequency" [disabled]="statementsSend.hoa.status==0">
                                <option value=""></option>
                                <option value="days">Días</option>
                                <option value="weeks">Semanas</option>
                                <option value="months">Meses</option>
                                <option value="years">Años</option>
                            </select>
                        </span>
                    </div>
                    <div class="input-group form-group">
                        <span class="input-group-addon">Próximo envío</span>
                        <input class="form-control datepicker" placeholder="yyyy-mm-dd"
                             name="dp" [(ngModel)]="statementsSend.hoa.next_date" required ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()" [minDate]="minDate" [disabled]="statementsSend.hoa.status==0">
                        <span class="input-group-addon">
                            <a href="javascript:;" (click)="d.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-group">Estado de cuenta Billpay <fa-icon class="switchIcon" [icon]="statementsSend.billpay.statusIcon" [ngClass]="{active: statementsSend.billpay.status==1}" (click)="onSwitch('billpay')"></fa-icon></label>
                    <div class="input-group form-group">
                        <span class="input-group-addon">Enviar cada</span>
                        <input type="text" [OnlyNumber]="true" class='form-control' [(ngModel)]="statementsSend.billpay.each" [AllowNegative]="false" noCopyPaste [AllowDot]="false" [disabled]="statementsSend.billpay.status==0">
                        <span class="input-group-addon">
                            <select [(ngModel)]="statementsSend.billpay.frequency" [disabled]="statementsSend.billpay.status==0">
                                <option value=""></option>
                                <option value="days">Días</option>
                                <option value="weeks">Semanas</option>
                                <option value="months">Meses</option>
                                <option value="years">Años</option>
                            </select>
                        </span>
                    </div>
                    <div class="input-group form-group">
                        <span class="input-group-addon">Próximo envío</span>
                        <input class="form-control datepicker" placeholder="yyyy-mm-dd"
                             name="dp" [(ngModel)]="statementsSend.billpay.next_date" required ngbDatepicker #dbb="ngbDatepicker" autocomplete="off" readonly (click)="dbb.toggle()" [minDate]="minDate" [disabled]="statementsSend.billpay.status==0">
                        <span class="input-group-addon">
                            <a href="javascript:;" (click)="dbb.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                        </span>
                    </div>
                </div>
            </div>
            <hr>
            <div class="alert alert-danger" *ngIf="statementsErrorMessage">{{statementsErrorMessage}}</div>
            <div class="text-right">
                <button class="btn btn-primary" (click)="onSaveStatementsSettings()" *ngIf="!syncing">Guardar cambios</button>
                <span *ngIf="syncing">Guardando <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></span>
            </div>
        </div>
    </div>
</div>