import { Injectable, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

interface propertyData {
    name: string,
    owner: string,
    type: string
}

interface serverResponse {
    auth: boolean,
    error?: string,
    success?: string,
    meta?: any,
    properties?: any
    property?: any
}

@Injectable({
    providedIn: 'root'
})
export class PropertiesService {
    constructor(private http: HttpClient, private router: Router){}

    propertiesList: any[] = [];
    filters = {
        order_by: "name",
        order: "ASC",
        status: 1
    }

    editingProperty: any = null;
    hostViewContainerRef?: ViewContainerRef;

    getPropertyMeta(){
        return this.http.get<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getPropertyMeta"
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    addProperty( propertyData: propertyData ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addProperty",
            propertyData
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    editProperty( propertyData: propertyData, filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=editProperty",
            {
                propertyData: propertyData,
                filters: filters
            }
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    deleteProperty( property_id: string, filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=deleteProperty",
            { property_id: property_id, filters: filters }
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    getProperties( filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getProperties",
            {filters: filters}
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    getProperty( property_id: string ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getProperty", 
            { property_id: property_id }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("property") ){
                    this.editingProperty = resData.property
                }
            })
        );
    }

    handleLoginError( errorRes: HttpErrorResponse ){
        console.log("errorRes", errorRes);
        let errorMessage = "Error desconocido!";
        if( errorRes.status==405 ){
            return throwError("El método no fue encontrado. Contacte al desarrollador del sistema si este problema persiste.");
        }
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        return throwError(errorMessage);
    }
}