import { NgModule } from '@angular/core';
import { EditUserComponent } from './edit/edit.component';
import { AddUserComponent } from './add/add.component';
import { UsersComponent } from './users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';
//import { AlertComponent } from 'src/app/shared/alert/alert.component';

export const userRoutes: Routes = [
    { path: "usuarios", canActivate: [AuthGuard], component: UsersComponent },
    { path: "usuarios/agregar", canActivate: [AuthGuard], component: AddUserComponent }
];

@NgModule({
    declarations: [
        UsersComponent,
        AddUserComponent,
        EditUserComponent
    ],
    imports: [ 
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [],
    providers: [],
    entryComponents: [
        EditUserComponent
    ]
})
export class UsersModule {}