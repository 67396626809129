<app-alert *ngIf="alertService.showAlert"></app-alert>
<div class="page-container">
    <div class="alert alert-success" *ngIf="successMessage">
        <h4 class='no-margin'><fa-icon [icon]="faCheckCircle"></fa-icon> {{successMessage}}</h4>
        <!-- <br><a [routerLink]="['/panel/finanzas/tipos-cobros']">Ir a la lista de tipos de cobros</a> -->
    </div>
    <div class="alert alert-info">
        <b>Nota:</b> El alias es el nombre que se mostrará al dueño
    </div>
    <div class='row'>
        <div class="col-md-6 form-group">
            <label for="name">Nombre</label>
            <input type="text" name="name" [(ngModel)]="newBillTypeName" class='form-control'>
        </div>
        <div class="col-md-6 form-group">
            <label for="alias">Alias</label>
            <input type="text" name="alias" [(ngModel)]="newBillTypeAlias" class='form-control'>
        </div>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage">
        {{errorMessage}}
    </div>
    <div class="text-right form-group">
        <div *ngIf="syncing">
            <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Guardando...
        </div>
        <hr>
        <button *ngIf="!syncing && mode=='add'" class="btn btn-primary" (click)="onAddBillType()">Agregar Tipo de Cobro</button>
        <button *ngIf="!syncing && mode=='edit' && financeService.editingBilltype.by_system=='0'" class="btn btn-danger margin-right-sm" (click)="onDeleteBillType()">Eliminar</button>
        <button *ngIf="!syncing && mode=='edit'" class="btn btn-primary" (click)="onEditBillType()">Guardar Cambios</button>
    </div>
</div>