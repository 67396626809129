import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertComponent } from './alert/alert.component';
import { BtnAutoFocus } from './autofocus.directive';
import { NoCopyPasteDirective } from './no-copy-paste.directive';
import { OnlyNumber } from './only-numbers.directive';
import { PlaceholderDirective } from './placeholder.directive';

@NgModule({
    declarations: [
        AlertComponent,
        PlaceholderDirective,
        OnlyNumber,
        NoCopyPasteDirective,
        BtnAutoFocus
    ],
    imports: [
        FontAwesomeModule,
        CommonModule
    ],
    exports: [
        AlertComponent,
        PlaceholderDirective,
        OnlyNumber,
        NoCopyPasteDirective,
        CommonModule,
        FontAwesomeModule,
        BtnAutoFocus
    ],
    providers: [],
})
export class SharedModule {}