<app-alert *ngIf="alertService.showAlert"></app-alert>
<div class="page-container">
    <div *ngIf="successMessage">
        <div class="alert alert-success">
            <h4 class='no-margin'><fa-icon [icon]="faCheck"></fa-icon> {{successMessage}}</h4>
        </div>
    </div>
    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
    <div >
        <form (ngSubmit)="onSubmit(f)" #f="ngForm" >
            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="firstName">Nombre</label>
                    <input type="text" class='form-control' name='firstName' ngModel required autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="lastName">Apellido</label>
                    <input type="text" class='form-control' name='lastName' ngModel required autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="email">Correo Electrónico</label>
                    <input type="text" class='form-control' name='email' ngModel email required autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="additionalEmails">Correos Electrónicos Adicionales (Opcional | Separados por coma)</label>
                    <input type="text" class='form-control' name='additionalEmails' ngModel autocomplete="off" placeholder="correo@dominio.com, correo2@dominio.com">
                </div>
                <div class="col-md-6 form-group">
                    <label for="phone">Teléfono</label>
                    <input type="text" class='form-control' name='phone' ngModel autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="additionalPhones">Teléfonos Adicionales (Opcional)</label>
                    <input type="text" class='form-control' name='additionalPhones' ngModel autocomplete="off" placeholder="John Wick 602-102-122">
                </div>
                <div class="col-md-6 form-group">
                    <label for="pwd">Contraseña</label>
                    <input type="password" *ngIf="mode=='add'" class='form-control' name='pwd' ngModel required autocomplete="off">
                    <input type="password" *ngIf="mode=='edit'" class='form-control' name='pwd' ngModel autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="pwdc">Repita la Contraseña</label>
                    <input type="password" *ngIf="mode=='add'" class='form-control' name='pwdc' ngModel required autocomplete="off">
                    <input type="password" *ngIf="mode=='edit'" class='form-control' name='pwdc' ngModel autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="userType">Dirección</label>
                    <input type="text" class='form-control' name='address' ngModel autocomplete="off">
                </div>
                <div class="col-md-6 form-group">
                    <label for="userType">Tipo de Usuario</label>
                    <select name="userType" id="userType" class="form-control" [(ngModel)]="userType" required autocomplete="off">
                        <option value=""></option>
                        <option value="owner">Dueño</option>
                        <option value="admin">Administrativo</option>
                    </select>
                </div>
                <div class="col-md-6 form-group" *ngIf="mode=='edit'">
                    <label for="userStatus">Estado</label>
                    <select name="userStatus" id="userStatus" ngModel class="form-control" autocomplete="off">
                        <option value="1">Activo</option>
                        <option value="0">Inactivo</option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="mode=='edit' && !ready">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
            </div>
            <div class="row" *ngIf="userType=='owner'">
                <div class="col-xs-12 form-group">
                    <hr>
                    <h4><fa-icon [icon]="faEnvelope"></fa-icon> Notificaciones</h4>
                </div>
                <div class="col-xs-12" *ngIf="!userNotifications">
                    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
                </div>
                <div class="col-xs-12" *ngFor="let notification of userNotifications.owner">
                    <input type="checkbox" class='notification' [name]='notification.key'> {{notification.name}} <br>
                </div>
            </div>
            <div class="row" *ngIf="userType=='admin'">
                <div class="col-xs-12 form-group">
                    <hr>
                    <h4><fa-icon [icon]="fasLock"></fa-icon> Acceso</h4>
                </div>
                <div class="col-xs-12 col-sm-4 form-group" *ngIf="!userAccess">
                    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
                </div>
                <div class="col-xs-12 col-sm-3 form-group" *ngFor="let access of userAccess">
                    <label><input type="checkbox" class='access' [name]='access.all' (change)="onAllAccessCheckbox(access.key, $event)"> {{access.name}}</label><br>
                    <div class="" *ngFor="let acc of access.list">
                        <input type="checkbox" class='access {{access.key}}' [checked]="allAccess[access.key]" [name]='acc.key' (change)="onAccessChecked(access.key, $event)"> {{acc.name}} <br>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <hr>
                <div class="alert alert-danger text-left" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="text-right text-white" *ngIf="saving">
                    <div *ngIf="mode=='add'">Agregando usuario, espere por favor <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></div>
                    <div *ngIf="mode=='edit' && !deleteConfirm">Guardando cambios, espere por favor <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></div>
                    <div *ngIf="mode=='edit' && deleteConfirm">Eliminando usuario, espere por favor <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></div>
                </div>
                <div *ngIf="!saving && mode=='add' && this.ready">
                    <button [disabled]="!f.valid" class="btn btn-primary ">Agregar</button>
                    <!-- <button [disabled]="!form.valid" class="btn btn-primary ">Agregar</button> -->
                </div>
                <div *ngIf="!saving && mode=='edit' && this.ready && !userDeleted">
                    <button type="button" class="btn btn-danger" (click)="onDelete()">Eliminar</button> &nbsp;
                    <button type="button" [disabled]="!f.valid" class="btn btn-primary" (click)="onSaveChanges()">Guardar Cambios</button>
                </div>
                <!-- <div class='alert alert-danger' *ngIf="mode=='edit' && deleteConfirm && !saving">
                    <b>Confirma para eliminar el usuario</b><br>
                    <small>*Esta acción no puede revertirse</small><br><br>
                    <button type="button" class='btn btn-default' (click)="deleteConfirm=false;">Cancelar</button>&nbsp; 
                    <button type="button" class="btn btn-danger" (click)="onDelete()">Eliminar</button>
                </div> -->
            </div>
        </form>
    </div>
</div>