import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FinanceService } from '../../finance.service';

@Component({
    selector: 'app-finance-edit-recurrent',
    templateUrl: './edit.recurrent.component.html',
    styleUrls: ['./edit.recurrent.component.css']
})
export class FinanceEditRecurrentComponent implements OnInit {

    @Input() recurrentID: string = "";
    @Output() close = new EventEmitter<void>();

    size: string = "modal-md";
    title: string = "Editar Transacción Recurrente";
    faSpinner = faSpinner;

    errorMessage: string = "";
    recurrent: any = null;

    constructor(public financeService: FinanceService) { }

    ngOnInit(): void {
        if( this.recurrentID ){
            for( let recurrent of this.financeService.recurrents ){
                if( recurrent.id == this.recurrentID ){
                    this.recurrent = recurrent;
                    this.financeService.editingRecurrent = recurrent;
                }
            }
        }
    }

    onClose(){
        this.close.emit();
    }
}
