import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SubmenuService } from '../../submenu.service';
import { faLock, faSpinner, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { UsersService } from '../users.service';
import { AlertButton, AlertService } from 'src/app/shared/alert/alert.service';

interface MyAccess {
    [key: string]: boolean;
}

@Component({
    selector: 'app-add-user',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.css']
})
export class AddUserComponent implements OnInit {

    @Input() mode: string = "add";
    @ViewChild('f', { static: true }) form?: NgForm;

    userType: string = "";
    fasLock = faLock;
    faEnvelope = faEnvelope;
    faSpinner = faSpinner;
    faCheck = faCheckCircle;
    allAccess: MyAccess = {
        users: false,
        properties: false
    };

    successMessage: string = "";
    errorMessage: string = "";
    saving: boolean = false;
    ready: boolean = false;

    userAccess: any = null;
    userNotifications: any = null;

    successTimer: any = null;
    deleteConfirm: boolean = false;
    userDeleted: boolean = false;

    showAlert: boolean = false;

    constructor(private submenuService: SubmenuService, private route: ActivatedRoute, private userService: UsersService, public alertService: AlertService) {
    }

    ngOnInit(): void {
        console.log(this.form);
        this.submenuService.updateRoot(this.route.snapshot);

        //Get User Meta
        this.getUserMeta();
    }

    loadUser(force: boolean = false) {
        console.log("trying loaduser", this.userService.editingUser);
        console.log(this.form);
        setTimeout(() => {
            if (this.form?.value.firstName == "" || force) {
                //console.log("forcing:", force);
                if (this.userService.editingUser) {
                    this.form?.reset({
                        email: this.userService.editingUser.email,
                        firstName: this.userService.editingUser.fname,
                        lastName: this.userService.editingUser.lname,
                        phone: this.userService.editingUser.phone,
                        address: this.userService.editingUser.address,
                        pwd: "",
                        pwdc: "",
                        userStatus: this.userService.editingUser.status,
                        additionalEmails: this.userService.editingUser.additional_emails,
                        additionalPhones: this.userService.editingUser.additional_phones
                    });
                    (<HTMLInputElement>document.getElementById("userType")).value = this.userService.editingUser.type;
                    this.userType = this.userService.editingUser.type;

                    (<HTMLInputElement>document.getElementById("userStatus")).value = this.userService.editingUser.status;
                }
                this.loadUser();
            } else {
                //console.log(this.userService.editingUser);
                //Load access / notifications
                if (this.userService.editingUser.type == "owner") {
                    for (let i = 0; i < this.userService.editingUser.notifications.length; i++) {
                        document.getElementsByName(this.userService.editingUser.notifications[i]).forEach((el, indx) => {
                            (<HTMLInputElement>el).checked = true;
                        });
                    }
                } else if (this.userService.editingUser.type == "admin") {
                    for (let i = 0; i < this.userService.editingUser.access.length; i++) {
                        document.getElementsByName(this.userService.editingUser.access[i]).forEach((el, indx) => {
                            (<HTMLInputElement>el).checked = true;
                        });
                    }
                }
                this.ready = true;
            }
        }, 100);
    }


    getUserMeta() {
        this.userService.getUserMeta().subscribe(
            resData => {
                if (resData.auth) {
                    if (resData.meta.hasOwnProperty("notifications")) {
                        this.userNotifications = resData.meta.notifications;
                    }
                    if (resData.meta.hasOwnProperty("access")) {
                        this.userAccess = resData.meta.access;
                    }

                    //Load data if edit
                    if (this.mode == "edit") {
                        this.loadUser();
                    } else if (this.mode == "add") {
                        this.ready = true;
                    }
                    //console.log(resData);
                } else {
                    this.onSessionExpired();
                }
            }, errorMessage => {
                this.errorMessage = errorMessage;
            }
        );
    }

    onSaveChanges() {
        this.errorMessage = "";
        this.successMessage = "";
        clearTimeout(this.successTimer);

        if (!this.form?.valid) {
            return;
        }

        var request = this.getFormData(this.form!);

        if (!request.email || !request.firstName || !request.lastName || !request.userType) {
            this.errorMessage = "Solo los campos teléfono y dirección son opcionales";
            return;
        }
        if (request.pwd != "" || request.pwdc != "") {
            if (request.pwd != request.pwdc) {
                this.errorMessage = "Las contraseñas no coinciden, por favor ingréselas nuevamente";
                this.form.form.patchValue({ pwd: "", pwdc: "" });
                return;
            }

            if (request.pwd.length < 8) {
                this.errorMessage = "La contraseña debe contener al menos 8 caracteres";
                this.form.form.patchValue({ pwd: "", pwdc: "" });
                return;
            }
        }
        if (request.userType == "admin" && !this.userAccess) {
            this.errorMessage = "Error al cargar los accesos. Actualice la página e inténtelo nuevamente. Si el error persiste por favor comuníquese con el desarrolador del sistema.";
            return;
        }
        if (request.userType == "owner" && !this.userNotifications) {
            this.errorMessage = "Error al cargar las notificaciones. Actualice la página e inténtelo nuevamente. Si el error persiste por favor comuníquese con el desarrolador del sistema.";
            return;
        }

        //save changes
        this.saving = true;
        request["user_id"] = this.userService.editingUser["id"];
        //Send data to the server
        this.userService.editUser(request, this.userService.filters).subscribe(
            resData => {
                if (resData.auth) {
                    if (resData.hasOwnProperty("users")) {
                        this.userService.usersList = resData.users;
                    }
                    this.onSuccess(resData.success ?? "");
                } else {
                    this.onSessionExpired();
                }
                this.saving = false;
            }, errorMessage => {
                this.errorMessage = errorMessage;
                this.saving = false;
            }
        );
    }

    // onSubmit(  ){
    onSubmit(form: NgForm) {
        //console.log( form.form.value );

        this.errorMessage = "";
        this.successMessage = "";

        if (!form.valid) {
            return;
        }

        var request = this.getFormData(form);

        if (!request.email || !request.firstName || !request.lastName || !request.userType || !request.pwd || !request.pwdc) {
            this.errorMessage = "Solo los campos teléfono y dirección son opcionales";
            return;
        }
        if (request.pwd != request.pwdc) {
            this.errorMessage = "Las contraseñas no coinciden, por favor ingréselas nuevamente";
            form.form.patchValue({ pwd: "", pwdc: "" });
            return;
        }
        if (request.pwd.length < 8) {
            this.errorMessage = "La contraseña debe contener al menos 8 caracteres";
            form.form.patchValue({ pwd: "", pwdc: "" });
            return;
        }
        if (request.userType == "admin" && !this.userAccess) {
            this.errorMessage = "Error al cargar los accesos. Actualice la página e inténtelo nuevamente. Si el error persiste por favor comuníquese con el desarrolador del sistema.";
            return;
        }
        if (request.userType == "owner" && !this.userNotifications) {
            this.errorMessage = "Error al cargar las notificaciones. Actualice la página e inténtelo nuevamente. Si el error persiste por favor comuníquese con el desarrolador del sistema.";
            return;
        }

        //Send data to the server
        this.userService.addUser(request).subscribe(
            resData => {
                if (resData.auth) {
                    form.reset();
                    this.successMessage = resData.success ?? "";
                } else {
                    this.onSessionExpired();
                }
            }, errorMessage => {
                this.errorMessage = errorMessage;
            }
        );
    }

    onSuccess(message: string) {
        this.successMessage = message;
        document.getElementById("modal_wrapper")!.scrollTop = 0;
        // this.successTimer = setTimeout( () => {
        //   this.successMessage = "";
        // }, 3000 );
    }

    onDelete() {
        this.errorMessage = "";
        //Show alert to confirm
        var body = "<div class='alert alert-danger'>¿Estás seguro de eliminar el usuario " + this.userService.editingUser["fname"] + "?<br><small>*Esta acción no puede revertirse</small></div>";
        const buttons: AlertButton[] = [
            {
                title: "Cancelar",
                btn_class: "btn-default",
                onClick: () => {
                    this.alertService.closeAlert();
                }
            },
            {
                title: "Eliminar",
                btn_class: "btn-danger",
                onClick: () => {
                    this.onDeleteConfirmed()
                }
            }
        ]
        this.alertService.setAlert("Confirmación", body, "modal-md", buttons);
    }

    onDeleteConfirmed() {
        this.saving = true;
        this.userService.deleteUser(this.userService.editingUser["id"], this.userService.filters).subscribe(
            resData => {
                if (resData.auth) {
                    if (resData.hasOwnProperty("users")) {
                        this.userService.usersList = resData.users;
                    }
                    this.userDeleted = true;
                    this.alertService.setSuccessAlert("Éxito", "Usuario eliminado correctamente", () => {
                        this.userService.hostViewContainerRef?.clear();
                    });
                    // this.alertService.showAlert = false;
                    // this.onSuccess( resData.success ?? "" );
                } else {
                    this.onSessionExpired();
                }
                this.deleteConfirm = false;
                this.saving = false;
            }, errorMessage => {
                this.alertService.closeAlert();
                this.saving = false;
                this.deleteConfirm = false;
                this.errorMessage = errorMessage;
            }
        );
    }

    getFormData(form: NgForm) {
        form.form.value.email = form.form.value.email.trim();
        form.form.value.firstName = form.form.value.firstName.trim();
        form.form.value.lastName = form.form.value.lastName.trim();
        form.form.value.userType = form.form.value.userType.trim();
        form.form.value.pwd = form.form.value.pwd.trim();
        form.form.value.pwdc = form.form.value.pwdc.trim();
        if (form.form.value.additionalEmails)
            form.form.value.additionalEmails = form.form.value.additionalEmails.trim();
        if (form.form.value.additionalPhones)
            form.form.value.additionalPhones = form.form.value.additionalPhones.trim();

        var request = form.form.value;
        if (form.form.value.userType == "owner") {
            request = this.appendToRequest(request, "notification");
        } else if (form.form.value.userType == "admin") {
            request = this.appendToRequest(request, "access");
        }

        return request;
    }

    appendNotifications(request: MyAccess) {
        var nots = document.getElementsByClassName("notification");
        for (var i = 0; i < nots.length; i++) {
            let name = (<HTMLInputElement>nots[i]).name;
            request[name] = (<HTMLInputElement>nots[i]).checked;
        }
        return request;
    }

    appendToRequest(request: MyAccess, key: string) {
        var elements = document.getElementsByClassName(key);
        for (var i = 0; i < elements.length; i++) {
            let name = (<HTMLInputElement>elements[i]).name;
            request[name] = (<HTMLInputElement>elements[i]).checked;
        }
        return request;
    }

    onAllAccessCheckbox(clase: string, event: Event) {
        //This only change the individual checkboxes visually
        // if( this.allAccess.hasOwnProperty(clase) ){
        //   this.allAccess[clase] = (<HTMLInputElement>event.target).checked;
        // }
        var value = (<HTMLInputElement>event.target).checked;
        var checkboxes = document.getElementsByClassName(clase);
        for (var i = 0; i < checkboxes.length; i++) {
            (<HTMLInputElement>checkboxes[i]).checked = value;
        }
    }

    onAccessChecked(clase: string, event: Event) {
        if (!(<HTMLInputElement>event.target).checked) {
            for (var i in this.userAccess) {
                if (this.userAccess[i].key == clase) {
                    document.getElementsByName(this.userAccess[i].all).forEach((el, indx) => {
                        (<HTMLInputElement>el).checked = false;
                    });
                }
            }
        }
    }

    onSessionExpired() {
        this.errorMessage = "Session expired.";
    }

}
