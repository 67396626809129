import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { AuthService } from './auth/auth.service';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { PanelComponent } from './panel/panel.component';
import { SubmenuService } from './panel/submenu.service';
import { NavbarComponent } from './panel/navbar/navbar.component';
import { FormsModule } from '@angular/forms';
import { UsersService } from './panel/users/users.service';
import { UsersModule } from './panel/users/users.module';
import { SharedModule } from './shared/shared.module';
import { PropertiesService } from './panel/properties/properties.service';
import { AlertService } from './shared/alert/alert.service';
import { FinanceModule } from './panel/finance/finance.module';
import { FinanceService } from './panel/finance/finance.service';
import { PropertiesModule } from './panel/properties/properties.module';
import { ReportsModule } from './panel/reports/reports.module';

@NgModule({
  declarations: [
    AppComponent,
    ComingSoonComponent,
    PanelComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AuthModule,
    UsersModule,
    PropertiesModule,
    FinanceModule,
    ReportsModule,
    SharedModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    AlertService,
    UsersService,
    PropertiesService,
    FinanceService,
    SubmenuService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
