import { Injectable, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

interface userData {
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    pwd: string,
    pwdc: string,
    userType: string,
    additional_emails: string,
    additional_phones: string
}

interface serverResponse {
    auth: boolean,
    error?: string,
    success?: string,
    meta?: any,
    users?: any
    user?: any
}

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient, private router: Router){}

    usersList: any = null;
    filters = {
        order_by: "type",
        order: "ASC",
        status: 1
    }

    editingUser: any = null;
    hostViewContainerRef?: ViewContainerRef;

    getUserMeta(){
        return this.http.get<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getUserMeta"
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    addUser( userData: userData ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addUser",
            userData
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    editUser( userData: userData, filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=editUser",
            {
                userData: userData,
                filters: filters
            }
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    deleteUser( user_id: string, filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=deleteUser",
            { user_id: user_id, filters: filters }
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    getUsers( filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getUsers",
            {filters: filters}
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    getUser( user_id: string ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getUser", 
            { user_id: user_id }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("user") ){
                    this.editingUser = resData.user
                }
            })
        );
    }

    handleLoginError( errorRes: HttpErrorResponse ){
        console.log("errorRes", errorRes);
        let errorMessage = "Error desconocido!";
        if( errorRes.status==405 ){
            return throwError("El método no fue encontrado. Contacte al desarrollador del sistema si este problema persiste.");
        }
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        return throwError(errorMessage);
    }
}