<ng-template appPlaceholder></ng-template>
<div class="page-container">
    <!-- <div class="text-right form-group">
        <button class="btn btn-primary"><fa-icon [icon]="faPlusCircle"></fa-icon> Agregar Nuevo Tipo de Cobro</button>
    </div> -->
    <div class="row form-group">
        <div class="col-md-12">
            <div class="input-group">
                <span class='input-group-addon'>
                    <fa-icon [icon]="faSearch" ></fa-icon>
                </span>
                <input type="text" class='form-control' (keyup)="onSearch($event)">
            </div>
        </div>
        <!-- <div class="col-md-4">
            <select class='form-control' (change)="onFilterStatusChange($event)" [ngModel]="financeService.filters.status">
                <option value="10">Mostrar todos</option>
                <option value="0">Mostrar solo inactivos</option>
                <option value="1">Mostrar solo activos</option>
            </select>
        </div> -->
    </div>
    <table class="table table-bordered table-striped table-hover">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Alias</th>
            </tr>
        </thead>
        <tbody id="list-results">
            <tr *ngIf="errorMessage">
                <td colspan="2">
                    <div class="alert alert-danger">{{errorMessage}}</div>
                </td>
            </tr>
            <tr *ngIf="syncing">
                <td colspan="2"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...</td>
            </tr>
            <tr *ngIf="!syncing && financeService.billtypesList.length==0">
                <td colspan="2">No se encontraron tipos de cobro. <a [routerLink]="['/panel/finanzas/tipos-cobros/agregar/']">Agregar tipo de cobro</a></td>
            </tr>
            <tr *ngFor="let billtype of financeService.billtypesList">
                <td><a href="javascript:;" (click)="editBillType(billtype.id)">{{billtype.name}}</a></td>
                <td>{{billtype.alias}}</td>
            </tr>
        </tbody>
    </table>
</div>