import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCheckCircle, faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SubmenuService } from 'src/app/panel/submenu.service';
import { AlertButton, AlertService } from 'src/app/shared/alert/alert.service';
import { FinanceService } from '../../finance.service';

@Component({
    selector: 'app-finance-biltype-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.css']
})
export class BilltypeAddComponent implements OnInit {

    viewInit: boolean = false;
    newBillTypeName: string = "";
    newBillTypeAlias: string = "";
    errorMessage: string = "";
    successMessage: string= "";
    syncing: boolean = false;
    @Input() mode: string = "add";

    faCheckCircle = faCheckCircle;
    faSpinner = faSpinner;

    constructor( private submenuService: SubmenuService, private route: ActivatedRoute, public financeService: FinanceService, public alertService: AlertService) { }

    ngOnInit(): void {
        console.log("finance on init", this.mode);

        if( this.mode=="add" ){
            this.updateRootMenu();
            this.updateNavBar();
        } else {
            this.newBillTypeName = this.financeService.editingBilltype.name;
            this.newBillTypeAlias = this.financeService.editingBilltype.alias;
        }
    }

    ngAfterViewInit(){
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([
            {
              name: "Agregar Tipo de Cobro",
              icon: faPlusCircle,
              link: "finanzas/tipos-cobros/agregar"
            },
            {
              name: "Lista de Tipos de Cobros",
              icon: faListAlt,
              link: "finanzas/tipos-cobros"
            }
          ]
        );
    }

    onAddBillType(){
        this.errorMessage = "";
        this.successMessage = "";
        if( this.newBillTypeName.trim()!="" || this.newBillTypeAlias.trim()!="" ){
            this.syncing = true;
            this.financeService.addBillType( this.newBillTypeName.trim(), this.newBillTypeAlias.trim() ).subscribe(
                resData => {
                    this.syncing = false;
                    if( resData.auth ){
                        if( resData.success ){
                            this.successMessage = "Tipo de cobro agregado correctamente.";
                            this.newBillTypeName = "";
                            this.newBillTypeAlias = "";
                        } else {
                            this.errorMessage = resData.error ?? "";
                        }
                    } else {
                        this.onSessionExpired();
                    }
                },
                errorMessage => {
                    this.syncing = false;
                    this.errorMessage = errorMessage;
                }
            );
        } else {
            this.errorMessage = (this.mode=="add") ? "Debes introducir el nombre y alias del nuevo tipo de cobro" : "Introduzca el nombre y alias del tipo de cobro";
        }
    }

    onEditBillType(){
        this.errorMessage = "";
        this.successMessage = "";
        if( this.newBillTypeName.trim()!="" || this.newBillTypeAlias.trim()!="" ){
            this.syncing = true;
            this.financeService.editBillType( this.newBillTypeName.trim(), this.newBillTypeAlias.trim() ).subscribe(
                resData => {
                    this.syncing = false;
                    if( resData.auth ){
                        if( resData.success ){
                            this.successMessage = "Cambios guardados correctamente.";
                            if( resData.hasOwnProperty("billtypes") ){
                                this.financeService.billtypesList = resData.billtypes;
                            }
                        } else {
                            this.errorMessage = resData.error ?? "";
                        }
                    } else {
                        this.onSessionExpired();
                    }
                },
                errorMessage => {
                    this.syncing = false;
                    this.errorMessage = errorMessage;
                }
            );
        } else {
            this.errorMessage = (this.mode=="add") ? "Debes introducir el nombre y alias del nuevo tipo de cobro" : "Introduzca el nombre y alias del tipo de cobro";
        }
    }

    onDeleteBillType(){
        this.errorMessage = "";
        //Show alert to confirm
        var body = "<div class='alert alert-danger'>¿Estás seguro de eliminar el tipo de cobro <b>"+this.financeService.editingBilltype["name"]+"</b>?<br><small>*Esta acción no puede revertirse</small></div>";
        const buttons: AlertButton[] = [
        {
            title: "Cancelar",
            btn_class: "btn-default",
            onClick: () => {
                this.alertService.closeAlert();
            }
        },
        {
            title: "Eliminar",
            btn_class: "btn-danger",
            onClick: () => {
                this.onDeleteConfirmed()
            }
        }
        ]
        this.alertService.setAlert( "Confirmación", body, "modal-md", buttons );
    }

    onDeleteConfirmed(){
        this.syncing = true;
        this.alertService.syncing = true;
        this.financeService.deleteBillType().subscribe(
            resData => {
                if( resData.auth ){
                    if( resData.hasOwnProperty("billtypes") ){
                        this.financeService.billtypesList = resData.billtypes;
                    }
                    //this.propertyDeleted = true;
                    this.alertService.setSuccessAlert("Éxito", "Tipo de cobro eliminado correctamente", () => {
                        this.financeService.hostViewContainerRef?.clear();
                    });
                } else {
                    this.alertService.closeAlert();
                    this.onSessionExpired();
                }
                //this.deleteConfirm = false;
                this.alertService.syncing = false;
                this.syncing = false;
            }, errorMessage => {
                this.alertService.closeAlert();
                this.alertService.syncing = false;
                this.syncing = false;
                //this.deleteConfirm = false;
                this.errorMessage = errorMessage;
            }
        );
    }

    onSessionExpired(){
        this.errorMessage = "Session expired.";
    }
}
