<nav class="navbar" *ngIf="rightOptions.length>0">
    <ul class="nav navbar-nav">
        <li 
        *ngFor="let option of rightOptions; index as i"
        >
            <a [routerLink]="option.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <fa-icon [icon]="option.icon"></fa-icon> {{option.name}}
            </a>
        </li>
    </ul>
    <!-- <p class="navbar-text">Some text</p> -->
</nav>