import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FinanceComponent } from './finance.component';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { BillTypesComponent } from './billtypes/billtypes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BilltypeAddComponent } from './billtypes/add/add.component';
import { BilltypeEditComponent } from './billtypes/edit/edit.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { TransactionAddComponent } from './transactions/add/add.component';
import { TransactionAddChargeComponent } from './transactions/add/charge/charge.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransactionAddDepositComponent } from './transactions/add/deposit/deposit.component';
import { FinanceSettingsComponent } from './settings/settings.component';
import { TransactionEditChargeComponent } from './transactions/edit/charge/charge.component';
import { TransactionsImportComponent } from './transactions/import/import.component';
import { TransactionsImportAddComponent } from './transactions/import/add/add.component';
import { FinanceRecurrentListComponent } from './recurrent/recurrent.component';
import { FinanceAddRecurrentComponent } from './recurrent/add.recurrent/add.recurrent.component';
import { FinanceEditRecurrentComponent } from './recurrent/edit.recurrent/edit.recurrent.component';
import { MultipleChargesComponent } from './transactions/add/multiple-charges/multiple-charges.component';
import { MultipleSelectorComponent } from './transactions/add/multiple-selector/multiple-selector.component';
import { MultipleDepositsComponent } from './transactions/add/multiple-deposits/multiple-deposits.component';

export const financeRoutes: Routes = [
    { path: "finanzas", canActivate: [AuthGuard], component: FinanceComponent },
    { path: "finanzas/tipos-cobros", canActivate: [AuthGuard], component: BillTypesComponent},
    { path: "finanzas/tipos-cobros/agregar", canActivate: [AuthGuard], component: BilltypeAddComponent},
    { path: "finanzas/transacciones", canActivate: [AuthGuard], component: TransactionsComponent},
    { path: "finanzas/transacciones/agregar", canActivate: [AuthGuard], component: TransactionAddComponent},
    { path: "finanzas/transacciones/importar", canActivate: [AuthGuard], component: TransactionsImportComponent},
    { path: "finanzas/configuracion", canActivate: [AuthGuard], component: FinanceSettingsComponent},
    { path: "finanzas/recurrentes", canActivate: [AuthGuard], component: FinanceRecurrentListComponent},
    { path: "finanzas/recurrentes/agregar", canActivate: [AuthGuard], component: FinanceAddRecurrentComponent},
];

@NgModule({
    declarations: [
        FinanceComponent,
        BillTypesComponent,
        BilltypeAddComponent,
        BilltypeEditComponent,
        TransactionsComponent,
        TransactionAddComponent,
        TransactionAddChargeComponent,
        TransactionAddDepositComponent,
        FinanceSettingsComponent,
        TransactionEditChargeComponent,
        TransactionsImportComponent,
        TransactionsImportAddComponent,
        FinanceRecurrentListComponent,
        FinanceAddRecurrentComponent,
        FinanceEditRecurrentComponent,
        MultipleChargesComponent,
        MultipleSelectorComponent,
        MultipleDepositsComponent
    ],
    imports: [ 
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule ,
        CommonModule
    ],
    exports: [],
    providers: [],
    entryComponents: [
        BilltypeEditComponent
    ]
})
export class FinanceModule {}