import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { FinanceService } from '../../finance.service';
import { TransactionsImportAddComponent } from './add/add.component';

@Component({
  selector: 'app-transactions-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class TransactionsImportComponent implements OnInit {

  faExcel = faFileExcel;
  faSpinner = faSpinner;
  fileToUpload: File | null = null;
  uploading: boolean = false;

  @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 
  private closeSub?: Subscription;

  constructor( public financeService: FinanceService, private componentFactoryResolver: ComponentFactoryResolver, public alertService: AlertService ) { }

  ngOnInit(): void {
  }

  onUploadFile(){
    document.getElementById("import_file")?.click();
  }

  onFileSelection( event: Event ){
    //files: FileList | null
    let files: any = <HTMLInputElement>event.target;
    let ff = files.files;
    if( ff && ff.item(0) ){
      this.fileToUpload = ff.item(0);
      console.log(files, ff, ff.item(0));
      this.uploading = true;
      this.financeService.postFile(this.fileToUpload).subscribe(data => {
        //console.log("success", data);
        this.showTransactionsViews();
        this.uploading = false;
        this.fileToUpload = null;
      }, error => {
        console.log("error", error);
        this.uploading = false;
        this.fileToUpload = null;
      });
    } else {
      this.fileToUpload = null;
    }
  }

  showTransactionsViews(){
    //this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

    let editComponent = TransactionsImportAddComponent;

    const editCmpFactory =this.componentFactoryResolver.resolveComponentFactory(editComponent);

    const hostViewContainerRef = this.editHost?.viewContainerRef;
    hostViewContainerRef?.clear();

    const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

    //componentRef!.instance.transactionID = transaction_id;
    componentRef!.instance.size = "modal-xl";

    //this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

    this.financeService.hostViewContainerRef = hostViewContainerRef;

    this.closeSub = componentRef?.instance.close.subscribe( () => {
        this.closeSub?.unsubscribe();
        hostViewContainerRef?.clear();
    });
  }

}
