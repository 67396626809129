import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { environment } from 'src/environments/environment';

interface serverResponse {
    auth: boolean,
    error?: string,
    success?: string,
    records?: any,
    properties?: propertyFilter[]
}

interface propertyFilter {
  id: number,
  name: string
}

@Component({
    selector: 'app-reports-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

    size: string = "modal-md";
    title: string = "Historial";
    faSpinner = faSpinner;
    faCalendar = faCalendarAlt;

    @Output() close = new EventEmitter<void>();
    errorMessage: string = "";
    filters : {[key: string]: any } = {
        from: {},
        to: {},
        property_id: 0
    };
    applyingFilters: boolean = false;
    records: {[key: string]: any}[] = [];
    propertiesFilters: propertyFilter[] = [];

    constructor( private http: HttpClient, public alertService: AlertService ) { }

    ngOnInit(): void {
        var from: Date = new Date();
        from.setDate( from.getDate() - 30);
        var to: Date = new Date();
        this.filters.from = {
            year: parseInt(formatDate(from, 'yyyy', 'en')),
            month: parseInt(formatDate(from, 'MM', 'en')),
            day: parseInt(formatDate(from, 'dd', 'en'))
        };
        this.filters.to = {
            year: parseInt(formatDate(to, 'yyyy', 'en')),
            month: parseInt(formatDate(to, 'MM', 'en')),
            day: parseInt(formatDate(to, 'dd', 'en'))
        };

        this.applyFilters();
    }

    applyFilters(){
        this.applyingFilters = true;

        this.getRecords().subscribe(
            resData => {
                if( resData.auth ){
                    if( resData.hasOwnProperty("records") ){
                        this.records = resData.records;
                    }
                } else {
                    this.onSessionExpired();
                }
                this.applyingFilters = false;
            }, error => {
                this.applyingFilters = false;
                this.alertService.setErrorAlert( "Error", error );
            } 
        );
    }

    getRecords(){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getHistoryRecords",
            { filters: this.filters }
        ).pipe(
          tap( data => {
            if (data.properties) {
              this.propertiesFilters = data.properties;
            }
          }),
          catchError( this.handleLoginError )
        );
    }

    handleLoginError( errorRes: HttpErrorResponse ){
        console.log("errorRes", errorRes);
        let errorMessage = "Error desconocido!";
        if( errorRes.status==405 ){
            return throwError("El método no fue encontrado. Contacte al desarrollador del sistema si este problema persiste.");
        }
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        return throwError(errorMessage);
    }

    onSessionExpired(){
        this.alertService.setErrorAlert("Error", "Session expired." );
    }

    onClose(){
        this.close.emit();
    }

}
