import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FinanceService } from '../../../finance.service';

@Component({
    selector: 'app-finance-transactions-edit-charge',
    templateUrl: './charge.component.html',
    styleUrls: ['./charge.component.css']
})
export class TransactionEditChargeComponent implements OnInit {
    @Input() transactionID: string = "";
    @Output() close = new EventEmitter<void>();

    size: string = "modal-md";
    title: string = "Editar Transacción";
    faSpinner = faSpinner;

    errorMessage: string = "";
    transaction: any = null;

    constructor( public financeService: FinanceService) { }

    ngOnInit(): void {
        this.title = this.title + " ID:" + this.transactionID;
    }

    getTransactionType(){
        if( this.financeService.editingTransaction ){
            if( parseFloat(this.financeService.editingTransaction.charge)>0 ){
                return "charge";
            } else if( parseFloat(this.financeService.editingTransaction.deposit)>0 ){
                return "deposit";
            }
        }
        return "";
    }

    getProperty(){
        // this.financeService.getProperty( this.propertyId ).subscribe(
        //     resData => {
        //     if( resData.auth ){
        //         if( resData.hasOwnProperty("property") ){
        //         this.property = resData.property;
        //         }
        //         console.log(resData);
        //     } else {
        //         this.onSessionExpired();
        //     }
        //     }, errorMessage => {
        //     this.errorMessage = errorMessage;
        //     }
        // );
    }

    onSessionExpired(){
        this.errorMessage = "Session expired.";
    }

    onClose(){
        this.close.emit();
    }
}
