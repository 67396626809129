import { Injectable } from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import { exhaustMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor( private authService: AuthService){}

    intercept( req: HttpRequest<any>, next: HttpHandler ){
        //console.log("Interceptor running...");
        return this.authService.user.pipe(
            take(1),
            exhaustMap( user => {
                if( !user ){
                    //console.log("Interceptor", "USER NOT FOUND");
                    return next.handle(req);
                }
                //console.log("Interceptor", user.token);
                const modifiedReq = req.clone({
                    params: new HttpParams().set('auth', user?.token ?? "" ).set('uid', user?.id)
                });
                return next.handle(modifiedReq);
            })
        );
    }

}