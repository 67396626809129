import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubmenuService } from '../../submenu.service';
import { FinanceService } from '../finance.service';
import { faListAlt, faPlusCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FinanceEditRecurrentComponent } from './edit.recurrent/edit.recurrent.component';
import { Subscription } from 'rxjs';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';

@Component({
    selector: 'app-finance-recurrent-list',
    templateUrl: './recurrent.component.html',
    styleUrls: ['./recurrent.component.css']
})
export class FinanceRecurrentListComponent implements OnInit {

    viewInit: boolean = false;
    faSpinner = faSpinner;
    loaded: boolean = false;
    faEdit = faEdit;
    faDelete = faTimesCircle;

    @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 
    private closeSub?: Subscription;

    constructor(private submenuService: SubmenuService, private route: ActivatedRoute, public financeService: FinanceService, private componentFactoryResolver: ComponentFactoryResolver, public alertService: AlertService) { }

    ngOnInit(): void {
        this.updateRootMenu();
        this.updateNavBar();

        this.getRecurrentList();
    }

    ngAfterViewInit() {
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([
            {
              name: "Agregar",
              icon: faPlusCircle,
              link: "finanzas/recurrentes/agregar"
            },
            {
              name: "Lista de Cargos Recurrentes",
              icon: faListAlt,
              link: "finanzas/recurrentes"
            }
          ]
        );
    }

    getRecurrentList(){
        this.financeService.getRecurrents().subscribe(
            resData => {
                if( resData.auth ){
                    //success
                } else {
                    this.onSessionExpired();
                }
                this.loaded = true;
            }, error => {
                this.alertService.setErrorAlert("Error", error);
            }
        )
    }

    onEditRecurrent( recurrent_id: string ){
        //this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

        let editComponent = FinanceEditRecurrentComponent;

        const editCmpFactory =this.componentFactoryResolver.resolveComponentFactory(editComponent);

        const hostViewContainerRef = this.editHost?.viewContainerRef;
        hostViewContainerRef?.clear();

        const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

        componentRef!.instance.recurrentID = recurrent_id;
        componentRef!.instance.size = "modal-xl";

        //this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

        this.financeService.hostViewContainerRef = hostViewContainerRef;

        this.closeSub = componentRef?.instance.close.subscribe( () => {
            this.closeSub?.unsubscribe();
            hostViewContainerRef?.clear();
        });
    }

    onDeleteRecurrent( recurrent_id: string ){
        let name = "";
        for( let recurrent of this.financeService.recurrents ){
            if( recurrent.id==recurrent_id ){
                name = recurrent.name;
            }
        }
        this.alertService.setConfirmationAlert("Confirmación", "¿Está seguro de eliminar el cargo recurrente <b>"+name+"</b>?", "ELIMINAR", ()=>{
            this.deleteRecurrent(recurrent_id);
        }, true);
    }

    deleteRecurrent( recurrent_id: string ){
        this.financeService.deleteRecurrentCharge(recurrent_id).subscribe(
            resData => {
                if( resData.auth ){
                    this.alertService.setSuccessAlert("Éxito", "Cargo recurrente eliminado correctamente");
                } else {
                    this.onSessionExpired();
                }
            }, error => {
                this.alertService.setErrorAlert("Error", error);
            }
        )
    }

    onSessionExpired(){
        this.alertService.setErrorAlert("Session expired", "Your session is expired");
    }

}
