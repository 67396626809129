<app-alert *ngIf="alertService.showAlert"></app-alert>
<div [ngClass]="{'page-container': mode=='add'}">
    <div *ngIf="!loaded">
        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
    </div>

    <ul class="nav itabs nav-pills" *ngIf="loaded">
        <li *ngFor="let option of options" [class.active]="selectedOption==option" [class.disabled]="!isEnabled(option) || savingRecurrent">
            <a (click)="onStepClicked(option);" data-toggle="tab" href='javascript:;'>{{optionsName[option]}}</a>
        </li>
    </ul>
    <hr>

    <div class="tab-content margin-top-md" *ngIf="loaded">
        <div id="charge" class="tab-pane fade in active" *ngIf="selectedOption==options[0]">
            <form #f="ngForm" (ngSubmit)="saveGeneralData(f)">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label for="name">Nombre (Para manejo interno)</label>
                        <input type="text" name="name" id="name" required [(ngModel)]="generalData.name" class="form-control" autocomplete="off" placeholder="Condos HOA Fee">
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="description">Descripción (Concepto de cargo)</label>
                        <input type="text" name="description" id="description" required [(ngModel)]="generalData.description" class="form-control" autocomplete="off" placeholder="Quarterly HOA Fee">
                    </div>
                    <!-- <div class="col-md-6 form-group">
                        <label for="property">Propiedad</label>
                        <select name="property" id="property" required ngModel class="form-control" autocomplete="off">
                            <option value=""></option>
                            <option [value]="property.id" *ngFor="let property of financeService.meta.properties">{{property.name}}</option>
                        </select>
                    </div> -->
                    <div class="col-md-6 form-group">
                        <label for="billtype">Tipo de Cobro</label>
                        <select name="billtype" [ngModel]="generalData.billtype" required class="form-control imp-billtype" autocomplete="off">
                            <option value=""></option>
                            <option [value]="billtype.id" *ngFor="let billtype of financeService.meta.billtypes">{{billtype.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="account">Cuenta</label>
                        <select name="account" id="account" required [ngModel]="generalData.account" class="form-control" autocomplete="off">
                            <option value=""></option>
                            <option [value]="account.id" *ngFor="let account of financeService.meta.accounts">{{account.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="currency">Moneda</label>
                        <select name="currency" class="form-control" [(ngModel)]="generalData.currency" required>
                            <option value=""></option>
                            <option value="MXN">MXN</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label>Notificaciones</label>
                        <div>
                            <input type="checkbox" name="not_owners" [(ngModel)]="generalData.not_owners" > Enviar notificación de cargo a dueños
                        </div>
                        <div>
                            <input type="checkbox" name="not_owners_balance" [(ngModel)]="generalData.not_owners_balance"> No enviar notificación de cargo si la propiedad tiene balance positivo para cubrir el cargo
                        </div>
                    </div>
                </div>
                <hr>
                <div class="text-right">
                    <button type="submit" [disabled]="!f.valid" class="btn btn-primary">Continuar</button>
                </div>
            </form>
        </div>
        
        <div id="deposit" class="tab-pane fade in active" *ngIf="selectedOption==options[1]">
            <div class="alert alert-info">
                <div class="row">
                    <div class="col-md-6">
                        <select name="bedrooms" id="bedrooms" [(ngModel)]="propertiesFilters.bedrooms" class="form-control">
                            <option value="">Selecconar por número de habitaciones</option>
                            <option value="1">1 habitación</option>
                            <option value="2">2 habitaciones</option>
                            <option value="3">3 habitaciones</option>
                            <option value="4">4 habitaciones</option>
                            <option value="5">5 habitaciones</option>
                            <option value="6">6 habitaciones</option>
                            <option value="7">7 habitaciones</option>
                            <option value="8">8 habitaciones</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <select name="property_type" id="property_type" [(ngModel)]="propertiesFilters.property_type" class="form-control">
                            <option value="">Selecconar por tipo de propiedad</option>
                            <option [value]="type.id" *ngFor="let type of financeService.meta.property_types">{{type.name}}</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6 pull-left">
                        <button class="btn btn-info" (click)="updateFilters()">Seleccionar propiedades</button>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <span class='input-group-addon'>$</span>
                            <input type="text" class="form-control" [OnlyNumber]="true" [AllowNegative]="false" noCopyPaste [(ngModel)]="propertiesFilters.amount">
                            <span class='input-group-btn'>
                                <button class='btn btn-info' (click)="applyAmountByFilters()">Aplicar cantidad</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table tablev2 table-bordered">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Propiedad</th>
                        <th>Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let property of propertiesData">
                        <td class='text-center'>
                            <input type="checkbox"  [(ngModel)]="property.selected">
                        </td>
                        <td>
                            {{property.name}}
                        </td>
                        <td>
                            <div class="input-group">
                                <span class="input-group-addon">$</span>
                                <input type="text" ngModel [OnlyNumber]="true" noCopyPaste class="form-control amount" [(ngModel)]="property.amount" [AllowNegative]="false">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div class="text-right">
                <button class="btn btn-default pull-left" (click)="toStep('step1')">Volver</button>
                <button class="btn btn-primary" (click)="toFrequency()">Continuar</button>
            </div>
        </div>
        
        <div id="step3" class="tab-pane fade in active" *ngIf="selectedOption==options[2]">
            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="date" *ngIf="mode=='add'">Fecha del primer cargo</label>
                    <label for="date" *ngIf="mode=='edit'">Fecha del siguiente cargo</label>
                    <!-- <input name="date" id="date" class="form-control" [(ngModel)]="date" ngbDatepicker autocomplete="off"> -->
                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-addon">
                                <a href="javascript:;" (click)="d.toggle()"><fa-icon [icon]="faCalendar"></fa-icon></a>
                                <!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                    
                                </button> -->
                            </span>
                            <input class="form-control datepicker" placeholder="yyyy-mm-dd"
                                 name="dp" [(ngModel)]="frequencyDate" required ngbDatepicker #d="ngbDatepicker" autocomplete="off" readonly (click)="d.toggle()" [minDate]="frequencyData.minDate">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 form-group">
                    <label for="logic">Lógica</label>
                    <select name="logic" id="logic" [(ngModel)]="frequencyData.logic" class="form-control">
                        <option value=""></option>
                        <option value="by_days">Días</option>
                        <option value="by_frequency">Frecuencia</option>
                    </select>
                </div>
            </div>
            <div *ngIf="frequencyData.logic=='by_days'">
                <label>Agregar el cargo los siguientes días:</label>
                <div *ngFor="let day of frequencyData.days">
                    <input type="checkbox" [(ngModel)]="day.selected"> {{day.name}}
                </div>
            </div>
            <div class='row' *ngIf="frequencyData.logic=='by_frequency'">
                <div class="col-md-6">
                    <label for="frequency">Frecuencia</label>
                    <div class="input-group">
                        <span class="input-group-addon">Agregar cargo cada</span>
                        <input type="text" [OnlyNumber]="true" class='form-control' [(ngModel)]="frequencyData.frequency.each" [AllowNegative]="false" noCopyPaste [AllowDot]="false">
                        <span class="input-group-addon">
                            <select [(ngModel)]="frequencyData.frequency.type">
                                <option value=""></option>
                                <option value="days">Días</option>
                                <option value="weeks">Semanas</option>
                                <option value="months">Meses</option>
                                <option value="years">Años</option>
                            </select>
                        </span>
                    </div>
                </div>
            </div>
            <hr>
            <div class="text-right" *ngIf="!savingRecurrent">
                <button class="btn btn-default pull-left" (click)="toStep('step2')">Volver</button>
                <button *ngIf="mode=='add'" class="btn btn-primary" (click)="onSaveRecurrent()">Agregar</button>
                <button *ngIf="mode=='edit'" class="btn btn-primary" (click)="onSaveRecurrent()">Guardar Cambios</button>
            </div>
            <div class="text-right" *ngIf="savingRecurrent">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Guardando...
            </div>
        </div>
    
    </div>
</div>
