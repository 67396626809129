<div class='modal' id='modal_wrapper'>
    <div id='modal_block' class='popup_bg_modal'></div>
    <div id='modal_block_text' class='hidden'>Please wait <i class='fa fa-spinner fa-spin'></i></div>
    <div id='modal_wrap' class='modal-dialog {{alertService.size}}'>
      <div class='modal-content modal-content-v2'>

        <!-- Modal Header -->
        <div class='modal-header'>
            <h3 class="{{alertService.title_color}}"> 
                <fa-icon [icon]="alertService.title_icon" *ngIf="alertService.title_icon"></fa-icon> &nbsp;
                <label id='modal_title' class='modal-title font-18' [innerHTML]="alertService.title"></label>
            </h3>
         
          <button *ngIf="!alertService.syncing" id='modal_close' type='button' class='close' (click)="onClose()">&times;</button>
        </div>

        <!-- Modal body -->
        <div id='modal_body' class='modal-body'>
            <div *ngIf="alertService.loading">
                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
            </div>
            <div *ngIf="!alertService.loading" [innerHTML]="alertService.body">
            </div>
        </div>

        <!-- Modal Footer -->
        <div id='modal_footer' class='modal-footer text-right' *ngIf="alertService.showFooter">
          <div *ngIf="alertService.syncing">
            <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Espere...
          </div>  
          <ul *ngIf="!alertService.syncing">
                <li *ngFor="let button of alertService.buttons">
                    <button class='btn {{button.btn_class}}' (click)="button.onClick()" [BtnAutoFocus]=button.focus><fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon> {{button.title}}</button>
                </li>
            </ul>
        </div>

        <div id='modal_msg' class='modal_msg_wrapper'></div>

      </div>
    </div>
  </div>