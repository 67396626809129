<!-- <div class="container">
    <div id="login-wrapper">
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div class="form-group">
                <label for="lastName">Last Name</label>
                <input type="text" ngModel name="lastName" class='form-control' required>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" ngModel name="password" class='form-control' required>
            </div>
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{ errorMessage }}
            </div>
            <div class="text-right" *ngIf="loggingIn">
                Logging in, please wait <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
            </div>
           <div class="text-right" *ngIf="!loggingIn"></div>
            <div class="text-right" *ngIf="!loggingIn">
                <button [disabled]="!f.valid" class="btn btn-primary">LOGIN</button>
            </div>
        </form>
    </div>
</div> -->
<!-- <login-example-2></login-example-2> -->

<!-- <div id='login-wrapper'>
    <div id="login-bg" class="login-bg"></div>
    <div id="login-bg-color" class="login-bg"></div>
    <div id='box-wrapper' >
        <div id="login-container">
            <div id="login-center">
                <div id="login-flexbox">
                    <div id="login-box">
                        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <div id="login-txt">
                                <h2>Welcome to</h2>
                                <div>Condominiums Puerta Privada Statements Service</div>
                            </div>
                            <div class="form-group">
                                <label for="lastName">Last Name</label>
                                <input type="text" class='form-control' ngModel required name="lastName" placeholder="Your Last Name">
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input type="password" class='form-control' ngModel required name="password" placeholder="Password">
                            </div>
                            <div class="alert alert-danger" *ngIf="errorMessage">
                                {{ errorMessage }}
                            </div>
                            <div class="text-right" *ngIf="loggingIn">
                                Logging in, please wait <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
                            </div>
                            <div id="btn-wrapper" *ngIf="!loggingIn">
                                <button [disabled]="!f.valid" class="btn btn-login">SIGN IN</button>
                            </div>
                        </form>
                    </div>
                    <div id="login-img">
                        <div id="logo-wrapper">
                            <img src="https://api.puertaprivadabills.com/images/puertaprivada-logo2.png" alt="">
                        </div>
                        <img class='bg' src="https://api.puertaprivadabills.com/images/puertaprivadap.jpeg" alt="Puerta Privada">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div id='login-wrapper'>
    <div id="login-bg" class="login-bg"></div>
    <div id="login-bg-color" class="login-bg"></div>
    <div id='box-wrapper' >
        <div id="login-container">
            <div id="login-center">
                <div id="login-flexbox">
                    <div id="login-img">
                        <img src="https://api.puertaprivadabills.com/images/puertaprivadap.jpeg" alt="Puerta Privada">
                    </div>
                    <div id="login-box">
                        <div id="logo-wrapper">
                            <img src="https://api.puertaprivadabills.com/images/puertaprivada-logo2.png" alt="">
                        </div>
                        <div id="login-txt" class="text-center">
                            <h1>Bienvenido</h1>
                            <!-- <div>Condominio Puerta Privada</div> -->
                            <div>Sistema de Administración</div>
                        </div>
                        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <div class="">
                                <input type="text" class='form-control no-radius-bottom' name="email" placeholder="Correo Electrónico" ngModel email required>
                            </div>
                            <div class="form-group">
                                <input type="password" class='form-control no-radius-top' name="password" placeholder="Contraseña" ngModel required>
                            </div>
                            <div class="alert alert-danger" *ngIf="errorMessage">
                                {{ errorMessage }}
                            </div>
                            <div class="text-right text-white" *ngIf="loggingIn">
                                Iniciando sesión, por favor espere <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
                            </div>
                            <div id="btn-wrapper" *ngIf="!loggingIn">
                                <button [disabled]="!f.valid" class="btn btn-login form-control tbold">INICIAR SESIÓN</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>