<app-alert *ngIf="alertService.showAlert"></app-alert>
<div class='modal' id='modal_wrapper'>
    <div id='modal_block' class='popup_bg_modal'></div>
    <div id='modal_block_text' class='hidden'>Please wait <i class='fa fa-spinner fa-spin'></i></div>
    <div id='modal_wrap' class='modal-dialog {{size}}'>
      <div class='modal-content modal-content-v2'>

        <!-- Modal Header -->
        <div class='modal-header'>
          <i id='back-model' class='fa fa-arrow-left margin-right-md hidden pointer' onclick='javascript: ".$this->js_instance.".modal_back();'></i>
          <label id='modal_title' class='modal-title font-18'>{{title}}</label>
          <button id='modal_close' type='button' class='close' (click)="onClose()">&times;</button>
        </div>

        <!-- Modal body -->
        <div id='modal_body' class='modal-body'>
            <div class="page-container">
                <div class="alert alert-info">
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <a href="javascript:;" (click)="dfrom.toggle()"><fa-icon [icon]="faCalendar"></fa-icon> Fecha Inicial</a>
                                </span>
                                <input class="form-control datepicker"
                                     name="dp" [(ngModel)]="filters.from" ngbDatepicker #dfrom="ngbDatepicker" autocomplete="off" readonly (click)="dfrom.toggle()">
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <a href="javascript:;" (click)="dto.toggle()"><fa-icon [icon]="faCalendar"></fa-icon> Fecha Final</a>
                                </span>
                                <input class="form-control datepicker"
                                     name="dp" [(ngModel)]="filters.to" ngbDatepicker #dto="ngbDatepicker" autocomplete="off" readonly (click)="dto.toggle()">
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    Propiedad
                                </span>
                                <select class="form-control datepicker" name="property_id" [(ngModel)]="filters.property_id">
                                    <option value="0">Todas</option>
                                    <option value="{{property.id}}" *ngFor="let property of propertiesFilters">{{property.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12 text-right">
                            <hr>
                            <div *ngIf="applyingFilters">
                                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Obteniendo transacciones...
                            </div>
                            <button *ngIf="!applyingFilters" class="btn btn-info" (click)="applyFilters()">Aplicar Filtros</button>
                        </div>
                    </div>
                </div>
                <table class="table tablev2 table-bordered">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Autor | Propiedad</th>
                            <th>Descripción</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="applyingFilters">
                        <tr>
                            <td colspan="4">
                                <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!applyingFilters">
                        <ng-container *ngIf="records.length === 0">
                            <tr>
                                <td colspan="4">
                                    No se encontraron resultados con los filtros seleccionados
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngFor="let record of records">
                            <td>{{record.date}}</td>
                            <td [innerHTML]="record.author"></td>
                            <td [innerHTML]="record.description"></td>
                            <td>{{record.meta_string}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal Footer -->
        <!-- <div id='modal_footer' class='modal-footer'>
              
        </div> -->

        <div id='modal_msg' class='modal_msg_wrapper'></div>

      </div>
    </div>
  </div>