import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/auth/auth-guard.service";
import { SharedModule } from "src/app/shared/shared.module";
import { ReportsComponent } from "./reports.component";
import { ReportsFiltersComponent } from './filters/filters.component';
import { HistoryComponent } from './history/history.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

export const reportRoutes: Routes = [
    { path: "reportes", canActivate: [AuthGuard], component: ReportsComponent }
];

@NgModule({
    declarations: [
        ReportsComponent,
        ReportsFiltersComponent,
        HistoryComponent
    ],
    imports: [ 
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule
    ],
    exports: [],
    providers: [],
    entryComponents: []
})
export class ReportsModule {}