import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCalendarAlt, faClone, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { FinanceService } from '../../../finance.service';
import { MultipleSelectorComponent } from '../multiple-selector/multiple-selector.component';

@Component({
    selector: 'app-finance-multiple-charges',
    templateUrl: './multiple-charges.component.html',
    styleUrls: ['./multiple-charges.component.css']
})
export class MultipleChargesComponent implements OnInit, OnDestroy {

    faSpinner = faSpinner;
    faCalendar = faCalendarAlt;
    faClone = faClone;
    faDelete = faTimesCircle;

    multipleData: {[key: string]: any}[] = [];
    multipleSelectorSub?: Subscription;
    errorMessage: string = "";
    account: string = "";

    saving: boolean = false;

    @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 
    private closeSub?: Subscription;

    constructor(public financeService: FinanceService, public alertService: AlertService, private componentFactoryResolver: ComponentFactoryResolver) {
        this.multipleSelectorSub = financeService.multipleSelector.subscribe( data => {
            this.setMultipleSelector( data );
        });
    }

    ngOnInit(): void {
        this.initTransactions();
    }

    initTransactions(){
        this.multipleData = [];
        for (let property of this.financeService.meta.properties) {
            this.multipleData.push({
                property_id: property.id,
                property_name: property.name,
                date: {},
                billtype_id: 0,
                description: "",
                amount: "",
                notification: 1
            });
        }
    }

    setMultipleSelector( data: {[key: string]: string}){
        for( let row of this.multipleData ){
            
            if( data.selector=="date" ){
                if( !data.onlyEmpty || (!row.date.hasOwnProperty("year") || row.date["year"]=="") ){
                    row.date = data.value;
                }
            } else if( data.selector=="billtype" ){
                if( !data.onlyEmpty || (row.billtype_id=="") ){
                    row.billtype_id = data.value;
                }
            } else if( data.selector=="description" ){
                if( !data.onlyEmpty || (row.description=="") ){
                    row.description = data.value;
                }
            } else if( data.selector=="amount" ){
                if( !data.onlyEmpty || (row.amount=="") ){
                    row.amount = data.value;
                }
            } else if( data.selector=="notification" ){
                if( !data.onlyEmpty || (row.notification=="") ){
                    row.notification = data.value;
                }
            }
        }
    }

    onDeleteRow( row: {[key:string]: any}){
        this.alertService.setConfirmationAlert("Confirmación", "¿Estás seguro de descartar la transacción de la propiedad "+row.property_name+"?", "Descartar", ()=>{
            this.multipleData = this.multipleData.filter( (data) => {
                if( data.property_id!=row.property_id ){
                    return true;
                }
                return false;
            });
            this.alertService.closeAlert();
        }, true, true );
    }

    openSelector( type: string, selector: string ){
        //this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

        //let editComponent = (parseFloat(this.financeService.editingTransaction["charge"])>0) ? TransactionEditChargeComponent : TransactionEditChargeComponent;
        let editComponent = MultipleSelectorComponent;

        const editCmpFactory = this.componentFactoryResolver.resolveComponentFactory(editComponent);

        const hostViewContainerRef = this.editHost?.viewContainerRef;
        hostViewContainerRef?.clear();

        const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

        componentRef!.instance.type = type;
        componentRef!.instance.selector = selector;
        //componentRef!.instance.transactionID = transaction_id;
        componentRef!.instance.size = "modal-md";

        //this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

        this.financeService.hostViewContainerRef = hostViewContainerRef;

        this.closeSub = componentRef?.instance.close.subscribe( () => {
            this.closeSub?.unsubscribe();
            hostViewContainerRef?.clear();
        });
    }

    onAddMultipleCharges(){
        this.errorMessage = "";
        if( this.account=="" ){
            this.errorMessage = "Debes seleccionar la cuenta";
        } else if( !this.areAllValid() ){
            this.errorMessage = "Hay uno o más campos vacíos";
        } else if( this.multipleData.length==0 ){
            this.errorMessage = "No se encontraron transacciones";
        } else {
            this.addMultipleChargesConfirm();
        }
    }

    addMultipleChargesConfirm(){
        var resume = "¿Estás seguro de agregar los siguientes cargos?<br><br><ul>";
        let total = 0;
        for( let row of this.multipleData ){
            resume += "<li><b>"+row.property_name+"</b>: $"+row.amount+"</li>";
            total += parseFloat(row.amount);
        }
        resume += "</ul>";
        resume += `<br><div class="text-right"><b>Total: </b>$${total.toFixed(2)}</div>`;

        this.alertService.setConfirmationAlert( "Confirmación", resume, "Agregar", () => {
            this.saving = true;
            this.alertService.setAlert( "Por favor espere", "Agregando múltiples cargos, esta acción puede durar varios minutos, por favor espere...", "modal-md" );
            let request = {
                account: this.account,
                transactions: this.multipleData
            };
            this.financeService.addMultipleCharges( request ).subscribe(
                resData => {
                    if( resData.auth ){
                        //Reset form
                        this.account = "";
                        this.initTransactions();
                        //Success
                        this.alertService.setSuccessAlert( "Éxito", "Transacciones agregadas correctamente" );
                    } else {
                        this.alertService.closeAlert();
                        this.errorMessage = "Session expired";
                    }
                    this.saving = false;
                }, error => {
                    this.saving = false;
                    this.alertService.setErrorAlert("Error", error );
                }
            );
        });
    }

    areAllValid(){
        var pass = true;
        for( let row of this.multipleData ){
            if( !row.date.hasOwnProperty("year") || row.date.year=="" || row.billtype_id=="" || row.description=="" || row.amount=="" ){
                pass = false;
            }
        }
        return pass;
    }

    ngOnDestroy(): void {
        this.multipleSelectorSub?.unsubscribe();
    }

}
