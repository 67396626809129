<app-alert *ngIf="alertService.showAlert"></app-alert>
<div class="page-container">

    <div *ngIf="!loaded">
        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Cargando...
    </div>

    <ul class="nav itabs nav-tabs" *ngIf="loaded">
        <li *ngFor="let option of options" [class.active]="selectedOption==option">
            <a (click)="selectedOption=option" data-toggle="tab" href='javascript:;'>{{optionsName[option]}}</a>
        </li>
    </ul>

    <div class="tab-content" *ngIf="loaded">
        <div id="charge" class="tab-pane fade in active" *ngIf="selectedOption==options[0]">
            <app-finance-transactions-charge></app-finance-transactions-charge>
        </div>
        
        <div id="deposit" class="tab-pane fade in active" *ngIf="selectedOption==options[1]">
          <app-finance-transactions-deposit></app-finance-transactions-deposit>
        </div>
        
        <div id="multiple-charge" class="tab-pane fade in active" *ngIf="selectedOption==options[2]">
            <app-finance-multiple-charges></app-finance-multiple-charges>
        </div>
        
        <div id="multiple-deposit" class="tab-pane fade in active" *ngIf="selectedOption==options[3]">
            <app-finance-multiple-deposits></app-finance-multiple-deposits>
        </div>

        <div id="import" class="tab-pane fade in active" *ngIf="selectedOption==options[4]">
            <app-transactions-import></app-transactions-import>
        </div>
    
    </div>
</div>