<app-alert *ngIf="alertService.showAlert"></app-alert>
<ng-template appPlaceholder></ng-template>
<div class='page-container'>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3>Reportes</h3>
        </div>
        <div class="panel-body">
            <ul class="list-group">
                <li class="list-group-item" (click)="openReportFilters(1)">
                    <fa-icon [icon]="faTransactions"></fa-icon> Transacciones
                </li>
                <!-- <li class="list-group-item" (click)="openReportFilters(2)">
                    <fa-icon [icon]="faResume"></fa-icon> Resumen de transacciones
                </li> -->
                <li class="list-group-item" (click)="openReportFilters(3)">
                    <fa-icon [icon]="faBalances"></fa-icon> Balances
                </li>
                <li class="list-group-item" (click)="openHistory()">
                    <fa-icon [icon]="faHistory"></fa-icon> Historial de movimientos
                </li>
            </ul>
        </div>
    </div>
</div>
