import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCalendarAlt, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { environment } from 'src/environments/environment';
import { FinanceService } from '../../finance/finance.service';

@Component({
    selector: 'app-reports-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.css']
})
export class ReportsFiltersComponent implements OnInit {

    size: string = "modal-md";
    title: string = "";
    reportId: number = 0;
    faSpinner = faSpinner;
    faCalendar = faCalendarAlt;
    faPdf = faFilePdf;

    titles: {[key: number]: string} = {
        1: "Reporte de Transacciones",
        2: "Reporte de Resumen de Transacciones",
        3: "Reporte de Balances"
    };
    urls: {[key: number]: string} = {
        1: "/reports/admin/transactions/",
        2: "/reports/admin/transactions-resume/",
        3: "/reports/admin/balances/"
    };

    @Output() close = new EventEmitter<void>();
    errorMessage: string = "";
    loading: boolean = true;

    dateType: {[key:string]: number} = {};

    filters = {
        from : this.dateType,
        to: this.dateType,
        property_id: "",
        account_id: "",
        billtype_id: "",
        currency: ""
    };

    constructor( public alertService: AlertService, public financeService: FinanceService, private authService: AuthService ) { }

    ngOnInit(): void {
        this.title = this.titles[this.reportId];
        var from: Date = new Date();
        from.setDate( from.getDate() - 30);
        var to: Date = new Date();
        this.filters.from = {
            year: parseInt(formatDate(from, 'yyyy', 'en')),
            month: parseInt(formatDate(from, 'MM', 'en')),
            day: parseInt(formatDate(from, 'dd', 'en'))
        };
        this.filters.to = {
            year: parseInt(formatDate(to, 'yyyy', 'en')),
            month: parseInt(formatDate(to, 'MM', 'en')),
            day: parseInt(formatDate(to, 'dd', 'en'))
        };

        if( this.financeService.meta.hasOwnProperty("billtypes") && 
            this.financeService.meta.billtypes.length>0 ){
            this.loading = false;
        } else {
            this.getMeta();
        }
    }

    getMeta(){
        this.financeService.getAddTransactionMeta().subscribe(
            resData => {
                if( resData.auth ){
                    this.loading = false;
                } else {
                    this.onSessionExpired();
                }
            }, error => {
                this.alertService.setErrorAlert("Error", error, () => {
                    this.onClose();
                } );
            }
        )
    }

    getFiltersQ(){
        var from = "";
        if( this.filters.from.hasOwnProperty("year") ){
            var year = this.filters.from.year;
            var month = this.filters.from.month;
            var day = this.filters.from.day;
            var month_s = (month<10) ? '0' + month : month;
            var day_s = (day<10) ? '0' + day : day;
            from = year+"-"+month_s+"-"+day_s;
        }
        var to = "";
        if( this.filters.to.hasOwnProperty("year") ){
            var year = this.filters.to.year;
            var month = this.filters.to.month;
            var day = this.filters.to.day;
            var month_s = (month<10) ? '0' + month : month;
            var day_s = (day<10) ? '0' + day : day;
            to = year+"-"+month_s+"-"+day_s;
        } 
        
        var string = "?from="+from+"&to="+to+"&property="+this.filters.property_id+"&account="+this.filters.account_id+"&billtype="+this.filters.billtype_id+"&currency="+this.filters.currency;

        return string;
    }

    getAuthQ(){
        return "&uid="+this.authService.currentUser?.id+"&token="+this.authService.currentUser?.token;
    }

    onPdfReportGenerate(){
        this.errorMessage = "";
        if( this.reportId==3 && this.filters.account_id=="" ){
            this.errorMessage = "Debes seleccionar una cuenta";
        } if( this.reportId==1 && this.filters.account_id=="" ){
            this.errorMessage = "Debes seleccionar una cuenta";
        } else {
            var url = environment.endpoint + this.urls[this.reportId] + this.getFiltersQ() + this.getAuthQ();
            window.open( url, "_blank" );
        }
    }

    onSessionExpired(){
        this.alertService.setErrorAlert("Error", "Session expired.", () => {
            this.onClose();
        });
    }

    onClose(){
        this.close.emit();
    }

}
