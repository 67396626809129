import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './user.model';
import { Router } from '@angular/router';

interface AuthResponseData {
    auth: boolean,
    error: string,
    name: string,
    email: string,
    id: string,
    token: string,
    expiresIn: string,
    modules: {
        [key: string]: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user = new BehaviorSubject<User | null>(null);
    currentUser?: User | null;
    isLoggedIn = false;

    constructor( private http: HttpClient, private router: Router ){}

    isAuthenticated() {
        const promise = new Promise(
            (resolve, reject) => {
                setTimeout( () => {
                    // console.log("isAuthenticated", this.isLoggedIn);
                    // resolve( this.isLoggedIn );
                    if( this.currentUser ){
                        if( this.currentUser?.token ){
                            resolve(true);
                        }
                    }
                    resolve(false);
                }, 1 );
            }
        )
        return promise;
    }

    autoLogin(){
        var userDataString = localStorage.getItem('userData');
        userDataString = userDataString ? userDataString : "";
        if( !userDataString ){
            return;
        }
        const userData: {
            email: string,
            name: string,
            id: string,
            _token: string,
            _tokenExpirationDate: string,
            modules: {
                [key: string]: string
            }
        } = JSON.parse( userDataString );
        if( !userData ){
            return;
        }
        console.log(userData);
        const loadedUser = new User( 
            userData.email, 
            userData.name, 
            userData.id, 
            userData._token,
            new Date( userData._tokenExpirationDate ),
            userData.modules
        );
        console.log( loadedUser.token );
        if( loadedUser.token ){
            this.user.next( loadedUser );
            this.currentUser = loadedUser;
            this.isLoggedIn = true;
        }
    }
    
    login( email: string, password: string ){
        return this.http.post<AuthResponseData>( 
            environment.endpoint+"?key="+environment.key+"&method=login_a",
            {
                email: email, 
                password: password 
            }
        ).pipe( 
            catchError( this.handleLoginError ), 
            tap( resData => {
                //const expirationDate = new Date( new Date().getTime() + +resData.expiresIn * 1000);
                const expirationDate = new Date( resData.expiresIn );
                const user = new User( resData.email, resData.name, resData.id, resData.token, expirationDate, resData.modules );
                this.user.next(user);
                this.currentUser = user;
                localStorage.setItem( "userData", JSON.stringify(user) );
            })
        );
    }

    handleLoginError( errorRes: HttpErrorResponse ){
        console.log("errorRes", errorRes);
        let errorMessage = "An unknown error ocurred!";
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        return throwError(errorMessage);
    }

    logout(){
        this.user.next(null);
        this.currentUser = null;
        localStorage.removeItem("userData");
        this.isLoggedIn = false;
        this.router.navigate(['/']);
    }
}