import { formatDate } from '@angular/common';
import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowRight, faArrowUp, faListAlt, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { PlaceholderDirective } from 'src/app/shared/placeholder.directive';
import { SubmenuService } from '../../submenu.service';
import { FinanceService } from '../finance.service';
import { TransactionEditChargeComponent } from './edit/charge/charge.component';

@Component({
    selector: 'app-finance-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit, OnDestroy {

    viewInit: boolean = false;
    applyingFilters: boolean = true;
    filtersMetaLoaded: boolean = false;

    faCalendar = faCalendarAlt;
    faSpinner = faSpinner;
    faArrowUp = faArrowUp;
    faArrowDown = faArrowDown;
    faArrowRight = faArrowRight;

    dateFrom?: NgbDateStruct;
    dateTo?: NgbDateStruct;

    errorMessage: string = "";

    @ViewChild(PlaceholderDirective,  {static: false}) editHost?: PlaceholderDirective; 
    private closeSub?: Subscription;

    constructor( private submenuService: SubmenuService, private route: ActivatedRoute, public financeService: FinanceService, private componentFactoryResolver: ComponentFactoryResolver, public alertService: AlertService) { }

    ngOnInit(): void {
        //Init filters
        var from: Date = new Date();
        from.setDate( from.getDate() - 30);
        var to: Date = new Date();
        this.dateFrom = {
            year: parseInt(formatDate(from, 'yyyy', 'en')),
            month: parseInt(formatDate(from, 'MM', 'en')),
            day: parseInt(formatDate(from, 'dd', 'en'))
        };
        this.dateTo = {
            year: parseInt(formatDate(to, 'yyyy', 'en')),
            month: parseInt(formatDate(to, 'MM', 'en')),
            day: parseInt(formatDate(to, 'dd', 'en'))
        };
        
        this.updateRootMenu();
        //this.submenuService.rightOptions.next([]);
        this.updateNavBar();

        //Filters meta data
        if( this.financeService.meta.hasOwnProperty("properties") && this.financeService.meta.properties.length>0 ){
            this.filtersMetaLoaded = true;
        }

        //GET TRANSACTIONS
        this.applyFilters();
    }

    ngAfterViewInit(){
        this.viewInit = true;
    }

    updateRootMenu(){
        if( !this.viewInit ){
            setTimeout( () => { 
            this.submenuService.updateRoot( this.route.snapshot );
            }, 10);
        } else {
            this.updateRootMenu();
        }
    }

    updateNavBar(){
        this.submenuService.rightOptions.next([
            {
              name: "Agregar Transacción",
              icon: faPlusCircle,
              link: "finanzas/transacciones/agregar"
            },
            {
              name: "Lista de Transacciones",
              icon: faListAlt,
              link: "finanzas/transacciones"
            }
          ]
        );
    }

    getTransactions(){
        this.applyingFilters = true;
        this.financeService.transactionsList = [];
        this.financeService.getTransactions(this.filtersMetaLoaded).subscribe(
            resData => {
                if( this.financeService.meta.hasOwnProperty("properties") && this.financeService.meta.properties.length>0 ){
                    this.filtersMetaLoaded = true;
                }

                this.financeService.transactionsList = resData.transactions;
                
                this.applyingFilters = false;
            }, errorMessage => {
                this.alertService.setErrorAlert("Error", errorMessage);
                this.errorMessage = errorMessage;
                this.applyingFilters = false;
            }
        );
    }

    getTransactionIcon( transaction: {[key: string]: string} ): IconProp{
        if( parseFloat(transaction.charge)>0 ){
            return faArrowUp;
        } else if( parseFloat(transaction.deposit)>0 ){
            return faArrowDown;
        }
        return faArrowRight;
    }

    getTransactionIconColor( transaction: {[key: string]: string} ): string {
        if( parseFloat(transaction.charge)>0 ){
            return "text-danger";
        } else if( parseFloat(transaction.deposit)>0 ){
            return "text-success";
        }
        return "";
    }

    getTransactionBilltype( transaction: {[key: string]: string} ): string{
        if( parseInt(transaction.billtype_id)>0 ){
            return transaction.billtype;
        } else if( parseFloat(transaction.deposit)>0 ){
            return "Deposit";
        }
        return "";
    }

    getTransactionTotal( transaction: {[key: string]: string} ): string{
        if( parseFloat(transaction.charge)>0 ){
            return transaction.charge_formatted;
            //return "$"+this.numberWithCommas(parseFloat(transaction.charge).toFixed(2));
        } else if( parseFloat(transaction.deposit)>0 ){
            return transaction.deposit_formatted;
            //return "$"+this.numberWithCommas(parseFloat(transaction.deposit).toFixed(2));
        }
        return "";
    }

    numberWithCommas(x: string) {
        console.log("converting number");
        //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        return x.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    applyFilters(){
        //Update date filters
        if( this.dateFrom?.hasOwnProperty("year") ){
            let from = this.dateFrom?.year.toString() + "-" + ( this.dateFrom?.month<10 ? "0"+this.dateFrom?.month : this.dateFrom?.month ) + "-" + (this.dateFrom?.day<10 ? "0"+this.dateFrom?.day : this.dateFrom?.day);
            this.financeService.transFilters.from = from;
        } else {
            this.financeService.transFilters.from = "";
        }
        if( this.dateTo?.hasOwnProperty("year") ){
            let to = this.dateTo?.year.toString() +"-"+ ( this.dateTo?.month<10 ? "0"+this.dateTo?.month : this.dateTo?.month ) + "-" + (this.dateTo?.day<10 ? "0"+this.dateTo?.day : this.dateTo?.day);
            this.financeService.transFilters.to = to;
        } else {
            this.financeService.transFilters.to = "";
        }

        //Get transactions
        this.getTransactions();
    }

    onEditTransaction( transaction_id: string ){
        console.log( "Open in modal", transaction_id );

        this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

        let editComponent = (parseFloat(this.financeService.editingTransaction["charge"])>0) ? TransactionEditChargeComponent : TransactionEditChargeComponent;

        const editCmpFactory =this.componentFactoryResolver.resolveComponentFactory(editComponent);

        const hostViewContainerRef = this.editHost?.viewContainerRef;
        hostViewContainerRef?.clear();

        const componentRef = hostViewContainerRef?.createComponent(editCmpFactory);

        componentRef!.instance.transactionID = transaction_id;
        componentRef!.instance.size = "modal-xl";

        this.financeService.editingTransaction = this.financeService.transactionsAssoc![transaction_id];

        this.financeService.hostViewContainerRef = hostViewContainerRef;

        this.closeSub = componentRef?.instance.close.subscribe( () => {
            this.closeSub?.unsubscribe();
            hostViewContainerRef?.clear();
        });
    }

    onSessionExpired(){
        this.errorMessage = "Session expired.";
    }

    ngOnDestroy(){
        this.financeService.editingTransaction = null;
        this.financeService.transactionsList = [];
        this.financeService.transactionsAssoc = {};
    }
}
