import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

interface serverResponse {
    auth: boolean,
    error?: string,
    success?: string,
    meta?: any,
    transactions?: any,
    transactions_assoc? : any,
    billtypes?: any,
    billtypes_assoc?: any ,
    billtype?: any,
    properties?: any
    properties_assoc?: any,
    property_types?: any,
    transaction_id?: any,
    settings?: any,
    accounts?: any,
    accounts_assoc?: any,
    recurrents?: any
}

@Injectable({
    providedIn: 'root'
})
export class FinanceService {
    filters = {
        status: 1
    }
    hostViewContainerRef: any = null;

    transFilters = {
        from: "",
        to: "",
        property_id: "",
        currency: "",
        transtype: "",
        status: "1",
        account_id: ""
    }
    transactionsList: any[] = [];
    transactionsAssoc?: {
        [key: string] : {
           [key: string] : string 
        }
    };
    editingTransaction: any = null;

    billtypesList: any[] = [];
    billtypesAssoc: any = null;
    editingBilltype: any = null;
    billtypesListBackup: any = null;

    multipleSelector: Subject<{[key: string]: any}> = new Subject<{[key: string]: any}>();
    
    meta: {
        [key: string] : any
    } = {}

    settings?: {
        [key: string]: any
    } = {};

    imported = {
        inicial : "",
        cargos: [],
        abonos: []
    }

    recurrents: {[key: string]: any }[] = [];
    editingRecurrent: any = null;

    constructor( private http: HttpClient ){}

    addBillType( name: string, alias: string ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addBillType",
            {name: name, alias: alias}
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    editBillType( name:string, alias: string ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=editBillType",
            {name: name, alias: alias, id: this.editingBilltype.id }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("billtypes_assoc") ){
                    this.billtypesAssoc = resData.billtypes_assoc
                }
                if( resData.hasOwnProperty("billtypes") ){
                    this.billtypesListBackup = resData.billtypes
                }
            })
        );
    }

    deleteBillType(){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=deleteBillType",
            { id: this.editingBilltype.id }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("billtypes_assoc") ){
                    this.billtypesAssoc = resData.billtypes_assoc
                }
                if( resData.hasOwnProperty("billtypes") ){
                    this.billtypesListBackup = resData.billtypes
                }
            })
        );
    }

    getBillTypes( filters: {[key: string]: any} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getBilltypes",
            {filters: filters}
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("billtypes_assoc") ){
                    this.billtypesAssoc = resData.billtypes_assoc
                }
                if( resData.hasOwnProperty("billtypes") ){
                    this.billtypesListBackup = resData.billtypes
                }
            })
        );
    }

    setEditingBilltype( billtype_id: string ){
        if( this.billtypesAssoc.hasOwnProperty(billtype_id) ){
            this.editingBilltype = this.billtypesAssoc[billtype_id];
        }
    }

    getAddTransactionMeta(){
        return this.http.get<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getAddTransactionMeta"
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //console.log("getAddTransactionMeta", resData );
                if( resData.hasOwnProperty("properties") ){
                    this.meta.properties = resData["properties"]
                }
                if( resData.hasOwnProperty("properties_assoc") ){
                    this.meta.properties_assoc = resData["properties_assoc"]
                }
                if( resData.hasOwnProperty("property_types") ){
                    this.meta.property_types = resData["property_types"]
                }
                if( resData.hasOwnProperty("billtypes_assoc") ){
                    this.meta.billtypes_assoc = resData.billtypes_assoc
                }
                if( resData.hasOwnProperty("billtypes") ){
                    this.meta.billtypes = resData.billtypes
                }
                if( resData.hasOwnProperty("accounts") ){
                    this.meta.accounts = resData.accounts
                }
                if( resData.hasOwnProperty("accounts_assoc") ){
                    this.meta.accounts_assoc = resData.accounts_assoc
                }
            })
        );
    }

    addCharge( data: {[key: string]: string} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addCharge",
            {data: data}
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    addDeposit( data: {[key: string]: string} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addDeposit",
            {data: data}
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    editCharge( data: {[key: string]: string} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=editCharge",
            {data: data, filters: this.transFilters}
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("transactions") ){
                    this.transactionsList = resData.transactions;
                }
                if( resData.hasOwnProperty("transactions_assoc") ){
                    this.transactionsAssoc = resData.transactions_assoc;
                }
            })
        );
    }
    
    editDeposit( data: {[key: string]: string} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=editDeposit",
            {data: data, filters: this.transFilters}
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("transactions") ){
                    this.transactionsList = resData.transactions;
                }
                if( resData.hasOwnProperty("transactions_assoc") ){
                    this.transactionsAssoc = resData.transactions_assoc;
                }
            })
        );
    }

    getSettings(){
        return this.http.get<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getSettings"
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //console.log("getAddTransactionMeta", resData );
                if( resData.hasOwnProperty("settings") ){
                    this.settings = resData["settings"]
                }
            })
        );
    }

    updateSettings( settings: {[key: string]: string} ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=updateSettings",
            {settings: settings}
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    getTransactions( getMeta: boolean ){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getTransactions",
            {
                getMeta: getMeta,
                filters: this.transFilters
            }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("properties") ){
                    this.meta.properties = resData["properties"]
                }
                if( resData.hasOwnProperty("properties_assoc") ){
                    this.meta.properties_assoc = resData["properties_assoc"]
                }
                if( resData.hasOwnProperty("billtypes_assoc") ){
                    this.meta.billtypes_assoc = resData.billtypes_assoc
                }
                if( resData.hasOwnProperty("billtypes") ){
                    this.meta.billtypes = resData.billtypes
                }
                if( resData.hasOwnProperty("transactions_assoc") ){
                    this.transactionsAssoc = resData.transactions_assoc;
                }
                if( resData.hasOwnProperty("accounts") ){
                    this.meta.accounts = resData.accounts;
                }
                if( resData.hasOwnProperty("accounts_assoc") ){
                    this.meta.accounts_assoc = resData.accounts_assoc;
                }
            })
        );
    }

    postFile(fileToUpload: File | null ) {
        //const endpoint =  environment.endpoint+"?key="+environment.key+"&method=uploadImportTransactions";
        //const formData: FormData = new FormData();
        //formData.append('fileKey', fileToUpload, fileToUpload.name);
        console.log("file to upload", fileToUpload );
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload ?? "key", fileToUpload?.name );
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=uploadImportTransactions",
            formData
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                if( resData.hasOwnProperty("transactions") ){
                    if( resData["transactions"]["inicial"] ){
                        this.imported.inicial = resData["transactions"]["inicial"];
                    } else {
                        this.imported.inicial = "";
                    }
                    if( resData["transactions"]["cargos"].length>0 ){
                        this.imported.cargos = resData["transactions"]["cargos"];
                    }else{
                        this.imported.cargos = [];
                    }
                    if( resData["transactions"]["abonos"].length>0 ){
                        this.imported.abonos = resData["transactions"]["abonos"];
                    }else{
                        this.imported.abonos = [];
                    }
                }
            })
        );
        // return this.httpClient
        //   .post(endpoint, formData, { headers: yourHeadersConfig })
        //   .map(() => { return true; })
        //   .catch((e) => this.handleError(e));
    }

    saveImportedTransactions( data: {[key: string]: any}){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=saveImportedTransactions",
            { data: data }
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    saveRecurrentCharge( data: {[key: string]: any}){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=saveRecurrentCharge",
            { data: data }
        ).pipe(
            catchError( this.handleLoginError )
        );
    }

    updateRecurrentCharge( data: {[key: string]: any}){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=updateRecurrentCharge",
            { data: data }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //update recurring list
                if( resData.hasOwnProperty("recurrents") ){
                    this.recurrents = resData.recurrents;
                }
            })
        );
    }

    deleteRecurrentCharge( recurrent_id: string){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=deleteRecurrentCharge",
            { recurrent_id: recurrent_id }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //update recurring list
                if( resData.hasOwnProperty("recurrents") ){
                    this.recurrents = resData.recurrents;
                }
            })
        );
    }

    getRecurrents(){
        return this.http.get<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=getRecurrents"
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //console.log("getRecurrents", resData );
                if( resData.hasOwnProperty("recurrents") ){
                    this.recurrents = resData.recurrents;
                }
            })
        );
    }

    updateStatementsSettings( data: {[key: string]: any}){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=updateStatementsSettings",
            { data: data }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //resData
            })
        );
    }

    addMultipleCharges( data: {[key: string]: any}){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addMultipleCharges",
            { data: data }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //resData
            })
        );
    }

    addMultipleDeposits( data: {[key: string]: any}){
        return this.http.post<serverResponse>( 
            environment.endpoint+"?key="+environment.key+"&method=addMultipleDeposits",
            { data: data }
        ).pipe(
            catchError( this.handleLoginError ),
            tap( resData => {
                //resData
            })
        );
    }

    handleLoginError( errorRes: HttpErrorResponse ){
        console.log("errorRes", errorRes);
        let errorMessage = "Error desconocido!";
        if( errorRes.status==405 ){
            return throwError("El método no fue encontrado. Contacte al desarrollador del sistema si este problema persiste.");
        }
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        return throwError(errorMessage);
    }
}