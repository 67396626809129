import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[OnlyNumber]',
})
export class OnlyNumber {

    constructor(private el: ElementRef, private control: NgControl) { }
  
    @Input() OnlyNumber?: boolean;
    @Input() AllowNegative: boolean = true;
    @Input() AllowDot: boolean = true;
  
    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
      let e = <KeyboardEvent> event;
      let value = (<HTMLInputElement>e.target).value;
      if (this.OnlyNumber) {
        if ( 
            (this.AllowDot && e.keyCode==190 && value.indexOf(".")!==-1) ||
            (this.AllowNegative && e.keyCode==189 && value.indexOf("-")!==-1 ) 
        ){
             //Allow only one . and -       
          e.preventDefault();
        }
        if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
          // Allow: Ctrl+A
          (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
          // Allow: Ctrl+C
          (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
          // Allow: Ctrl+V
          (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
          // Allow: Ctrl+X
          (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39) ||
          (this.AllowNegative && e.keyCode==189) ||
          (this.AllowDot && e.keyCode==190) ) {
            // let it happen, don't do anything
            return;
          }
          // Ensure that it is a number and stop the keypress
          if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
              e.preventDefault();
          }
        }
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
      let e = <KeyboardEvent> event;
      let value = (<HTMLInputElement>e.target).value;
      if (this.OnlyNumber && this.AllowNegative) {
        if ( (e.keyCode==189 && value.indexOf("-")!==0 ) ){
          //Force - to be first position   
          let new_value = "-" + value.replace("-", "");
          (<HTMLInputElement>e.target).value = new_value;
          this.control.control?.setValue(new_value);
        }
      }
    }
  }