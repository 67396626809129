import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { financeRoutes } from './panel/finance/finance.module';
import { PanelComponent } from './panel/panel.component';
import { propertyRoutes } from './panel/properties/properties.module';
import { reportRoutes } from './panel/reports/reports.module';
import { userRoutes } from './panel/users/users.module';

var uRoutes = userRoutes;
var pRoutes = propertyRoutes;
var fRoutes = financeRoutes;
var rRoutes = reportRoutes;
const appRoutes: Routes = [
    { 
        path: "panel", canActivate: [AuthGuard], component: PanelComponent, children: uRoutes.concat(pRoutes).concat(fRoutes).concat(rRoutes)
    }
]

@NgModule({
    declarations: [],
    imports: [ RouterModule.forRoot( appRoutes ) ],
    exports: [ RouterModule ],
    providers: [],
})
export class AppRoutingModule {}