import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from './alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

    faSpinner = faSpinner;

    constructor( public alertService: AlertService ) { }

    ngOnInit(): void { }

    onClose(){
        this.alertService.closeAlert();
    }
}
