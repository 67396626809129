<ng-template appPlaceholder></ng-template>
<div class="page-container">
    <div class="row form-group">
        <div class="col-md-8">
            <div class="input-group">
                <span class='input-group-addon'>
                    <fa-icon [icon]="faSearch" ></fa-icon>
                </span>
                <input type="text" class='form-control' (keyup)="onSearch($event)">
            </div>
        </div>
        <div class="col-md-4">
            <select class='form-control' (change)="onFilterStatusChange($event)" [ngModel]="usersService.filters.status">
                <option value="10">Mostrar todos</option>
                <option value="0">Mostrar solo inactivos</option>
                <option value="1">Mostrar solo activos</option>
            </select>
        </div>
    </div>
    <table class="table table-bordered table-striped table-hover">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Propiedades</th>
                <th>Correo Electrónico</th>
                <th>Teléfono</th>
                <th>Tipo</th>
                <th>Estado</th>
            </tr>
        </thead>
        <tbody id="list-results">
            <tr *ngIf="errorMessage">
                <td colspan="6">
                    <div class="alert alert-danger">{{errorMessage}}</div>
                </td>
            </tr>
            <tr *ngIf="syncing">
                <td colspan="6"><fa-icon [icon]="faSpinner" [spin]="true"></fa-icon> Loading...</td>
            </tr>
            <tr *ngFor="let user of usersService.usersList">
                <td><a href="javascript:;" (click)="editUser(user.id)">{{user.name}}</a></td>
                <td>
                    <span class="td-list-element" *ngFor="let property of user.properties">{{property.name}}</span>
                </td>
                <td>{{user.email}}</td>
                <td>{{user.phone}}</td>
                <td>{{user.type}}</td>
                <td>{{user.status}}</td>
            </tr>
        </tbody>
    </table>
</div>