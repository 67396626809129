import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[BtnAutoFocus]',
})
export class BtnAutoFocus {

    @Input('BtnAutoFocus') focus?: boolean;

    constructor(private el: ElementRef) {
        if (this.focus && !el.nativeElement['focus']) {
            throw new Error("Element does not accept focus");
        }
    }

    ngOnInit() {
        if (this.focus) {
            const button: HTMLButtonElement = this.el.nativeElement as HTMLButtonElement;
            button.focus();
        }
    }
}