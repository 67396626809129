import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SubmenuService } from '../../submenu.service';
import { faLock, faPlusCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { PropertiesService } from '../properties.service';
import { AlertButton, AlertService } from 'src/app/shared/alert/alert.service';

interface MyAccess {
    [key: string]: boolean;
}

@Component({
    selector: 'app-add-property',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.css']
})
export class AddPropertyComponent implements OnInit {

    @Input() mode: string = "add";
    @ViewChild('f', { static: true }) form?: NgForm;

    userType: string = "";
    fasLock = faLock;
    faEnvelope = faEnvelope;
    faSpinner = faSpinner;
    faCheck = faCheckCircle;
    faPlus = faPlusCircle;
    faDelete = faTimesCircle;
    allAccess: MyAccess = {
        users: false,
        properties: false
    };

    successMessage: string = "";
    errorMessage: string = "";
    saving: boolean = false;
    ready: boolean = false;

    propertyOwners: any = null;
    propertyOwnersAssoc: { [key: string]: string } = {};
    propertyTypes: any = null;

    successTimer: any = null;
    deleteConfirm: boolean = false;
    propertyDeleted: boolean = false;

    showAlert: boolean = false;

    ownerSelected: string = "";
    additionalOwners: string[] = [];
    additionalOwnerSelected: string = "";

    constructor(private submenuService: SubmenuService, private route: ActivatedRoute, private propertiesService: PropertiesService, public alertService: AlertService) {
    }

    ngOnInit(): void {
        console.log(this.form);
        this.submenuService.updateRoot(this.route.snapshot);

        //Get User Meta
        this.getPropertyMeta();
        //this.getUserMeta();
    }

    loadProperty(force: boolean = false) {
        console.log("trying loaduser", this.propertiesService.editingProperty);
        console.log(this.form);
        setTimeout(() => {
            if (this.form?.value.name == "" || force) {
                //console.log("forcing:", force);
                if (this.propertiesService.editingProperty) {
                    this.form?.reset({
                        name: this.propertiesService.editingProperty.name,
                        owner: this.propertiesService.editingProperty.owner_id,
                        type: this.propertiesService.editingProperty.type_id,
                        currency: this.propertiesService.editingProperty.currency,
                        bedrooms: this.propertiesService.editingProperty.bedrooms,
                        status: this.propertiesService.editingProperty.status
                    });
                    (<HTMLInputElement>document.getElementById("type")).value = this.propertiesService.editingProperty.type_id;
                    (<HTMLInputElement>document.getElementById("owner")).value = this.propertiesService.editingProperty.owner_id;
                    (<HTMLInputElement>document.getElementById("bedrooms")).value = this.propertiesService.editingProperty.bedrooms;
                    (<HTMLInputElement>document.getElementById("currency")).value = this.propertiesService.editingProperty.currency;
                    (<HTMLInputElement>document.getElementById("status")).value = this.propertiesService.editingProperty.status;

                    this.additionalOwners = this.propertiesService.editingProperty.additional_owners;
                }
                this.loadProperty();
            } else {
                this.ready = true;
            }
        }, 100);
    }

    getPropertyMeta() {
        this.propertiesService.getPropertyMeta().subscribe(
            resData => {
                if (resData.auth) {
                    if (resData.meta.hasOwnProperty("owners")) {
                        this.propertyOwners = resData.meta.owners;
                        for (let owner of resData.meta.owners) {
                            this.propertyOwnersAssoc[owner.id] = owner.name;
                        }
                    }
                    if (resData.meta.hasOwnProperty("types")) {
                        this.propertyTypes = resData.meta.types;
                    }

                    //Load data if edit
                    if (this.mode == "edit") {
                        this.loadProperty();
                    } else if (this.mode == "add") {
                        this.ready = true;
                    }
                    //console.log(resData);
                } else {
                    this.onSessionExpired();
                }
            }, errorMessage => {
                this.errorMessage = errorMessage;
            }
        );
    }

    onSaveChanges() {
        this.errorMessage = "";
        this.successMessage = "";

        if (!this.form?.valid) {
            return;
        }

        var request = this.getFormData(this.form!);

        if (!request.name || !request.type || !request.bedrooms || !request.currency) {
            this.errorMessage = "Solo el campo de dueño es opcional";
            return;
        }

        //save changes
        this.saving = true;
        request["property_id"] = this.propertiesService.editingProperty["id"];
        request.additional_owners = this.additionalOwners;
        //Send data to the server
        this.propertiesService.editProperty(request, this.propertiesService.filters).subscribe(
            resData => {
                if (resData.auth) {
                    if (resData.hasOwnProperty("properties")) {
                        this.propertiesService.propertiesList = resData.properties;
                    }
                    this.onSuccess(resData.success ?? "");
                } else {
                    this.onSessionExpired();
                }
                this.saving = false;
            }, errorMessage => {
                this.errorMessage = errorMessage;
                this.saving = false;
            }
        );
    }

    onSubmit(form: NgForm) {
        console.log(form.form.value);

        this.errorMessage = "";
        this.successMessage = "";

        if (!form.valid) {
            return;
        }

        var request = this.getFormData(form);

        if (!request.name || !request.type || !request.bedrooms || !request.currency) {
            this.errorMessage = "Solo el campo de dueño es opcional";
            return;
        }

        request.additional_owners = this.additionalOwners;

        //Send data to the server
        this.propertiesService.addProperty(request).subscribe(
            resData => {
                if (resData.auth) {
                    form.reset();
                    this.successMessage = resData.success ?? "";
                } else {
                    this.onSessionExpired();
                }
            }, errorMessage => {
                this.errorMessage = errorMessage;
            }
        );
    }

    onSuccess(message: string) {
        this.successMessage = message;
        document.getElementById("modal_wrapper")!.scrollTop = 0;
    }

    onDelete() {
        this.errorMessage = "";
        //Show alert to confirm
        var body = "<div class='alert alert-danger'>¿Estás seguro de eliminar la propiedad " + this.propertiesService.editingProperty["name"] + "?<br><small>*Esta acción no puede revertirse</small></div>";
        const buttons: AlertButton[] = [
            {
                title: "Cancelar",
                btn_class: "btn-default",
                onClick: () => {
                    this.alertService.closeAlert();
                }
            },
            {
                title: "Eliminar",
                btn_class: "btn-danger",
                onClick: () => {
                    this.onDeleteConfirmed()
                }
            }
        ]
        this.alertService.setAlert("Confirmación", body, "modal-md", buttons);
    }

    onDeleteConfirmed() {
        this.saving = true;
        this.propertiesService.deleteProperty(this.propertiesService.editingProperty["id"], this.propertiesService.filters).subscribe(
            resData => {
                if (resData.auth) {
                    if (resData.hasOwnProperty("properties")) {
                        this.propertiesService.propertiesList = resData.properties;
                    }
                    this.propertyDeleted = true;
                    this.alertService.setSuccessAlert("Éxito", "Propiedad eliminada correctamente", () => {
                        this.propertiesService.hostViewContainerRef?.clear();
                    });
                } else {
                    this.onSessionExpired();
                }
                this.deleteConfirm = false;
                this.saving = false;
            }, errorMessage => {
                this.alertService.closeAlert();
                this.saving = false;
                this.deleteConfirm = false;
                this.errorMessage = errorMessage;
            }
        );
    }

    getFormData(form: NgForm) {
        form.form.value.name = form.form.value.name.trim();
        var request = form.form.value;
        return request;
    }

    addAdditionalOwner() {
        if (this.additionalOwnerSelected && !this.additionalOwners.includes(this.additionalOwnerSelected)
            && this.additionalOwnerSelected != this.ownerSelected) {
            this.additionalOwners.push(this.additionalOwnerSelected);
        }
    }

    onOwnerSelected() {
        if (this.ownerSelected && this.additionalOwners.length > 0) {
            for (let i = 0; i < this.additionalOwners.length; i++) {
                if (this.additionalOwners[i] == this.ownerSelected) {
                    this.additionalOwners.splice(i, 1);
                }
            }
        }
    }

    deleteAdditional(index: number) {
        this.additionalOwners.splice(index, 1);
    }

    onSessionExpired() {
        this.errorMessage = "Session expired.";
    }

}
