import { Component, OnInit } from '@angular/core';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { RightOption, SubmenuService } from '../submenu.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  rightOptions: RightOption[] = [];
  optionsSub?: Subscription

  constructor( private submenuService: SubmenuService ) {
    this.submenuService.rightOptions.subscribe( (options) => {
      this.rightOptions = options;
    });
  }

  ngOnInit(): void {
    this.optionsSub = this.submenuService.currentRoot.subscribe( (root) => {
      //console.log("UPDATE ROOT", root);
      this.generateSubMenu(root);
    });
  }

  generateSubMenu( option: string ){
    switch( option.toLowerCase() ){
      case "usuarios":
        this.generateUsersMenu();
        break;
      case "propiedades":
        this.generatePropertiesMenu();
        break;
    }
  }

  generateUsersMenu(){
    this.rightOptions = [
      {
        name: "Agregar Usuario",
        icon: faUserPlus,
        link: "usuarios/agregar"
      },
      {
        name: "Lista de Usuarios",
        icon: faListAlt,
        link: "usuarios"
      }
    ];
  }

  generatePropertiesMenu(){
    this.rightOptions = [
      {
        name: "Agregar Propiedad",
        icon: faPlusCircle,
        link: "propiedades/agregar"
      },
      {
        name: "Lista de Propiedades",
        icon: faListAlt,
        link: "propiedades"
      }
    ];
  }

  ngOnDestroy(): void{
    this.optionsSub?.unsubscribe();
  }

}
